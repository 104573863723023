<div class="container mx-auto text-white">
  <div class="mb-8">
    <h1 class="text-3xl sm:text-5xl font-effraHeavy text-pink">Bonus Round</h1>
  </div>

  <div>
    <div id="step-3" class="rounded-lg shadow-lg transform">
      <p class="mb-4 font-poppins">
        Refer friends to earn more points! For a referral to count your friend
        must create an account after clicking the link you share with them!
      </p>

      <div class="flex flex-col">
        <div class="invite flex mb-4">
          <input
            #email
            type="email"
            class="input"
            placeholder="Enter your friend's email"
            class="flex-1 bg-transparent text-white py-2 px-4 rounded-full font-bold mr-2 border-2 border-pink hover:border-white focus:outline-none"
            (input)="validateEmail(email.value)"
          />
          <button
            class="border-2 border-pink hover:border-white py-2 px-6 rounded-full font-effraHeavy"
            [ngClass]="{
              'opacity-30': !email.value,
              'opacity-100': email.value,
              'cursor-crosshair': email.value,
              'cursor-not-allowed': !email.value
            }"
            [disabled]="!email.value"
            (click)="sendReferralInvite(email.value)"
          >
            Copy Link
          </button>
        </div>
        <p
          *ngIf="message"
          [ngClass]="{ 'text-warn': isError, 'text-pink': !isError }"
        >
          {{ message }}
        </p>
      </div>

      <div
        class="flex-wrap flex items-center justify-center md:justify-between space-x-4 relative referral-container"
      >
        @if(loadingAchievements){
        <div class="w-full text-center pt-10 font-poppins blink">
          Retrieving Achievements...
        </div>
        }
        <div
          *ngFor="let referral of referrals; let i = index"
          class="md:w-auto horizontal:w-auto w-1/3 flex flex-col items-center relative"
        >
          @if(i==4 && currentReferrals==5) {
          <p
            [ngClass]="{ 'text-gold': i == 4, 'text-pink': i != 4 }"
            class="font-digital text-3xl text-center mb-2"
          >
            +{{ referral }}
          </p>
          @if (referralBadgeClaimed) {
          <div
            class="gradient-gold glow-gold relative rounded-xl text-md hover-blink text-dark pt-8 pb-2 px-5 text-center"
          >
            <img
              class="nft absolute -mt-10"
              src="/assets/images/nft-referral-preview.png"
            />
            <span class="leading-1">Badge<br />CLAIMED</span>
          </div>
          } @else {
          <button
            (click)="claimReferralBadge()"
            class="gradient-gold glow-gold relative rounded-xl text-md hover-blink text-dark pt-8 pb-2 px-5 text-center cursor-crosshair"
            [disabled]="claiming"
          >
            <img
              class="nft absolute -mt-10"
              src="/assets/images/nft-referral-preview.png"
            />

            @if(claiming) {
            <span class="leading-1">CLAIMING<br />prize...</span>
            } @else {
            <span class="leading-1">CLAIM<br />your prize!</span>
            }
          </button>
          } } @else {
          <p
            [ngClass]="{
              'text-gold': i == 4,
              'text-green': i != 4 && i < currentReferrals,
            }"
            class="font-digital text-bluishGray text-3xl text-center mb-2"
          >
            +{{ referral }}
            @if (i == 4) {
            <img class="nft" src="/assets/images/nft-referral-preview.png" />
            }
          </p>
          <div
            class="referral-circle"
            [ngClass]="{
              active: i < currentReferrals,
              'gradient-gold': i < currentReferrals && i == 4,
              'glow-gold': i == 4,
              'border-gold': i == 4 && i != currentReferrals - 1,
              'border-green': i != 4 && i < currentReferrals,
              'border-grey': i != 4
            }"
          ></div>
          <p
            [ngClass]="{ 'text-gold': i == 4, 'text-white': i != 4 }"
            class="font-poppins text-center mt-2"
          >
            {{ i + 1 }} Referral{{ i > 0 ? "s" : "" }}
          </p>
          }
        </div>
      </div>
    </div>
  </div>
</div>
