import { Component, Inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DIALOG_DATA } from '../../../services/dialog.service';

@Component({
  selector: 'app-instructions-dialog',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './instructions-dialog.component.html',
  styleUrl: './instructions-dialog.component.scss',
})
export class InstructionsDialogComponent {
  showStartButton: boolean = false;
  dontShowAgain: boolean = false;

  constructor(@Inject(DIALOG_DATA) public data: any) {
    this.showStartButton = this.data.showStartButton;
  }

  public start() {
    if (this.dontShowAgain) {
      localStorage.setItem('dontShowInstructions', 'true');
    }
    this.data.onClose();
  }
}
