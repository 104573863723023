import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as SocialActions from '../../../store/actions/social.actions';
import { RootState } from '../../../store/app.state';
import { PrimengSharedModule } from '../../../modules/primeng.shared.module';
import {
  selectCurrentAchievementId,
  selectAchievementById,
} from '../../../store/selectors/achievements.selectors';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-oauth-callback',
  standalone: true,
  imports: [PrimengSharedModule],
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.scss'],
})
export class OAuthCallbackComponent implements OnInit {
  achievementName: string | undefined;

  constructor(private route: ActivatedRoute, private store: Store<RootState>) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const code = params['code'];
      const state = params['state'];
      const path = this.route.snapshot.routeConfig?.path;

      this.store
        .select(selectCurrentAchievementId)
        .subscribe((achievementId) => {
          if (achievementId) {
            return this.store
              .select(selectAchievementById(achievementId))
              .subscribe((achievement) => {
                this.achievementName = achievement?.name;
              });
          }
          return EMPTY;
        });

      if (path?.includes('discord')) {
        this.store.dispatch(SocialActions.handleDiscordCallback({ code }));
      } else if (path?.includes('twitter')) {
        this.store.dispatch(
          SocialActions.handleTwitterCallback({ code, state })
        );
      }
    });
  }
}
