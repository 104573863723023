import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DialogService } from '../../../services/dialog.service';
import { FirstStationComponent } from '../first-station/first-station.component';
import { SecondStationComponent } from '../second-station/second-station.component';
import { StationComponent } from '../../../components/station/station.component';
import { UserEntry } from '../../../interfaces/entry.interface';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-stations',
  standalone: true,
  imports: [StationComponent, NgClass],
  templateUrl: './stations.component.html',
  styleUrl: './stations.component.scss',
})
export class StationsComponent implements AfterViewInit {
  constructor(private dialogService: DialogService) {}
  @Input() isAuthenticated: boolean = false;
  @Input() userEntryData: UserEntry | undefined;
  @Input() leaderboardId!: string;
  @Input() station2Locked: undefined | boolean;
  @Input() station3Locked: undefined | boolean;
  @Input() launched: boolean = false;
  @Output() stationClick = new EventEmitter();
  @Output() setStationFocusArea = new EventEmitter<any>();
  @ViewChild('station1', { static: false })
  station1El!: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    this.setStationFocusArea.emit(this.station1El.nativeElement);
  }
  station1ClickHandler(ev: any) {
    if (!this.launched) return;
    setTimeout(() => {
      this.dialogService.openDialog(FirstStationComponent, {
        identifier: 'station',
      });
    }, 1000);

    this.stationClick.emit(ev);
  }

  station2ClickHandler(ev: any) {
    if (!this.launched) return;
    setTimeout(() => {
      this.dialogService.openDialog(SecondStationComponent, {
        identifier: 'station',
        fullscreen: true,
      });
    }, 1000);

    this.stationClick.emit(ev);
  }

  station3ClickHandler(ev: any) {
    if (!this.launched) return;
    //this.stationClick.emit(ev); //don't zoom because this station has no dialogue

    setTimeout(() => {
      window.open(`${environment.playerDashboardUrl}/events`, '_blank');
    }, 1000);
  }
}
