<div
  (click)="onClick()"
  class="card-container items-center"
  [ngClass]="{ 'card-done': (userAchievement | async)?.isCompleted }"
>
  <!-- Center the icon horizontally and vertically -->
  <div
    class="flex align-items-center justify-content-center mb-3"
    style="height: 3em"
  >
    <i
      class="pi"
      [ngClass]="achievement.iconName ?? 'pi-twitter'"
      style="font-size: 3.5em"
    ></i>
  </div>

  <!-- Center the achievement name -->
  <div class="flex justify-content-center text-center mb-3">
    <div
      class="zoomin animation-duration-200 flex align-items-center justify-content-center"
    >
      <span class="text-xl font-poppins">{{
        achievement.name || "No Name"
      }}</span>
    </div>
  </div>

  <!-- Center the rewards text -->
  <div class="flex align-items-center justify-content-center text-center mt-3">
    <div
      class="zoomin animation-duration-200 flex align-items-center justify-content-center"
    >
      <span class="font-effraHeavy ml-4 text-2xl">{{ rewardQuantity }}</span>
      <span class="font-effra ml-2">PL▶️Y Coins</span>
    </div>
  </div>

  <!-- Center the done message -->
  @if((userAchievement | async)?.isCompleted) {
  <div class="flex align-items-center justify-content-center text-center mt-3">
    <div
      class="zoomin animation-duration-200 flex align-items-center justify-content-center"
    >
      <span class="font-poppins ml-2 text-yellow-700"
        >Done at
        {{ (userAchievement | async)?.lastCompleteTime | date : "short" }}</span
      >
    </div>
  </div>
  }
</div>
