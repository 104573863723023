<div class="no-box-shadows">
  <div class="mb-8 bg-transparent">
    <!-- <img src="assets/images/logo.svg" alt="READYgg" class="mx-auto h-9 mb-4" /> -->
    <h1 class="text-3xl sm:text-5xl font-effraHeavy text-pink">
      Social Battleground
    </h1>
  </div>
  <i class="text-pink text-lg block text-center">
    *NOTE* Make sure to use the same email you used for your PLAY account!
  </i>
  <iframe
    src="https://gleam.io/HOZb0/readygg-coinlist-station-2"
    class="iframe-container border-0"
    title="Readygg Coinlist station 2"
  ></iframe>
</div>
