<div
  class="toolbar-container md:w-auto md:justify-end md:bg-transparent flex z-50 w-screen justify-evenly sm:gap-5"
>
  <img
    class="logo hidden horizontal:block"
    src="assets/images/logo-white.png"
    alt="PLAY"
  />
  @if(launched) {
  <div class="horizontal:mt-0">
    <div
      (click)="refer()"
      class="pointer-events-auto relative px-2 transition-all cursor-crosshair text-center"
    >
      <span
        class="inline-block icon icon-earn-more bg-borderGray sm:mr-2 headerIcon"
      ></span>

      <h1
        class="lg:text-md text-xs text-right truncate text-white font-effraHeavy"
      >
        More Points!
      </h1>
    </div>
  </div>
  }
  <div class="horizontal:mt-0">
    <div class="relative text-center px-2">
      <img
        (click)="openInventory()"
        class="headerIcon cursor-crosshair pointer-events-auto"
        src="assets/icons/chest.png"
        alt="NFT Badge"
      />
      <div
        class="inventory-count absolute right-2 top-0 text-sm text-dark bg-white rounded-full font-effraHeavy"
      >
        {{ inventory.length > 0 ? inventory.length : "" }}
      </div>
      <h1
        class="lg:text-md text-xs text-right truncate text-white font-effraHeavy"
      >
        Inventory
      </h1>
    </div>
  </div>

  @if(launched) {
  <div class="flex items-center sm:justify-end horizontal:mt-0">
    <div
      (click)="openInstructions()"
      class="pointer-events-auto relative px-2 transition-all cursor-crosshair text-center"
    >
      <span
        class="inline-block icon icon-help bg-borderGray sm:mr-2 headerIcon"
      ></span>

      <h1
        class="lg:text-md text-xs text-right truncate text-white font-effraHeavy"
      >
        Instructions
      </h1>
    </div>
  </div>
  } @if (user$ | async; as user) {
  <div class="horizontal:mt-0">
    <button
      (click)="logout()"
      class="pointer-events-auto px-2 transition-all cursor-crosshair"
    >
      <span
        class="icon icon-logout inline-block bg-borderGray headerIcon"
      ></span>
      <h1
        class="user-info__username lg:text-md text-xs text-center truncate text-white font-effraHeavy"
        [title]="displayName"
      >
        Logout
      </h1>
    </button>
  </div>

  } @else {
  <div>
    <button
      (click)="openAuthPopup()"
      class="md:mr-30 pointer-events-auto px-4 transition-all cursor-crosshair text-center"
    >
      <span
        class="icon icon-login inline-block bg-borderGray headerIcon"
      ></span>

      <h1 class="text-md truncate text-white font-effraHeavy">Login</h1>
    </button>
  </div>
  }
</div>
