import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { StepperModule } from 'primeng/stepper';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';

const modules = [
  CommonModule,
  ButtonModule,
  CardModule,
  ToolbarModule,
  SplitButtonModule,
  PanelMenuModule,
  ProgressBarModule,
  ProgressSpinnerModule,
  RippleModule,
  ToastModule,
  StepperModule,
  DialogModule,
  FieldsetModule,
];
@NgModule({
  imports: modules,
  exports: modules,
})
export class PrimengSharedModule {}
