import { Routes } from '@angular/router';

import { UniverseComponent } from './pages/universe/universe.component';
import { AchievementTriggerResultComponent } from './pages/social/achievement-trigger-result/achievement-trigger-result.component';
import { AchievementsListComponent } from './pages/social/achievements-list/achievements-list.component';
import { OAuthCallbackComponent } from './pages/social/oauth-callback/oauth-callback.component';
import { SocialTaskDetailsComponent } from './pages/social/social-task-details/social-task-details.component';

export const routes: Routes = [
  {
    path: 'universe',
    component: UniverseComponent,
  },
  {
    path: 'social',
    children: [
      {
        path: 'achievements',
        pathMatch: 'full',
        component: AchievementsListComponent,
      },
      {
        path: 'social-task-details/:id',
        pathMatch: 'full',
        component: SocialTaskDetailsComponent,
      },
      {
        path: 'oauth/callback/discord',
        pathMatch: 'full',
        component: OAuthCallbackComponent,
      },
      {
        path: 'oauth/callback/twitter',
        pathMatch: 'full',
        component: OAuthCallbackComponent,
      },
      {
        path: 'achievement-trigger-result/:id',
        pathMatch: 'full',
        component: AchievementTriggerResultComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: '/universe',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/universe' },
];
