import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { TemplateStationComponent } from './template-station/template-station.component';
import { RootState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-template-chapter',
  standalone: true,
  imports: [TemplateStationComponent],
  templateUrl: './template-chapter.component.html',
  styleUrl: './template-chapter.component.scss',
})
export class TemplateChapterComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  @Output() stationLaunch = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @ViewChild('templateChapterContainer') templateChapterContainer!: ElementRef;

  ngOnInit() {
    this.dialogService.dialogClose$.subscribe((identifier) => {
      if (identifier === 'template-station') {
        this.close.emit({
          currentTarget: this.templateChapterContainer.nativeElement,
        } as any);
      }
    });
  }

  stationClickHandler(el: any) {
    //disable on production
    return;

    if (!this.authService.isAuthenticated()) {
      return;
    }
    this.stationLaunch.emit(el);
    setTimeout(() => {
      this.dialogService.openDialog(TemplateStationComponent, {
        identifier: 'template-station',
        fullscreen: true,
      });
    }, 1000);
  }
}
