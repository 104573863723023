// src/app/state/models/achievements.model.ts
import {
  ReadyGGAchievementData,
  ReadyGGUserAchievement,
} from '@readygg/ng-api';

export interface AchievementsState {
  achievements: ReadyGGAchievementData[];
  userAchievements: ReadyGGUserAchievement[];
  loadedAt: Date | null;
}

export const initialAchievementsState: AchievementsState = {
  achievements: [],
  userAchievements: [],
  loadedAt: null,
};
