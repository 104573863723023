import { Injectable } from '@angular/core';
import isMobile from 'is-mobile';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor() {}

  isIpad() {
    //This is needed because Apple are now masquerading iPads as desktop devices.
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any)['opera'];
    return /iPad|Macintosh/.test(userAgent) && 'ontouchend' in document;
  }

  isIphone(): boolean {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any)['opera'];
    return /iPhone/.test(userAgent) && !(window as any).MSStream;
  }

  isMobile(): boolean {
    return isMobile({ featureDetect: true, tablet: true }) || this.isIpad();
  }
}
