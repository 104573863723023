import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './services/auth.service';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  let newReq = req.clone();
  const accessToken = req.body.refreshToken || authService.getUserToken();

  if (accessToken) {
    const appId = '8McbPa7yR4ozLut3udHf'; //TODO : Possibly we will have to pass a different appId for each chapter
    //const appId = 'io.getready.rgntest';
    try {
      newReq = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + accessToken)
          .set('app-id', appId),
      });
    } catch (error) {
      console.error('User is not authenticated', error);
      return throwError(() => new Error('User is not authenticated'));
    }
  }

  return next(newReq).pipe(
    catchError((response: HttpErrorResponse) => {
      if (response instanceof HttpErrorResponse && response.status === 401) {
        authService.signOut();
        location.reload();
      }
      return throwError(
        () =>
          new Error(
            response.error.message ? response.error.message : response.error
          )
      );
    })
  );
};
