<div class="mb-8">
  @if(!isOnAchievementsPage) {
  <button [routerLink]="['/social/achievements']">
    <span class="arrow next inline-block w-8 h-8"></span>
  </button>
  }

  <h1 class="text-3xl inline-block ml-4 sm:text-5xl font-effraHeavy text-pink">
    Achievements
  </h1>
</div>

<app-social></app-social>
