@if (user$ | async; as user) {
<div
  [ngClass]="{
    'fade-in': launched,
    'fade-out': !launched
  }"
  class="sm:w-auto flex flex-1 user-info font-poppins items-center horizontal:mt-0 sm:max-w-70 sm:min-w-80"
>
  <div class="flex flex-1 gap-1 user-info font-poppins items-end">
    <div
      class="score-zone flex flex-1 items-center h-18 rounded-l-full border-2 border-r-0 border-bluishGray border-opacity-50"
    >
      <div class="flex flex-col flex-1 text-pink leading-7 h-full mr-2 pl-3">
        <div class="font-effra text-sm text-right h-4 mb-1">Points</div>
        <div class="flex flex-1 justify-end min-h-6">
          <div
            class="absolute text-pink opacity-20 text-right text-6xl font-digital neon-text"
          >
            {{
              userEntryData?.formattedScore
                ? userEntryData?.formattedScore
                : "0000"
            }}00
          </div>

          <div
            #score
            [ngClass]="{
              blink: showScore
            }"
            class="transition-all antialiased font-digital text-6xl neon-text leading-10"
          >
            {{
              userEntryData?.formattedScore
                ? userEntryData?.formattedScore
                : "0000"
            }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="score-zone h-18 leading-4 md:text-left rounded-r-full border-2 border-l-0 border-bluishGray pointer-events-auto flex-1"
    >
      <a
        [href]="mainEventUrl + leaderboardId"
        target="_blank"
        class="block pointer-events-auto leading-4 mt-2 relative pr-6 pb-4 cursor-crosshair mr-2"
      >
        <h1
          class="user-info__username text-2xl truncate text-white"
          [title]="user.displayName"
        >
          {{ user.displayName }}
        </h1>
        @if (userEntryData?.formattedScore) {

        <div class="rank-container flex gap-0">
          <h2
            [title]="userEntryData?.place"
            class="antialiased user-info__rank font-poppins truncate text-violet"
          >
            {{ userEntryData?.place }}
          </h2>
          <div class="ranking-title uppercase font-poppins text-bluishGray">
            <p class="line-1">$PLAY</p>
            <p class="line-2">Cup Rank</p>
          </div>
        </div>
        <span
          class="score-arrow absolute next inline-block sm:w-6 sm:h-6 w-4 h-4"
        ></span>

        } @else {
        <h2 class="antialiased text-2xl mt-2 font-poppins text-violet">
          No Ranking
        </h2>

        }
      </a>
    </div>
  </div>
</div>
}
