import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, of } from 'rxjs';
import { IAuthNewUserData } from '../../../interfaces/auth.interface';
import { AuthService } from '../../../services/auth.service';
import { AchievementsService } from '../../../services/achievements.service';

@Component({
  selector: 'app-first-station',
  standalone: true,
  templateUrl: 'first-station.component.html',
  styleUrls: ['first-station.component.scss'],
  imports: [CommonModule],
})
export class FirstStationComponent implements OnInit {
  @ViewChild('iframeEl') iframeEl: ElementRef | undefined;
  isAuthenticated$: Observable<IAuthNewUserData | null> = of(null);
  isAuthenticated: boolean = false;

  constructor(
    private authService: AuthService,
    private achievementsService: AchievementsService
  ) {}

  ngOnInit() {
    this.checkAuthentication();
  }

  checkAuthentication() {
    this.isAuthenticated$ = this.authService.user$;
    this.isAuthenticated$.subscribe((user) => {
      this.isAuthenticated = Boolean(user?.userId.length);
      if (this.isAuthenticated) {
        this.achievementsService.loginAchievement();
      }
    });
  }

  openAuthPopup() {
    this.authService.runOAuth();
  }
}
