import { Component } from '@angular/core';

@Component({
  selector: 'app-second-station',
  standalone: true,
  templateUrl: 'second-station.component.html',
  styleUrls: ['second-station.component.scss'],
  imports: [],
})
export class SecondStationComponent {
  constructor() {}
}
