import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrowButtonComponent } from '../arrow-button/arrow-button.component';

@Component({
  selector: 'app-station',
  standalone: true,
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss'],
  imports: [NgClass, ArrowButtonComponent],
})
export class StationComponent {
  @Input() id: string = '';
  @Input() title: string = '';
  @Input() imgSrc: string = '';
  @Input() score: number = 0;
  @Input() points: string = '';
  @Input() locked: boolean | undefined;
  @Input() leaderboardId: string | undefined;
  @Output() stationClick = new EventEmitter();

  constructor() {}

  stationClickHandler(ev: any) {
    if (this.locked) return;
    if (this.stationClick) {
      this.stationClick.emit(ev);
      return;
    }
  }
}
