<div
  #templateChapterContainer
  (click)="stationClickHandler($event)"
  class="planet-coming-soon mix-blend-lighten"
>
  <div class="title font-effraHeavy text-right">
    <h1 class="text-white text-8xl text-nowrap antialiased">CHAPTER 2</h1>
    <h2 class="text-pink text-7xl text-nowrap antialiased">COMING SOON</h2>
  </div>
  <img
    class="mix-blend-lighten max-w-none"
    src="assets/images/planet-coming-soon.png"
    alt="Universe"
  />
</div>
