<div
  [ngClass]="{ container: bottomGradient }"
  class="fixed horizontal:hidden right-0 left-0 bottom-0 md:pb-3 md:pt-1 pb-10 pt-10 font-effra text-lg w-full md:w-auto text-center z-50 leading-10"
>
  <a
    href="https://discord.gg/0xplay-network"
    class="icon icon-discord"
    target="_blank"
  ></a>
  <a
    href="https://t.me/play_communityofficial"
    class="icon icon-telegram"
    target="_blank"
  ></a>
  <a
    href="https://x.com/0xplay_network"
    class="icon icon-x"
    target="_blank"
  ></a>
  <a
    href="https://www.linkedin.com/company/0xplay-network"
    class="icon icon-linkedin"
    target="_blank"
  ></a>

  <a
    class="text-white hover:text-pink transition-colors duration-200"
    href="https://myplay.network/contact-us/"
    target="_blank"
    >Contact Us</a
  >
</div>
