import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { FieldsetModule } from 'primeng/fieldset';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import {
  ReadyGGAchievementData,
  ReadyGGRequirement,
  ReadyGGUserAchievement,
} from '@readygg/ng-api';

import * as SocialActions from '../../../store/actions/social.actions';

import {
  selectAchievementById,
  selectUserAchievementById,
} from '../../../store/selectors/achievements.selectors';
import { RootState } from '../../../store/app.state';
import {
  setAchievementToTrigger,
  triggerAchievement,
} from '../../../store/actions/achievements.actions';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

class MyReadyGGRequirement implements ReadyGGRequirement {
  public mergedType: string;

  private _id: string;
  private _type:
    | 'gamepass_by_id'
    | 'gamepass_by_request_name'
    | 'item_by_id'
    | 'item_by_tag'
    | 'achievement_by_id'
    | 'achievement_by_request_name'
    | 'social_connected'
    | 'discord_joined_server'
    | 'discord_has_role'
    | 'twitter_retweeted'
    | 'twitter_liked'
    | 'twitter_replied';
  public get id(): string {
    return this._id;
  }

  public get type():
    | 'gamepass_by_id'
    | 'gamepass_by_request_name'
    | 'item_by_id'
    | 'item_by_tag'
    | 'achievement_by_id'
    | 'achievement_by_request_name'
    | 'social_connected'
    | 'discord_joined_server'
    | 'discord_has_role'
    | 'twitter_retweeted'
    | 'twitter_liked'
    | 'twitter_replied' {
    return this._type;
  }

  constructor(readyGGRequirement: ReadyGGRequirement) {
    this._id = readyGGRequirement.id;
    this._type = readyGGRequirement.type;
    this.mergedType = readyGGRequirement.type;
  }
}

@Component({
  selector: 'app-social-task-details',
  standalone: true,
  templateUrl: './social-task-details.component.html',
  styleUrls: ['./social-task-details.component.scss'],
  imports: [AsyncPipe, DatePipe, StepperModule, ButtonModule, FieldsetModule],
})
export class SocialTaskDetailsComponent implements OnInit {
  @Input() achievement: string | undefined;
  achievement$: Observable<ReadyGGAchievementData | null> = of(null);

  userAchievement: ReadyGGUserAchievement | null = null;
  requirements$: Observable<
    (MyReadyGGRequirement & {
      requiredAchievement?: ReadyGGAchievementData;
      requiredUserAchievement?: ReadyGGUserAchievement;
    })[]
  > = of([]);

  activeStepIndex: number = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store<RootState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.achievement$ = this.route.params.pipe(
      map((params) => params['id']),
      switchMap((id) => {
        this.store
          .select(selectUserAchievementById(id))
          .pipe(take(1))
          .subscribe((userAchievement) => {
            this.userAchievement = userAchievement;
          });
        return this.store.select(selectAchievementById(id));
      })
    );

    this.requirements$ = this.achievement$.pipe(
      switchMap((achievement) => this.fetchRequirements(achievement))
    );
  }

  ngOnInit(): void {
    this.requirements$.subscribe((requirements) => {
      this.updateActiveStep(requirements);
      this.cdr.detectChanges();
    });
  }

  private fetchRequirements(
    achievement: ReadyGGAchievementData | null
  ): Observable<
    (MyReadyGGRequirement & {
      requiredAchievement?: ReadyGGAchievementData;
      requiredUserAchievement?: ReadyGGUserAchievement;
    })[]
  > {
    const requirements = achievement?.requiredToComplete?.requirements || [];
    const sortedRequirements = this.sortRequirements(requirements);
    let myRequirements = sortedRequirements.map(
      (req) => new MyReadyGGRequirement(req)
    );
    if (achievement?.requiredToComplete?.operation === 'and') {
      myRequirements = this.requirementsMerge(myRequirements);
    }
    return combineLatest(
      myRequirements.map((req) => {
        if (req.mergedType === 'achievement_by_id') {
          return combineLatest([
            this.store.select(selectAchievementById(req.id)),
            this.store.select(selectUserAchievementById(req.id)),
          ]).pipe(
            map(
              ([requiredAchievement, requiredUserAchievement]) =>
                ({
                  ...req,
                  requiredAchievement,
                  requiredUserAchievement,
                } as MyReadyGGRequirement & {
                  requiredAchievement?: ReadyGGAchievementData;
                  requiredUserAchievement?: ReadyGGUserAchievement;
                })
            )
          );
        }
        return of(
          req as MyReadyGGRequirement & {
            requiredAchievement?: ReadyGGAchievementData;
            requiredUserAchievement?: ReadyGGUserAchievement;
          }
        );
      })
    );
  }

  private updateActiveStep(
    requirements: (MyReadyGGRequirement & {
      requiredUserAchievement?: ReadyGGUserAchievement;
    })[]
  ): void {
    this.activeStepIndex = requirements.findIndex(
      (req) => !req.requiredUserAchievement?.isCompleted
    );
  }

  openAchievementUrl(): void {
    this.achievement$.pipe(take(1)).subscribe((achievement) => {
      this.store.dispatch(setAchievementToTrigger({ achievement }));
      if (achievement?.description) {
        window.open(achievement.description, '_blank');
      }
    });
  }

  triggerAchievement(achievementId: string): void {
    console.log('Triggering achievement');
    //.navigate(['/achievement-trigger-result', achievementId]);
    this.store.dispatch(triggerAchievement({ achievementId }));
  }

  connectTwitter(achievementData: ReadyGGAchievementData | null): void {
    if (achievementData) {
      this.store.dispatch(
        SocialActions.connectTwitter({ achievement: achievementData })
      );
    }
  }

  connectDiscord(achievementData: ReadyGGAchievementData | null): void {
    if (achievementData) {
      const callbackUrl = achievementData.description || '';
      this.store.dispatch(
        SocialActions.connectDiscord({
          achievement: achievementData,
          callbackUrl,
        })
      );
    }
  }

  openTheRequirementForConnectSocialAccount(
    achievementData: ReadyGGAchievementData | null
  ): void {
    const firstRequirement =
      achievementData?.requiredToComplete?.requirements?.[0];
    if (firstRequirement) {
      if (firstRequirement.id === 'discord') {
        this.connectDiscord(achievementData);
      } else if (firstRequirement.id === 'twitter') {
        this.connectTwitter(achievementData);
      }
    }
  }

  getStepActionDescription(requirement: MyReadyGGRequirement): string {
    const split = requirement.mergedType?.split('&');
    if (split && split.length > 1) {
      let description = '';
      split.forEach((type, index) => {
        description += this.getStepActionDescriptionByType(type, true);
        if (index < split.length - 1) {
          description += ' and ';
        }
      });
      if (split.some((type) => type.includes('twitter'))) {
        description += ' on Twitter';
      } else if (split.some((type) => type.includes('discord'))) {
        description += ' on Discord';
      }
      var toReturn = description.at(0)?.toUpperCase() + description.slice(1);
      return toReturn || 'Requirement to proceed';
    }
    return this.getStepActionDescriptionByType(requirement.mergedType, false);
  }
  private getStepActionDescriptionByType(type: string, short: boolean): string {
    switch (type) {
      case 'achievement_by_id':
        return 'Complete the required step';
      case 'discord_joined_server':
        return 'Join Discord Server';
      case 'discord_has_role':
        return 'Get Discord Role';
      case 'twitter_retweeted':
        return short ? 'retweet' : 'Retweet on Twitter';
      case 'twitter_liked':
        return short ? 'like' : 'Like on Twitter';
      case 'twitter_replied':
        return short ? 'reply' : 'Reply on Twitter';
      case 'social_connected':
        return 'Connect Social Account';
      default:
        return 'Requirement';
    }
  }

  getStepContent(
    requirement: MyReadyGGRequirement | ReadyGGRequirement | null
  ): string {
    if (!requirement) {
      return 'Complete this requirement to proceed.';
    }
    var castedRequirement = requirement as MyReadyGGRequirement;
    if (castedRequirement) {
      const split = castedRequirement.mergedType?.split('&');
      if (split && split.length > 1) {
        let description = '';
        split.forEach((type, index) => {
          description += this.getStepContentByType(type, true);
          if (index < split.length - 1) {
            description += ', ';
          }
        });
        if (split.some((type) => type.includes('twitter'))) {
          description += ' the tweet';
        } else if (split.some((type) => type.includes('discord'))) {
          description += ' on Discord';
        }
        let toReturn = description.at(0)?.toUpperCase() + description.slice(1);
        return toReturn || 'Complete this requirement to proceed.';
      }
      return this.getStepContentByType(castedRequirement.type, false);
    }
    return this.getStepContentByType(requirement.type, false);
  }
  private getStepContentByType(type: string, short: boolean): string {
    switch (type) {
      case 'discord_joined_server':
        return 'Join the Discord server.';
      case 'discord_has_role':
        return 'Get the required role on Discord.';
      case 'twitter_retweeted':
        return short ? 'retweet' : 'Retweet the tweet.';
      case 'twitter_liked':
        return short ? 'like' : 'Like the tweet.';
      case 'twitter_replied':
        return short ? 'reply' : 'Reply to the tweet.';
      case 'social_connected':
        return 'Connect your social account to proceed.';
      default:
        return 'Complete this requirement to proceed.';
    }
  }

  getStepDetailedDescription(requirement: MyReadyGGRequirement | null): string {
    switch (requirement?.mergedType) {
      case 'discord_joined_server':
        return 'You need to open the Discord server by clicking the button below. Once you have joined the server, come back here to proceed.';
      case 'discord_has_role':
        return 'You need to get the required role on Discord Server. Once you have the role, come back here to proceed.';
      case 'twitter_retweeted':
        return 'You need to open the tweet by clicking the button below. Once you have retweeted the tweet, come back here to proceed.';
      case 'twitter_liked':
        return 'You need to open the tweet by clicking the button below. Once you have liked the tweet, come back here to proceed.';
      case 'twitter_replied':
        return 'You need to open the tweet by clicking the button below. Once you have replied to the tweet, come back here to proceed.';
      case 'social_connected':
        return 'You need to connect your social account to proceed. Click the button below to connect your account.';
      default:
        return 'Complete this requirement to proceed.';
    }
  }

  getButtonLabel(
    requirement: MyReadyGGRequirement | ReadyGGRequirement | null
  ): string {
    switch (requirement?.type) {
      case 'discord_joined_server':
      case 'discord_has_role':
        return 'Open Discord Server';
      case 'twitter_retweeted':
      case 'twitter_liked':
      case 'twitter_replied':
        return 'Open The Tweet';
      case 'social_connected':
        return requirement?.id === 'discord'
          ? 'Connect Discord'
          : 'Connect Twitter';
      default:
        return 'Complete';
    }
  }

  private sortRequirements(
    requirements: ReadyGGRequirement[]
  ): ReadyGGRequirement[] {
    return requirements.slice().sort((a, b) => {
      if (a.type === 'achievement_by_id' && b.type !== 'achievement_by_id')
        return -1;
      if (a.type !== 'achievement_by_id' && b.type === 'achievement_by_id')
        return 1;
      return 0;
    });
  }

  private requirementsMerge(
    requirements: MyReadyGGRequirement[]
  ): MyReadyGGRequirement[] {
    // if the id is the same, merge the requirements
    const mergedRequirements: MyReadyGGRequirement[] = [];
    requirements.forEach((req) => {
      const existingReq = mergedRequirements.find((r) => r.id === req.id);
      if (existingReq) {
        existingReq.mergedType += `&${req.mergedType}`;
        return;
      }
      mergedRequirements.push(req);
    });
    return mergedRequirements;
  }
}
