import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import Panzoom from '@panzoom/panzoom';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-panzoom-component',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './panzoom.component.html',
  styleUrl: './panzoom.component.scss',
})
export class PanzoomComponent implements AfterViewInit, OnInit {
  @ViewChild('panzoomContainer') panzoomContainer!: ElementRef;
  @ViewChild('panzoomElement') panzoomElement!: ElementRef;

  constructor(private _device: DeviceService) {}

  panzoomInstance: any;

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    const element = this.panzoomElement.nativeElement;
    if (element) {
      this.panzoomInstance = Panzoom(element, {
        maxScale: 5,
        minScale: 0.3,
        duration: 1000,
        zoom: 0.1,
        animate: false,
      });

      // element.parentElement!.addEventListener(
      //   'wheel',
      //   this.panzoomInstance.zoomWithWheel
      // );
    }
  }

  public zoomToElement(element: any, depth: number) {
    // Get the bounding rectangle of the element
    const rect = element.getBoundingClientRect();

    const panElement =
      this.panzoomElement.nativeElement.getBoundingClientRect();

    const screenX = window.innerWidth / 2;
    const screenY = window.innerHeight / 2 + 74;

    // Current transformation
    const { x: panX, y: panY } = this.panzoomInstance.getPan();
    const scale = this.panzoomInstance.getScale();
    // Calculate the center of the element relative to the container
    const targetX = rect.left + rect.width / 2;
    const targetY = rect.top + rect.height / 2;

    // Set the desired scale
    let newScale;
    const isMobile = this._device.isMobile();
    if (depth == 1) newScale = isMobile ? 0.7 : 1;
    if (depth == 2) newScale = isMobile ? 1 : 1.6;
    if (depth == 3) newScale = isMobile ? 1.6 : 4;

    let deltaX: number;
    let deltaY: number;

    const bypass = true;

    if (
      bypass ||
      (panElement.width <= window.innerWidth &&
        panElement.height <= window.innerHeight)
    ) {
      // Case 1: When the panzoom container size is smaller than the window size

      deltaX = (targetX - screenX) / scale;
      deltaY = (targetY - screenY) / scale;
      this.panzoomInstance.pan(panX - deltaX, panY - deltaY);
    } else {
      // Case 2: When the panzoom container size is bigger than the window size
      const absoluteTargetX = rect.left + rect.width / 2;
      const absoluteTargetY = rect.top + rect.height / 2;

      deltaX = screenX - absoluteTargetX;
      deltaY = screenY - absoluteTargetY;

      this.panzoomInstance.pan(deltaX + panX, deltaY + panY, {
        animate: true,
      });
    }
    this.panzoomInstance.zoom(newScale, { animate: true });
  }
}
