import {
  Injectable,
  Injector,
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ComponentRef,
  Type,
  InjectionToken,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BaseDialogComponent } from '../components/base-dialog/base-dialog.component';

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogComponentRef!: ComponentRef<any>;
  private dialogCloseSubject = new Subject<string>();
  dialogClose$ = this.dialogCloseSubject.asObservable();

  constructor(
    private injector: Injector,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  openDialog(component: Type<any>, _data?: any): ComponentRef<any> {
    const injectData = {
      component,
      onClose: () => this.closeDialog(_data.identifier),
      ..._data,
    };

    const injector = Injector.create({
      providers: [{ provide: DIALOG_DATA, useValue: injectData }],
      parent: this.injector,
    });

    // Create a component reference for the base dialog component
    this.dialogComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(BaseDialogComponent)
      .create(injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.dialogComponentRef.hostView);

    // Get DOM element from component
    const domElem = (this.dialogComponentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    // Load the actual dialog content into the base dialog component
    //this.dialogComponentRef.instance.loadContent(component);

    return this.dialogComponentRef;
  }

  closeDialog(identifier: string) {
    if (this.dialogComponentRef.instance) {
      this.dialogComponentRef.instance.close();
    } else {
      this.removeDialog(identifier);
    }
  }
  removeDialog(identifier: string) {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogCloseSubject.next(identifier);
    this.dialogComponentRef.destroy();
  }
}
