<main
  class="universe relative flex flex-col items-center h-dvh w-dvw overflow-hidden"
>
  <app-header
    [userEntryData]="userEntryData"
    [inventory]="purchasedItems"
    [launched]="depth == 2 || depth == 3"
  >
  </app-header>

  @if (depth === 2) {
  <div (click)="backToUniverse()" class="backToUniverse triangle absolute">
    <app-arrow-button [back]="true" [flat]="true" label=""></app-arrow-button>
  </div>
  }

  <div class="flex-1 z-20">
    <app-panzoom-component>
      <main
        [ngClass]="{ bg: elementsVisible }"
        class="panzoomElement flex justify-around relative"
      >
        @if (elementsVisible) {
        <h1
          [ngClass]="{ visible: depth === 1 }"
          class="universeTitle opacity-90"
        >
          <img
            src="assets/images/play-quests-logo.png"
            class="logo"
            alt="Universe"
          />
        </h1>
        }

        <app-rdyx-cup
          [isAuthenticated]="true"
          [userEntryData]="userEntryData"
          [station2Locked]="station2Locked"
          [station3Locked]="station3Locked"
          [launched]="depth == 2 || depth == 3"
          (launch)="goToChapter($event)"
          (stationLaunch)="goToStation($event)"
          (setFocusArea)="setFocusArea($event)"
        ></app-rdyx-cup>

        @if (elementsVisible) {
        <app-template-chapter
          (close)="backToUniverse()"
          (stationLaunch)="goToStation($event)"
        ></app-template-chapter>
        <img
          class="absolute orbit-img delay-75"
          src="assets/images/orbit-masked.gif"
          alt=""
        />
        }
      </main>
    </app-panzoom-component>
  </div>

  <!-- <app-socials-bar></app-socials-bar> -->
  <div
    class="flex items-center justify-center font-effraHeavy text-dark text-lg p-2 z-20 w-full absolute bottom-0 left-0 right-0 mx-auto bg-white horizontal:left-auto horizontal:w-44 horizontal:text-sm"
  >
    READYgg is now
    <a
      href="https://x.com/0xplay_network/status/1823358897706479930"
      target="_blank"
    >
      <img class="w-16 ml-2" src="assets/images/logo-play.svg" alt="PLAY" /> </a
    >!
  </div>
</main>
