import { AsyncPipe, NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UserEntry } from '../../../../interfaces/entry.interface';
import { AuthService } from '../../../../services/auth.service';
import { Observable } from 'rxjs';
import { IAuthNewUserData } from '../../../../interfaces/auth.interface';
import { environment } from '../../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { RootState } from '../../../../store/app.state';
import { UniverseActions } from '../../../../store/actions/universe.actions';
import { Leaderboard } from '../../../../interfaces/leaderboard.interface';
import {
  selectActiveLeaderboardData,
  selectLeaderboardData,
} from '../../../../store/selectors/universe.selectors';

@Component({
  selector: 'app-score',
  standalone: true,
  imports: [NgClass, AsyncPipe],
  templateUrl: './score.component.html',
  styleUrl: './score.component.scss',
})
export class ScoreComponent implements OnInit, OnChanges {
  @Input() userEntryData: UserEntry | undefined;
  @Input() displayName!: string | undefined;
  @Input() launched: boolean = false;
  @ViewChild('score') scoreElementRef!: ElementRef<HTMLDivElement>;
  user$: Observable<IAuthNewUserData | null> | undefined;
  showScore: boolean = false;
  showLeaderboardData: boolean = false;

  leaderboardData$: Observable<Leaderboard | undefined> = this.store.pipe(
    select(selectActiveLeaderboardData)
  );

  leaderboardId: string = '';

  mainEventUrl = `${environment.playerDashboardUrl}/events/rdyx-cup/`;

  constructor(
    private authService: AuthService,
    private store: Store<RootState>
  ) {}
  ngOnInit(): void {
    this.user$ = this.authService.user$;

    this.leaderboardData$.subscribe((data) => {
      if (data) {
        this.leaderboardId = data.id;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userEntryData']?.currentValue) {
      this.showLeaderboardData = true;
      this.showScore = false;
      let newUserEntryData = { ...changes['userEntryData'].currentValue };
      if (!newUserEntryData.score) {
        newUserEntryData.formattedScore = '000';
        newUserEntryData.place = 0;
      }
      this.userEntryData = newUserEntryData;
      const score = this.scoreElementRef?.nativeElement;
      if (!score) return;
      setTimeout(() => {
        this.showScore = true;
      }, 10);
    }
  }
}
