import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import * as AchievementsActions from '../../../store/actions/achievements.actions';
import { PrimengSharedModule } from '../../../modules/primeng.shared.module';
import { Store } from '@ngrx/store';
import { RootState } from '../../../store/app.state';
import { map, Observable, switchMap, take } from 'rxjs';
import {
  selectAchievementById,
  selectUserAchievementById,
} from '../../../store/selectors/achievements.selectors';
import {
  ReadyGGAchievementData,
  ReadyGGUserAchievement,
} from '@readygg/ng-api';

@Component({
  selector: 'app-achievement-trigger-result',
  standalone: true,
  imports: [PrimengSharedModule],
  templateUrl: './achievement-trigger-result.component.html',
  styleUrls: ['./achievement-trigger-result.component.scss'],
})
export class AchievementTriggerResultComponent implements OnInit {
  achievement$: Observable<ReadyGGAchievementData | null>;
  userAchievement: ReadyGGUserAchievement | null = null;
  achievementDialogVisible = false;
  achievementHeader: string | undefined;
  achievementMessage: string | undefined;

  constructor(
    private store: Store<RootState>,
    private route: ActivatedRoute,
    private router: Router,
    private actions$: Actions
  ) {
    this.achievement$ = this.route.params.pipe(
      map((params) => params['id']),
      switchMap((id) => {
        this.store
          .select(selectUserAchievementById(id))
          .pipe(take(1))
          .subscribe((userAchievement) => {
            this.userAchievement = userAchievement;
          });
        return this.store.select(selectAchievementById(id));
      })
    );
  }

  ngOnInit(): void {
    this.actions$
      .pipe(ofType(AchievementsActions.triggerAchievementResult))
      .subscribe((action) => {
        if (action.success) {
          const reward = action.rewards?.[0];

          this.achievementHeader = 'Step done! 🎉';
          this.achievementMessage = `
          <div class="flex py-4 gap-2 font-bold text-mi text-xl">
            <span>Received ${reward?.quantity || 0} PL▶️Y Coins reward!</span>
          </div>`;
        } else {
          this.achievementHeader = 'Oops 😬';
          this.achievementMessage = `
          <div class="flex py-4 gap-2 font-bold text-mi text-xl">
            <span>Oops, the achievement slipped away this time! But don't worry, you'll nail it next time! 💪</span>
          </div>`;
        }
        this.showAchievementDialog();
      });
  }

  showAchievementDialog() {
    this.achievementDialogVisible = true;
  }

  onDialogHide() {
    this.achievementDialogVisible = false;
    this.router.navigate(['/home']);
  }
}
