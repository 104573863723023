import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DIALOG_DATA, DialogService } from '../../services/dialog.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-base-dialog',
  standalone: true,
  imports: [NgClass],
  templateUrl: './base-dialog.component.html',
  styleUrl: './base-dialog.component.scss',
})
export class BaseDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog!: any;
  @ViewChild('dialogContent') dialogContentEl!: any;
  @ViewChild('dialogContentContainer') dialogContentContainer!: any;
  @ViewChild('dialogContentBase', { read: ViewContainerRef, static: true })
  dialogContentBase!: ViewContainerRef;
  constructor(
    private dialogService: DialogService,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.loadContent(this.data.component);
  }

  public close() {
    this.dialog.nativeElement.classList.remove('show');

    setTimeout(() => {
      this.dialogService.removeDialog(this.data.identifier);
    }, 400);
  }

  loadContent(component: Type<any>) {
    this.injectContent(component);
  }

  injectContent(component: Type<any>) {
    const factory = this.cfr.resolveComponentFactory(component);
    this.dialogContentBase.clear();
    const componentRef = this.dialogContentBase.createComponent(
      factory,
      0,
      this.injector
    );

    setTimeout(() => {
      this.dialog.nativeElement.classList.remove('starting');
      this.dialogContentContainer.nativeElement.classList.add('show');
    }, 400);

    if (this.data) {
      Object.assign(componentRef.instance, this.data);
    }
  }

  ngAfterViewInit(): void {
    this.dialog.nativeElement.classList.add('show');
    setTimeout(() => {
      this.dialogContentEl.nativeElement.classList.add('show');
    }, 400);
  }
}
