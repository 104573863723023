<h1 class="font-effraHeavy text-white text-5xl mb-4 antialiased">
  Instructions
</h1>
<h2 class="font-effraHeavy text-pink text-3xl mb-4 antialiased">
  Compete against other players for galactic glory in the $PLAY Cup!
</h2>
<ol class="font-effra text-white antialiased">
  <li>Enter the Arena at Station 1 to create or link your PLAY account!</li>
  <li>
    Explore the system & unlock every space station! Each station has a
    different way to earn points on the $PLAY Cup Leaderboard.
  </li>
  <li>
    Top ranking players get whitelist spots for the $PLAY presale on CoinList +
    an early adoption bonus item for use in the next chapters!
  </li>
</ol>

@if (showStartButton) {
<div class="mt-4 flex items-center justify-start">
  <input type="checkbox" id="dontShowAgain" [(ngModel)]="dontShowAgain" />
  <label
    for="dontShowAgain"
    class="font-effra text-md text-white antialiased ml-2"
    >Don't show these Instructions again</label
  >
</div>
<div (click)="start()" class="mx-auto my-6 w-20 action-button cursor-crosshair">
  Start
</div>
} @else {
<div
  (click)="start()"
  class="mx-auto my-6 w-20 action-button-violet cursor-crosshair"
>
  Continue
</div>
}
