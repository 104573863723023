// src/app/state/effects/achievements.effects.ts
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AchievementsActions from '../actions/achievements.actions';
import { AchievementsService } from '@readygg/ng-api';

import { EMPTY, from } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '../app.state';

@Injectable()
export class AchievementsEffects {
  loadAchievementsByTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AchievementsActions.loadAchievementsByTags),
      mergeMap((action) =>
        from(
          this.achievementsService.achievementsGetByTagsWithUserData({
            body: {
              tags: action.tags,
              limit: 100,
              withHistory: true,
            },
          })
        ).pipe(
          map((response) =>
            AchievementsActions.loadAchievementsByTagsSuccess({
              achievements: response.achievements || [],
              userAchievements: response.userAchievements || [],
              loadedAt: new Date(),
            })
          )
        )
      )
    )
  );

  triggerAchievement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AchievementsActions.triggerAchievement),
        mergeMap((action) =>
          this.achievementsService
            .achievementsTriggerById({
              body: {
                id: action.achievementId || '',
                appId: '',
              },
            })
            .pipe(
              map((response) => {
                this.store.dispatch(
                  AchievementsActions.triggerAchievementResult({
                    achievementId: action.achievementId,
                    success: true,
                    rewardsHaveBeenGivenOut:
                      response.rewardsHaveBeenGivenOut || null,
                    rewards: response.rewards || null,
                  })
                );
                this.store.dispatch(
                  AchievementsActions.setAchievementToTrigger({
                    achievement: null,
                  })
                );
                return EMPTY;
              }),
              catchError((error) => {
                console.error('Failed to trigger achievement', error);
                this.store.dispatch(
                  AchievementsActions.triggerAchievementResult({
                    achievementId: action.achievementId,
                    success: false,
                    rewardsHaveBeenGivenOut: null,
                    rewards: null,
                  })
                );
                this.store.dispatch(
                  AchievementsActions.setAchievementToTrigger({
                    achievement: null,
                  })
                );
                return EMPTY;
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private achievementsService: AchievementsService,
    private store: Store<RootState>
  ) {}
}
