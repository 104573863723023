import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-socials-bar',
  standalone: true,
  templateUrl: './socials-bar.component.html',
  styleUrls: ['./socials-bar.component.scss'],
  imports: [CommonModule],
})
export class SocialsBarComponent implements OnInit {
  @Input() bottomGradient?: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
