import { ActionReducerMap } from '@ngrx/store';
import { universeReducer, UniverseState } from './reducers/universe.reducer';
import { AchievementsState } from './models/achievements.model';
import { achievementsReducer } from './reducers/achievements.reducer';
import { SocialState } from './models/social.model';
import { socialReducer } from './reducers/social.reducer';

export interface RootState {
  universe: UniverseState;
  achievements: AchievementsState;
  social: SocialState;
}

export const reducers: ActionReducerMap<RootState> = {
  universe: universeReducer,
  achievements: achievementsReducer,
  social: socialReducer,
};
