<div #universe class="absolute top-0 bottom-0 left-0 right-0 z-10">
  <main
    #rdyxContainer
    class="container bg flex justify-around relative"
    [ngClass]="{ 'bg-rings': launched }"
  >
    <app-floating-elements />
    <div
      (click)="showContent($event)"
      class="planet-container header-links flex flex-col items-center"
      [ngClass]="{ 'cursor-crosshair': !launched }"
    >
      <button
        *ngIf="
          !rdyxCupBadgeClaimed &&
          isWhiteListed &&
          isEventEnded &&
          isAuthenticated
        "
        class="absolute z-50 text-center claim-badge font-poppins text-lg text-white ml-3 p-1 px-6 border-2 border-pink rounded-full bg-pink hover:border-white cursor-crosshair"
        (click)="onClaimBadge()"
        [disabled]="claimingRdyxCupBadge"
      >
        {{ claimingRdyxCupBadge ? "Claiming prize..." : "Claim Your Prize!" }}
        <span class="arrow next bounceAlpha"></span>
      </button>

      @if(!launched) {
      <div class="planet-outline"></div>
      }
      <div
        class="planet-label font-poppins leading-8 text-center w-full left-0"
      >
        <h1 class="text-gold text-3xl text-nowrap antialiased">
          Chapter 1
          <span *ngIf="isEventEnded && isAuthenticated">Ended!</span>
        </h1>
      </div>

      <div class="title text-center leading-8 pointer-events-none">
        <h1
          class="font-effraHeavy text-gold text-6xl antialiased pointer-events-none uppercase"
        >
          $PLAY CUP
        </h1>
        <h2
          class="font-poppins text-gold italic tracking-widest text-xl text-bluishGray pointer-events-none mt-2"
        >
          featuring
        </h2>

        <a
          href="https://coinlist.co/"
          target="_blank"
          [ngClass]="{
            'pointer-events-none': !launched,
            'pointer-events-auto': launched
          }"
          class="coinlist-logo px-6 border-2 rounded-full border-pink hover:border-white flex items-center justify-center"
        >
          <span></span>
        </a>
      </div>
    </div>

    <div [ngClass]="{ 'fade-in': launched, 'fade-out': !launched }">
      <app-stations
        [station3Locked]="station3Locked"
        [station2Locked]="station2Locked"
        [leaderboardId]="leaderboardId"
        [isAuthenticated]="isAuthenticated"
        [userEntryData]="userEntryData"
        (stationClick)="stationClickHandler($event)"
        (setStationFocusArea)="setStationFocusArea($event)"
        [launched]="launched"
      ></app-stations>

      <img
        class="hidden sm:block absolute orbit-img delay-75"
        src="assets/images/orbit-masked.gif"
        alt=""
      />
    </div>
  </main>
</div>
