import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './arrow-button.component.html',
  styleUrl: './arrow-button.component.scss',
})
export class ArrowButtonComponent {
  @Input() label: string = '';
  @Input() back: boolean = false;
  @Input() flat: boolean = false;
}
