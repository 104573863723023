<div class="flex flex-column justify-center h-screen align-items-center">
  <h1 class="text-center font-bold text-2xl">
    Completing the {{ achievementName || "required" }} step...
  </h1>
  <p-progressSpinner
    styleClass="w-8rem h-8rem"
    strokeWidth="2"
    animationDuration=".5s"
  >
  </p-progressSpinner>
</div>
