<div class="dialog-backdrop" (click)="close()"></div>
<div
  class="dialog starting"
  #dialog
  [ngClass]="{ fullscreen: data.fullscreen }"
>
  <div class="frame action-frame">
    <div class="content z-10 relative pointer-events-auto" #dialogContent>
      <button
        (click)="close()"
        class="fixed icon icon-close close-button bg-white text-white hover:text-pink text-5xl font-poppins cursor-crosshair z-99"
      ></button>
      <div class="content-container" #dialogContentContainer>
        <div #dialogContentBase></div>
      </div>
    </div>
  </div>
</div>
