<button
  [ngClass]="{ flat: flat, withLabel: label.length, back: back }"
  class="arrow-button relative drop-shadow-glow inline-flex items-center justify-center rounded-lg transition duration-300 text-pink transition-shadow cursor-crosshair"
>
  <div class="-ml-3 relative arrow-button-icon">
    <span class="arrow primera next bounceAlpha"></span>
    <span class="arrow segunda next bounceAlpha"></span>
  </div>
</button>
@if(label.length>0) {
<div
  class="arrow-label cursor-crosshair absolute text-white text-xl uppercase font-effra antialiased"
>
  {{ label }}
</div>
}
