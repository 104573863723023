import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { IAuthNewUserData } from '../../../../interfaces/auth.interface';
import { DialogService } from '../../../../services/dialog.service';
import { InventoryItem } from '../../../../interfaces/inventory.interface';
import { InventoryDialogComponent } from '../../inventory-dialog/inventory-dialog.component';
import { InstructionsDialogComponent } from '../../instructions-dialog/instructions-dialog.component';
import { ReferDialogComponent } from '../../refer-dialog/refer-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { UniverseActions } from '../../../../store/actions/universe.actions';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit {
  @Input() displayName!: string | undefined;
  @Input() launched: boolean = false;
  @Input() inventory: (InventoryItem & { image: string })[] = [];
  user$: Observable<IAuthNewUserData | null> | undefined;
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.user$ = this.authService.user$;

    this.actions$.pipe(ofType(UniverseActions.openInventory)).subscribe(() => {
      this.openInventory();
    });
  }

  openAuthPopup() {
    this.authService.runOAuth('signin', true);
  }
  logout(): void {
    this.authService.signOut();
    this.router.navigate(['/home']);
  }

  proceed() {
    if (!this.authService.isAuthenticated()) {
      this.openAuthPopup();
      return false;
    } else {
      return true;
    }
  }

  refer() {
    this.proceed() && this.dialogService.openDialog(ReferDialogComponent, {});
  }

  openInventory() {
    this.proceed() &&
      this.dialogService.openDialog(InventoryDialogComponent, {
        inventory: this.inventory,
      });
  }

  openInstructions(firstTime: boolean = false) {
    this.dialogService.openDialog(InstructionsDialogComponent, {
      showStartButton: firstTime,
      identifier: 'instructions',
    });
  }
}
