// src/app/state/actions/social.actions.ts
import { createAction, props } from '@ngrx/store';
import { ReadyGGAchievementData } from '@readygg/ng-api';

export const connectTwitter = createAction(
  '[Social] Connect Twitter',
  props<{ achievement: ReadyGGAchievementData | null }>()
);

export const connectDiscord = createAction(
  '[Social] Connect Discord',
  props<{ achievement: ReadyGGAchievementData | null; callbackUrl: string }>()
);

export const setTwitterAuthData = createAction(
  '[Social] Set Twitter Auth Data',
  props<{ url: string; twitterCodeVerifier: string; twitterState: string }>()
);

export const handleDiscordCallback = createAction(
  '[Social] Handle Discord Callback',
  props<{ code: string }>()
);

export const handleTwitterCallback = createAction(
  '[Social] Handle Twitter Callback',
  props<{ code: string; state: string }>()
);
