// src/app/state/effects/social.effects.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { SocialService } from '@readygg/ng-api';
import * as SocialActions from '../actions/social.actions';
import * as AchievementsActions from '../actions/achievements.actions';
import { EMPTY, Observable, of } from 'rxjs';
import {
  selectTwitterCodeVerifier,
  selectTwitterState,
} from '../selectors/social.selectors';
import { selectCurrentAchievementId } from '../selectors/achievements.selectors';
import { RootState } from '../app.state';

@Injectable()
export class SocialEffects {
  connectTwitter$;
  connectDiscord$;
  handleDiscordCallback$;
  handleTwitterCallback$;

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private socialService: SocialService,
    private router: Router
  ) {
    this.connectTwitter$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(SocialActions.connectTwitter),
          mergeMap(({ achievement }) =>
            this.getTwitterAuthUrl().pipe(
              map((response) => {
                this.store.dispatch(
                  SocialActions.setTwitterAuthData({
                    url: response?.url || '',
                    twitterCodeVerifier: response?.codeVerifier || '',
                    twitterState: response?.state || '',
                  })
                );
                this.store.dispatch(
                  AchievementsActions.setAchievementToTrigger({ achievement })
                );
                this.openUrl(response.url);
              })
            )
          )
        ),
      { dispatch: false }
    );

    this.connectDiscord$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(SocialActions.connectDiscord),
          map(({ achievement, callbackUrl }) => {
            this.connectDiscord(callbackUrl);
            this.store.dispatch(
              AchievementsActions.setAchievementToTrigger({ achievement })
            );
          })
        ),
      { dispatch: false }
    );

    this.handleDiscordCallback$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(SocialActions.handleDiscordCallback),
          withLatestFrom(this.store.pipe(select(selectCurrentAchievementId))),
          mergeMap(([action, achievementId]) => {
            return this.socialService
              .socialUpdateDiscordUserInfo({
                body: {
                  discordCode: action.code,
                  redirectUrl:
                    window.location.origin + '/social/oauth/callback/discord',
                },
              })
              .pipe(
                map((_) => {
                  if (achievementId) {
                    router.navigate([
                      '/social/achievement-trigger-result',
                      achievementId,
                    ]);
                    this.store.dispatch(
                      AchievementsActions.triggerAchievement({ achievementId })
                    );
                  } else {
                    console.error('No achievement ID found');
                  }
                  return EMPTY;
                }),
                catchError((error) => {
                  console.error('Failed to update Discord user info', error);
                  return EMPTY;
                })
              );
          })
        ),
      { dispatch: false }
    );

    this.handleTwitterCallback$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(SocialActions.handleTwitterCallback),
          withLatestFrom(
            this.store.pipe(select(selectTwitterCodeVerifier)),
            this.store.pipe(select(selectTwitterState)),
            this.store.pipe(select(selectCurrentAchievementId))
          ),
          mergeMap(
            ([
              handleTwitterCallbackAction,
              twitterCodeVerifier,
              twitterState,
              achievementId,
            ]) => {
              if (handleTwitterCallbackAction.state !== twitterState) {
                console.error(
                  'Twitter state mismatch: ',
                  handleTwitterCallbackAction.state,
                  twitterState
                );
              }
              return this.socialService
                .socialUpdateTwitterUserInfo({
                  body: {
                    twitterCode: handleTwitterCallbackAction.code,
                    codeVerifier: twitterCodeVerifier,
                    redirectUrl:
                      window.location.origin + '/social/oauth/callback/twitter',
                  },
                })
                .pipe(
                  mergeMap((_) => {
                    if (achievementId) {
                      router.navigate([
                        '/social/achievement-trigger-result',
                        achievementId,
                      ]);
                      this.store.dispatch(
                        AchievementsActions.triggerAchievement({
                          achievementId,
                        })
                      );
                    } else {
                      console.error('No achievement ID found');
                    }
                    return EMPTY;
                  }),
                  catchError((error) => {
                    console.error('Failed to update Twitter user info', error);
                    return EMPTY;
                  })
                );
            }
          )
        ),
      { dispatch: false }
    );
  }

  getTwitterAuthUrl(): Observable<{
    url?: string;
    codeVerifier?: string;
    state?: string;
  }> {
    return this.socialService.socialGenerateTwitterOAuthUrl({
      body: {
        redirectUrl: window.location.origin + '/social/oauth/callback/twitter',
        scopes: [
          'tweet.read',
          'users.read',
          'like.read',
          'follows.read',
          'offline.access',
        ],
      },
    });
  }

  connectDiscord(callbackUrl: string): void {
    const redirectUrl =
      window.location.origin + '/social/oauth/callback/discord';
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);
    const finalCallbackUrl =
      callbackUrl + '&redirect_uri=' + encodedRedirectUrl;
    console.log(finalCallbackUrl);
    this.openUrl(finalCallbackUrl);
  }

  openUrl(url: string | null | undefined): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }
}
