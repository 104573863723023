<div
  [ngClass]="{ isLocked: locked }"
  class="group station text-white text-center p-4 pointer-events-auto"
  (click)="stationClickHandler($event)"
>
  <div class="station-img-container relative">
    <img
      [src]="imgSrc"
      alt="{{ title }}"
      class="floating station-img relative transition-all"
    />
    <img
      src="assets/images/glow.png"
      class="floating glow absolute -z-1 mix-blend-lighten max-w-none opacity-70 transition-opacity duration-300"
    />
    <img
      src="assets/images/glow.gif"
      class="glow animated absolute -z-10 mix-blend-lighten max-w-none transition-opacity duration-1000"
    />
  </div>
  <div class="station-content flex flex-col items-end p-4 text-left leading-0">
    <div class="flex justify-end gap-4 items-end">
      <div class="station-children leading-5">
        <ng-content></ng-content>
      </div>
      @if (!locked) {
      <div class="h-10">
        <app-arrow-button></app-arrow-button>
      </div>
      } @if (locked) {
      <div
        class="station-action-button relative border-2 rounded-lg border-bluishGray inline-flex items-center justify-center"
      >
        <span
          class="station-action-button-icon relative icon icon-lock w-14 h-12"
        ></span>
      </div>
      }
    </div>
    @if (locked) {
    <div
      class="mt-3 font-poppins text-sm whitespace-nowrap bg-bluishGray w-fit px-4 h-6 text-center rounded-xl leading-6"
    >
      {{ score }}/{{ points }} Points to unlock
    </div>
    }
  </div>
</div>
