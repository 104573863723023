import { NgFor } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';

function getRandom(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

@Component({
  selector: 'app-stars',
  standalone: true,
  imports: [NgFor],
  templateUrl: './stars.component.html',
  styleUrl: './stars.component.scss',
})
export class StarsComponent implements AfterViewInit {
  stars: { top: string; left: string; delay: string }[] = [];
  constructor() {}

  generateStars(): void {
    for (let i = 0; i < 60; i++) {
      const top = `${getRandom(0, 100)}vh`;
      const left = `${getRandom(0, 100)}vw`;
      const delay = `${getRandom(0, 15)}s`;
      this.stars.push({ top, left, delay });
    }
  }

  ngAfterViewInit() {
    this.generateStars();
  }
}
