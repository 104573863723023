import { createSelector } from '@ngrx/store';
import { UniverseState } from '../reducers/universe.reducer';
import { RootState } from '../app.state';

// Select the feature state
const selectUniverseState = (state: RootState) => state.universe;

export const selectLeaderboardData = createSelector(
  selectUniverseState,
  (state: UniverseState) => state.leaderboardData
);

export const selectActiveLeaderboardData = createSelector(
  selectUniverseState,
  (state: UniverseState) => state.activeLeaderboardData
);

export const selectUserEntry = createSelector(
  selectUniverseState,
  (state: UniverseState) => state.userEntry
);

export const selectVirtualItems = createSelector(
  selectUniverseState,
  (state: UniverseState) => state.virtualItems
);

export const selectInventory = createSelector(
  selectUniverseState,
  (state: UniverseState) => state.inventory
);

export const selectClaimStatus = createSelector(
  selectUniverseState,
  (state: UniverseState) => state.claiming
);
