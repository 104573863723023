@if(requirements$ | async; as requirements) {
<p-stepper orientation="vertical" [activeStep]="activeStepIndex">
  @if(achievement$ | async; as rootAchievement) {
  <ng-container>
    @for(requirement of requirements; track $index) {
    <p-stepperPanel
      [header]="
        getStepActionDescription(requirement) +
        (userAchievement?.isCompleted ||
        requirement.requiredUserAchievement?.isCompleted
          ? ' (Done)'
          : '')
      "
    >
      <ng-template pTemplate="content">
        @if(requirement.mergedType === 'achievement_by_id') {
        <div class="justify-content-left align-items-left font-medium mt-4">
          {{ getStepContent(requirement?.requiredAchievement?.requiredToComplete?.requirements?.[0] || null) }}
        </div>
        }

        <div class="flex flex-column md:flex-row py-4 gap-2">
          @if(requirement.mergedType === 'achievement_by_id') {
          <p-button
            [label]="getButtonLabel(requirement?.requiredAchievement?.requiredToComplete?.requirements?.[0] || null)"
            (onClick)="
              openTheRequirementForConnectSocialAccount(
                requirement?.requiredAchievement || null
              )
            "
            [disabled]="
              userAchievement?.isCompleted ||
              requirement.requiredUserAchievement?.isCompleted
            "
          />
          }

          <!-- Card for Discord and Twitter requirements -->
          @if([ 'discord_joined_server', 'discord_has_role',
          'twitter_retweeted', 'twitter_liked', 'twitter_replied'
          ].includes(requirement.type)) {
          <ng-container>
            <p-fieldset
              class="flex-1 gap-2 mr-4"
              [legend]="'Step 1: ' + getStepContent(requirement)"
            >
              <ng-template pTemplate="content">
                <div class="mt-4 text-xl">{{ rootAchievement.name }}</div>
                <div class="mt-4">
                  {{ getStepDetailedDescription(requirement) }}
                </div>
                <div
                  class="justify-content-left align-items-left font-medium gap-2 mt-4"
                >
                  <p-button
                    [label]="getButtonLabel(requirement)"
                    (onClick)="openAchievementUrl()"
                  />
                </div>
              </ng-template>
            </p-fieldset>

            <p-fieldset
              class="flex-1 gap-2 mr-4"
              legend="Step 2: Claim The Reward"
              [style]="{ height: '100%' }"
            >
              <ng-template pTemplate="content">
                <div class="text-xl">
                  Reward {{ rootAchievement.rewards?.[0]?.quantity || 0 }} PL▶️Y
                  Coins.
                </div>
                <div
                  class="mt-4 justify-content-left align-items-left font-medium gap-2"
                >
                  <p-button
                    label="Get the reward"
                    (onClick)="triggerAchievement(rootAchievement?.id || '')"
                    [disabled]="
                      userAchievement?.isCompleted ||
                      requirement.requiredUserAchievement?.isCompleted
                    "
                  />
                </div>
              </ng-template>
            </p-fieldset>
          </ng-container>
          }

          <!-- Buttons for connecting social accounts -->
          @if(requirement.type === 'social_connected' && requirement.id ===
          'twitter') {
          <p-button
            label="Connect Twitter"
            (onClick)="connectTwitter(rootAchievement)"
            [disabled]="
              userAchievement?.isCompleted ||
              requirement.requiredUserAchievement?.isCompleted
            "
          />
          } @if(requirement.type === 'social_connected' && requirement.id ===
          'discord'){
          <p-button
            label="Connect Discord"
            (onClick)="connectDiscord(rootAchievement)"
            [disabled]="
              userAchievement?.isCompleted ||
              requirement.requiredUserAchievement?.isCompleted
            "
          />
          }
        </div>

        @if(userAchievement || requirement.requiredUserAchievement ) {
        <div class="flex py-4 gap-2 font-bold text-mi text-xl">
          <span
            >Received
            {{ userAchievement?.history?.[0]?.rewards?.[0]?.quantity || 0 }}
            PL▶️Y Coins reward at
            {{ userAchievement?.lastCompleteTime | date : "short" }}</span
          >
        </div>
        }
      </ng-template>
    </p-stepperPanel>
    }
  </ng-container>
  }
</p-stepper>
}
