import { createAction, props } from '@ngrx/store';
import {
  ReadyGGAchievementData,
  ReadyGGAchievementReward,
  ReadyGGUserAchievement,
} from '@readygg/ng-api';

export const loadAchievementsByTags = createAction(
  '[Achievements] Load Achievements By Tags',
  props<{ tags: string[] }>()
);

export const loadAchievementsByTagsSuccess = createAction(
  '[Achievements] Load Achievements By Tags Success',
  props<{
    achievements: ReadyGGAchievementData[];
    userAchievements: ReadyGGUserAchievement[];
    loadedAt: Date;
  }>()
);

export const setAchievementToTrigger = createAction(
  '[Achievements] Set Achievement To Trigger',
  props<{ achievement: ReadyGGAchievementData | null }>()
);

export const triggerAchievement = createAction(
  '[Achievements] Trigger Achievement',
  props<{ achievementId: string }>()
);

export const triggerAchievementResult = createAction(
  '[Achievements] Trigger Achievement Result',
  props<{
    achievementId: string;
    success: boolean;
    rewardsHaveBeenGivenOut: boolean | null;
    rewards: Array<ReadyGGAchievementReward> | null;
  }>()
);
