import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DIALOG_DATA } from '../../../services/dialog.service';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { details as inventoryDetails } from './inventory-details';
import { InventoryItem } from '../../../interfaces/inventory.interface';

@Component({
  selector: 'app-inventory-dialog',
  standalone: true,
  imports: [FormsModule, NgStyle, NgClass, NgTemplateOutlet],
  templateUrl: './inventory-dialog.component.html',
  styleUrl: './inventory-dialog.component.scss',
})
export class InventoryDialogComponent implements OnInit {
  inventorySlots: (InventoryItem & { image: string })[] = [];
  selectedNft: string = '';
  title: string = '';
  line1: string = '';
  line2: string = '';
  virtualItemImage: string = '';

  constructor(
    @Inject(DIALOG_DATA)
    public inventory: (InventoryItem & { image: string })[]
  ) {}

  ngOnInit(): void {
    this.inventorySlots = Array(8).fill(null);
    this.inventorySlots.splice(0, this.inventory.length, ...this.inventory);
    this.selectNft(this.inventorySlots[0]);
  }
  selectNft(item: InventoryItem & { image: string }) {
    if (!item) return;
    this.selectedNft = item.virtualItemId;

    const selectedTag = item.tags[1];
    const isTopQualifier = selectedTag.includes('Top');
    const detailsIdentifier = isTopQualifier ? 'top' : selectedTag;

    const selectedItemDetails =
      inventoryDetails[detailsIdentifier as keyof typeof inventoryDetails];

    this.title = selectedItemDetails.title;
    this.line1 = selectedItemDetails.line1;
    this.line2 = selectedItemDetails.line2;
    this.virtualItemImage = item.image;
  }
}
