import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { UserEntry } from '../../../interfaces/entry.interface';
import { IAuthNewUserData } from '../../../interfaces/auth.interface';
import { AuthService } from '../../../services/auth.service';
import { InventoryItem } from '../../../interfaces/inventory.interface';
import { ScoreComponent } from './score/score.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink, ScoreComponent, ToolbarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  @Input() userEntryData: UserEntry | undefined;
  @Input() displayName!: string | undefined;
  @Input() isEnded: boolean = false;
  @Input() inventory: (InventoryItem & { image: string })[] = [];
  @Input() launched: boolean = false;

  user$: Observable<IAuthNewUserData | null> | undefined;
  isUserRanked = false;
  refittedText: any;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.user$ = this.authService.user$;
  }
}
