import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { UserEntry } from '../../interfaces/entry.interface';
import { DialogService } from '../../services/dialog.service';
import { StationsComponent } from './stations/stations.component';
import { HeaderComponent } from './header/header.component';
import { FloatingElementsComponent } from './floating-elements/floating-elements.component';
import { PanzoomComponent } from '../../components/panzoom/panzoom.component';
import { UniverseActions } from '../../store/actions/universe.actions';
import { DeviceService } from '../../services/device.service';
import { combineLatest, filter, Observable, take } from 'rxjs';
import {
  selectClaimStatus,
  selectInventory,
  selectLeaderboardData,
  selectVirtualItems,
} from '../../store/selectors/universe.selectors';
import { RootState } from '../../store/app.state';
import { AuthService } from '../../services/auth.service';
import { Leaderboard } from '../../interfaces/leaderboard.interface';
import { Inventory } from '../../interfaces/inventory.interface';
import { Actions, ofType } from '@ngrx/effects';

interface FocusArea {
  el: any;
  depth: number;
  jumpTo: boolean;
}

@Component({
  selector: 'app-rdyx-cup',
  standalone: true,
  templateUrl: 'rdyx-cup.component.html',
  styleUrls: ['./rdyx-cup.component.scss'],
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    StationsComponent,
    HeaderComponent,
    FloatingElementsComponent,
    PanzoomComponent,
  ],
  providers: [DeviceService],
})
export class RdyxCupComponent implements OnInit {
  @Input() isAuthenticated: boolean = false;
  @Input() userEntryData: UserEntry | undefined;
  @Input() station2Locked: undefined | boolean;
  @Input() station3Locked: undefined | boolean;

  @Input() launched: boolean = false;

  @Output() launch = new EventEmitter<void>();
  @Output() stationLaunch = new EventEmitter<void>();
  @Output() setFocusArea = new EventEmitter<FocusArea>();

  @ViewChild('rdyxContainer') rdyxContainer!: ElementRef;
  @ViewChild('universe') universe!: ElementRef;
  showElements: boolean = true;

  isEventEnded: boolean = false;
  isWhiteListed: boolean = false;
  leaderboard: any;
  leaderboardId!: string;
  leaderboardPosition: number | null = null;
  rdyxCupBadgeClaimed: boolean = true;
  claimingRdyxCupBadge: boolean = false;
  rdyxCupBadgeVirtualItemId: string = '';

  virtualItems$: Observable<any> = this.store.pipe(select(selectVirtualItems));

  leaderboardData$: Observable<Leaderboard | undefined> = this.store.pipe(
    select(selectLeaderboardData)
  );
  inventory$: Observable<Inventory | undefined> = this.store.pipe(
    select(selectInventory)
  );

  claiming$: Observable<boolean> = this.store.pipe(select(selectClaimStatus));

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private _device: DeviceService,
    private store: Store<RootState>,
    private actions$: Actions
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['launched']) {
      if (this.launched) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    }
    if (changes['userEntryData']) {
      const data = this.userEntryData;
      if (data) {
        this.leaderboardPosition = data.place ?? null;
        this.isWhiteListed = data.place! <= 3000;
        this.fetchNftTagsToClaim();
      }
    }
  }

  setStationFocusArea(el: any) {
    this.setFocusArea.emit({
      el,
      depth: 2,
      jumpTo: false,
    });
  }

  ngAfterViewInit(): void {
    if (this._device.isMobile()) {
      this.setFocusArea.emit({
        el: this.universe.nativeElement,
        depth: 1,
        jumpTo: true,
      });
    }
  }

  fadeIn() {
    setTimeout(() => {
      this.showElements = true;
    }, 0);
  }

  fadeOut() {
    setTimeout(() => {
      this.showElements = false;
    }, 1000);
  }

  showContent(ev: any) {
    if (this.launched) return;
    this.launch.emit({
      currentTarget: this.rdyxContainer.nativeElement,
    } as any);
    this.launched = true;
    this.store.dispatch(
      UniverseActions.setActiveLeaderboard({
        data: this.leaderboard,
      })
    );
  }

  ngOnInit(): void {
    this.store.dispatch(
      UniverseActions.loadLeaderboard({
        leaderboardName: 'coinlist_presale_event',
      })
    );
    this.dialogService.dialogClose$.subscribe((identifier) => {
      if (identifier === 'station') {
        this.launch.emit({
          currentTarget: this.rdyxContainer.nativeElement,
        } as any);
      }
    });

    this.claiming$.subscribe((claiming) => {
      this.claimingRdyxCupBadge = claiming;
    });

    this.actions$
      .pipe(ofType(UniverseActions.claimBadgeSuccess))
      .subscribe(() => {
        this.store.dispatch(UniverseActions.getInventory());
        this.store.dispatch(UniverseActions.openInventory());
      });

    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.fetchLeaderboard(isAuthenticated);
    });

    combineLatest([this.inventory$, this.virtualItems$])
      .pipe(
        filter(([inventory, virtualItems]) => {
          return !!inventory && virtualItems.hasOwnProperty('virtualItems');
        }),
        take(1)
      )
      .subscribe(([inventory, virtualItems]) => {
        //If there are no virtual items avalable
        if (virtualItems.virtualItems.length === 0) return;

        //Get the first virtual item that should be claimed
        const badgeItem = virtualItems.virtualItems[0];
        if (badgeItem) {
          this.rdyxCupBadgeVirtualItemId = badgeItem.id;
        }

        //Check if you already have this virtual item
        //YES : you already have it, so no need to claim it again
        //NO : then enable the CLAIM button
        this.rdyxCupBadgeClaimed = [...(inventory?.items ?? [])].some(
          (item) => {
            return (
              item.virtualItemId === this.rdyxCupBadgeVirtualItemId &&
              this.isEventEnded
            );
          }
        );
      });
  }

  fetchNftTagsToClaim() {
    if (this.isEventEnded && this.leaderboardPosition !== null) {
      const tag =
        this.leaderboardPosition! > 50
          ? 'coinlistRdyxCup'
          : 'Top' + this.leaderboardPosition;

      this.store.dispatch(UniverseActions.getInventory());
      this.store.dispatch(UniverseActions.getVirtualItems({ tag }));
    }
  }

  fetchLeaderboard(isLoggedin: boolean = true) {
    this.leaderboardData$.subscribe((data) => {
      if (data) {
        this.leaderboard = data;
        this.leaderboardId = data.id;
        this.isEventEnded = data.time.end < Date.now();
        isLoggedin &&
          this.store.dispatch(
            UniverseActions.getUserEntry({ leaderboardId: data.id })
          );
      }
    });
  }

  stationClickHandler(el: any) {
    this.stationLaunch.emit(el);
  }

  onClaimBadge() {
    if (this.rdyxCupBadgeVirtualItemId) {
      this.store.dispatch(
        UniverseActions.claimBadge({
          virtualItemId: this.rdyxCupBadgeVirtualItemId,
        })
      );
    }
  }
}
