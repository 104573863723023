import { RouterLink, RouterOutlet } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HeaderComponent } from '../rdyx-cup/header/header.component';
import { PanzoomComponent } from '../../components/panzoom/panzoom.component';
import { RdyxCupComponent } from '../rdyx-cup/rdyx-cup.component';
import { SocialsBarComponent } from '../../components/socials-bar/socials-bar.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { ArrowButtonComponent } from '../../components/arrow-button/arrow-button.component';
import { UniverseActions } from '../../store/actions/universe.actions';
import { RootState } from '../../store/app.state';
import {
  selectLeaderboardData,
  selectUserEntry,
  selectInventory,
} from '../../store/selectors/universe.selectors';
import { UserEntry } from '../../interfaces/entry.interface';
import { Inventory, InventoryItem } from '../../interfaces/inventory.interface';
import { AuthService } from '../../services/auth.service';
import { StarsComponent } from '../../components/stars/stars.component';
import { Leaderboard } from '../../interfaces/leaderboard.interface';
import { TemplateChapterComponent } from '../template-chapter/template-chapter.component';

@Component({
  selector: 'app-universe',
  standalone: true,
  templateUrl: 'universe.component.html',
  styleUrls: ['universe.component.scss'],
  imports: [
    NgClass,
    RouterLink,
    RouterOutlet,
    AsyncPipe,
    HeaderComponent,
    PanzoomComponent,
    SocialsBarComponent,
    RdyxCupComponent,
    ArrowButtonComponent,
    StarsComponent,
    TemplateChapterComponent,
  ],
})
export class UniverseComponent implements OnInit {
  userEntry$: Observable<UserEntry | undefined> = this.store.pipe(
    select(selectUserEntry)
  );
  inventory$: Observable<Inventory | undefined> = this.store.pipe(
    select(selectInventory)
  );
  userEntryData: UserEntry | undefined;
  station2Locked: undefined | boolean;
  station3Locked: undefined | boolean;
  purchasedItems: (InventoryItem & { image: string })[] = [];
  @ViewChild(PanzoomComponent) panzoomComponent!: PanzoomComponent;
  @ViewChild('firstchapter', { static: false })
  firstChapterEl!: ElementRef<HTMLDivElement>;
  elementsVisible: boolean = true;
  depth: number = 1;
  depth2El: any;

  focusArea1: any;
  focusArea2: any;
  focusArea3: any;

  constructor(
    private authService: AuthService,
    private store: Store<RootState>
  ) {}

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.store.dispatch(UniverseActions.getInventory());

        this.userEntry$.subscribe((data) => {
          if (data) {
            this.userEntryData = data;
            this.updateUserEntryData(data);
          }
        });
        this.fetchNfts();
      } else {
        this.station2Locked = this.station3Locked = true;
      }
    });
  }

  showElements() {
    setTimeout(() => {
      this.elementsVisible = true;
    }, 0);
  }

  setFocusArea({ el, depth = 1, jumpTo = false }: any) {
    (this as any)['focusArea' + depth] = el;
    if (!jumpTo) return;
    setTimeout(() => {
      this.panzoomComponent.zoomToElement(el, depth);
    }, 1500);
  }

  hideElements() {
    setTimeout(() => {
      this.elementsVisible = false;
    }, 500); // Match this duration with the CSS transition duration
  }

  goToChapter(el: any) {
    this.panzoomComponent.zoomToElement(el.currentTarget, 2);
    this.depth = 2;
    this.hideElements();
    this.depth2El = el.currentTarget; //reference to active chapter
  }

  backToUniverse() {
    this.showElements();
    this.panzoomComponent.zoomToElement(
      this.focusArea1 || this.panzoomComponent.panzoomElement.nativeElement,
      1
    );
    this.depth = 1;
  }

  goToStation(el: any) {
    this.panzoomComponent.zoomToElement(el.currentTarget, 3);
    this.depth = 3;
  }

  updateUserEntryData(userEntryData: UserEntry | null) {
    this.userEntryData = userEntryData || undefined;
    if (userEntryData?.score) {
      this.station2Locked = this.userEntryData?.score! < 1500;
      this.station3Locked = this.userEntryData?.score! < 2500;
    } else {
      this.station2Locked = this.station3Locked = true;
    }
  }

  fetchNfts() {
    this.inventory$.subscribe((inventory) => {
      this.purchasedItems =
        [...(inventory?.items ?? [])]
          .sort((a, b) => b.updatedAt - a.updatedAt)
          .map((item) => {
            //this.checkIfRdyxCupBadgeWasClaimed(item.virtualItemId);
            const virtualItem = inventory?.virtualItems.find(
              (vItem) => vItem.id === item.virtualItemId
            );
            return {
              ...item,
              image: virtualItem ? virtualItem.image.source : null,
              tags: virtualItem ? virtualItem.tags : item.tags,
            };
          }) ?? [];
    });
  }
}
