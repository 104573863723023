export const details = {
  coinlistRdyxCup: {
    title: '$PLAY Cup Chapter One Badge',
    line1: 'You earned an NFT Badge from Chapter One of the $PLAY Cup',
    line2:
      'The Chapter 1 Cup grants you 0.1x points in future chapters of the $PLAY Cup! Stay tuned for more chapters on the road to TGE!',
  },
  coinlistReferral: {
    title: '$PLAY Cup Referral Badge',
    line1: 'You earned an NFT Badge for referring 5 friends to the $PLAY Cup',
    line2:
      'The Referral Badge grants you 0.05x points in future chapters of the $PLAY Cup! Stay tuned for more chapters on the road to TGE!',
  },
  top: {
    title: '$PLAY Cup Top Badge',
    line1:
      'The $PLAY Cup Rank Badge is a unique badge based on your position on the leaderboard at the end of Chapter 1.',
    line2:
      'Holding it grants you a 0.15x points in future chapters of the $PLAY Cup. Stay tuned for more chapters on the road to TGE!',
  },
};
