// src/app/features/social-tasks/social-task-item/social-task-item.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  ReadyGGAchievementData,
  ReadyGGUserAchievement,
} from '@readygg/ng-api';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { RootState } from '../../../store/app.state';
import { selectUserAchievementById } from '../../../store/selectors/achievements.selectors';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';

@Component({
  selector: 'app-social-task-item',
  standalone: true,
  imports: [NgClass, AsyncPipe, DatePipe],
  templateUrl: './social-task-item.component.html',
  styleUrl: './social-task-item.component.scss',
})
export class SocialTaskItemComponent {
  @Input() achievement!: ReadyGGAchievementData;
  @Output() selectAchievement = new EventEmitter<string | undefined>();
  rewardQuantity: number = 0;
  userAchievement: Observable<ReadyGGUserAchievement | null> | null = null;

  constructor(private router: Router, private store: Store<RootState>) {}

  ngOnInit(): void {
    this.rewardQuantity = this.achievement?.rewards?.[0]?.quantity || 0;
    this.userAchievement = this.store.select(
      selectUserAchievementById(this.achievement.id || '')
    );
  }

  onClick(): void {
    this.router.navigate(['/social/social-task-details', this.achievement.id]);
  }
}
