import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  APP_INITIALIZER,
  provideZoneChangeDetection,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';

import { AuthService } from './services/auth.service';
import { authInterceptor } from './auth.interceptor';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { UniverseEffects } from './store/effects/universe.effects';
import { reducers } from './store/app.state';
import { AchievementsEffects } from './store/effects/achievements.effects';
import { SocialEffects } from './store/effects/social.effects';
import { ReadyGGApiConfiguration } from '@readygg/ng-api';

function initializeAppFactory(authService: AuthService) {
  return () => authService.init();
}

//TODO : This code is in preparation of the upcoming SOCIAL Connectors
//*************************************************************** */
// export const endpoints = {
//   development: 'https://us-central1-readymaster-development.cloudfunctions.net',
//   development_emulator:
//     'http://127.0.0.1:5001/readymaster-development/us-central1',
//   staging: 'https://us-central1-readysandbox.cloudfunctions.net',
//   staging_emulator: 'http://127.0.0.1:5001/readysandbox/us-central1',
//   production: 'https://us-central1-readymaster-2b268.cloudfunctions.net',
//   production_emulator: 'http://127.0.0.1:5001/readymaster-2b268/us-central1',
// };

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true,
    },
    // {
    //   provide: ReadyGGApiConfiguration,
    //   useValue: {
    //     rootUrl: endpoints['development'],
    //   },
    // },
    provideStore(reducers),
    provideEffects([UniverseEffects, AchievementsEffects, SocialEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
  ],
};
