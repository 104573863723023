<header
  [ngClass]="{ claimed: inventory.length > 0 }"
  class="header w-screen flex justify-between items-center"
>
  <div class="hidden horizontal:hidden sm:block left"></div>
  <div class="center flex items-center justify-center sm:gap-0 gap-3">
    <div class="flex sm:flex-1">
      <a
        href="http://www.ready.gg"
        class="horizontal:ml-10 mx-auto md:mx-6 sm:-mt-5 horizontal:mt-0 pointer-events-auto"
        target="_blank"
      >
        <img
          class="logo hidden md:block horizontal:hidden"
          src="assets/images/logo-white.png"
          alt="PLAY"
        />

        <img
          class="compact-logo block md:hidden horizontal:hidden"
          src="assets/images/mobile-logo.png"
          alt="PLAY"
        />
      </a>
    </div>

    <!-- LOGGED IN HEADER -->
    @if (user$ | async; as user) { @if (launched) {
    <app-score
      class="flex-1"
      [userEntryData]="userEntryData"
      [displayName]="displayName"
      [launched]="launched"
    ></app-score>
    } }
    <app-toolbar
      class="flex-1"
      [launched]="launched"
      [inventory]="inventory"
      [displayName]="displayName"
    ></app-toolbar>
  </div>
  <div class="hidden horizontal:hidden sm:block right"></div>
</header>
