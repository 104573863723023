<div id="particles-js" *ngIf="achievementDialogVisible === true"></div>
<div class="flex flex-column justify-center h-screen align-items-center" *ngIf="!achievementDialogVisible">
  <h1 class="text-center font-bold text-2xl">
    Claiming the rewards...
  </h1>
  <p-progressSpinner 
    styleClass="w-8rem h-8rem" 
    strokeWidth="2"
    animationDuration=".5s">
  </p-progressSpinner>
</div>
<p-dialog 
    [header]="achievementHeader" 
    [(visible)]="achievementDialogVisible" 
    [modal]="true"
    (onHide)="onDialogHide()">
    <div [innerHTML]="achievementMessage"></div>
    <div class="flex justify-content-end gap-2">
        <p-button label="OK" (onClick)="achievementDialogVisible = false" />
    </div>
</p-dialog>
