import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-social',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './social.component.html',
  styleUrl: './social.component.scss',
})
export class SocialComponent {
  constructor(private router: Router) {
    this.router.navigate(['social/achievements']);
  }
}
