// src/app/state/selectors/achievements.selectors.ts
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AchievementsState } from '../models/achievements.model';
import { ReadyGGAchievementData } from '@readygg/ng-api';

export const selectAchievementsState =
  createFeatureSelector<AchievementsState>('achievements');

export const selectAchievements = createSelector(
  selectAchievementsState,
  (state: AchievementsState) => state.achievements
);

export const selectAchievementsLoadedAt = createSelector(
  selectAchievementsState,
  (state: AchievementsState) => state.loadedAt
);

export const selectAchievementById = (id: string) =>
  createSelector(
    selectAchievements,
    (achievements: ReadyGGAchievementData[]) =>
      achievements.find((achievement) => achievement.id === id) || null
  );

export const selectUserAchievementById = (id: string) =>
  createSelector(
    selectAchievementsState,
    (state: AchievementsState) =>
      state.userAchievements.find(
        (userAchievement) => userAchievement.id === id
      ) || null
  );

export const selectCurrentAchievementIdState = createFeatureSelector<
  string | null
>('currentAchievementId');

export const selectCurrentAchievementId = createSelector(
  selectCurrentAchievementIdState,
  (state: string | null) => state
);
