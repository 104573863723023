import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SocialState } from '../models/social.model';

export const selectSocialState = createFeatureSelector<SocialState>('social');

export const selectTwitterCodeVerifier = createSelector(
  selectSocialState,
  (state: SocialState) => state.twitterCodeVerifier
);

export const selectTwitterState = createSelector(
  selectSocialState,
  (state: SocialState) => state.twitterState
);
