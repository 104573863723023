<div class="container mx-auto text-white">
  <div class="mb-8">
    <h1 class="text-3xl sm:text-5xl font-effraHeavy text-pink">
      Enter the Play Arena
    </h1>
  </div>

  <!-- STEP 1 -->
  <div>
    <div class="rounded-lg shadow-lg transform transition duration-500">
      <h2 class="text-3xl font-effraHeavy mb-2 text-left">STEP 1</h2>
      <p class="mb-4 font-poppins">
        Create or login to your PLAY account to start earning points & unlock
        Step 2!
      </p>
      @if (isAuthenticated$ | async) {
      <div class="flex justify-between flex-wrap">
        <button
          class="info-link inline-flex py-2 px-4 rounded-full font-bold mb-4 hover:opacity-70"
          disabled
        >
          Already Logged In
        </button>
        <p
          class="inline-block font-digital text-pink text-5xl text-center vertical-middle"
        >
          +500 Points &checkmark;
        </p>
      </div>
      } @else {
      <button
        class="w-auto info-link border-pink py-2 px-6 rounded-full font-bold mb-4 cursor-crosshair"
        (click)="openAuthPopup()"
      >
        LOGIN
        <p
          class="inline-block ml-6 font-digital text-pink text-3xl text-center vertical-middle"
        >
          +500 Points
        </p>
      </button>

      }
    </div>
    <hr class="border-dashed border-pink" />

    <!-- STEP 2 -->
    @if (isAuthenticated$ | async) {
    <div id="step-2" class="rounded-lg shadow-lg transform">
      <h2 class="text-3xl font-effraHeavy mb-2 text-left mt-4">STEP 2</h2>
      <div>
        <p class="mb-4 font-poppins">
          Complete quests to earn more points & unlock the Social Battleground!
        </p>
        <i class="text-pink text-lg">
          *NOTE* Make sure to use the same email you used for your PLAY account!
        </i>
        <div class="no-box-shadows">
          <iframe
            #iframeEl
            src="https://gleam.io/rBqPT/readygg-coinlist-station-1"
            class="iframe-container h-80 border-0"
            title="Readygg Coinlist Station 1"
          ></iframe>
        </div>
      </div>
    </div>

    <hr class="border-dashed border-pink" />

    } @else {
    <div id="step-2" class="rounded-lg shadow-lg transform">
      <h2 class="text-3xl font-effraHeavy mb-2 text-left mt-4">
        <span class="icon icon-lock mr-3"></span>STEP 2
      </h2>
      <div>
        <p class="mb-4 font-poppins">
          Complete quests to earn more points & unlock the Social Battleground!
        </p>
        <button
          class="bg-gray-600 text-white py-2 px-4 rounded-full font-bold mb-4 hover:opacity-70 border border-borderGray cursor-not-allowed"
          disabled
        >
          Complete Step 1 to Unlock
        </button>
      </div>
    </div>
    }
  </div>
</div>
