import { Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { SocialComponent } from '../../social/social/social.component';
import { AchievementsListComponent } from '../../social/achievements-list/achievements-list.component';
import { SocialTaskDetailsComponent } from '../../social/social-task-details/social-task-details.component';
import { SocialTaskItemComponent } from '../../social/social-task-item/social-task-item.component';

@Component({
  selector: 'app-template-station',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    SocialComponent,
    SocialTaskItemComponent,
    AsyncPipe,
    SocialTaskDetailsComponent,
    AchievementsListComponent,
  ],
  templateUrl: './template-station.component.html',
  styleUrl: './template-station.component.scss',
})
export class TemplateStationComponent implements OnInit {
  isOnAchievementsPage: boolean = true;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isOnAchievementsPage = event.url.includes('social/achievements');
      }
    });
  }
}
