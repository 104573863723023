<div class="text-center">
  <div>
    <h1 class="font-effraHeavy text-white text-5xl mb-4 antialiased">
      Inventory
    </h1>
  </div>

  <div class="flex md:flex-row flex-col horizontal:flex-row">
    <div class="inventory">
      @for (item of inventorySlots; track $index) {
      <div
        [ngClass]="{ active: item?.virtualItemId == selectedNft }"
        class="item-cell action-frame"
        (click)="selectNft(item)"
      >
        <div class="item-cell-content">
          @if (item) {
          <div
            [ngStyle]="{
              'background-image': 'url(' + item.image + ')'
            }"
            class="item"
          ></div>
          }
        </div>
      </div>
      }
    </div>

    <div class="inventory-details action-frame relative">
      <div class="absolute z-99">
        @if (selectedNft) {
        <ng-container *ngTemplateOutlet="badgeTemplate"></ng-container>
        }
      </div>
    </div>
  </div>
</div>

<ng-template #badgeTemplate>
  <div class="details-container blink">
    <h1 class="font-effraHeavy text-white text-2xl mb-4 antialiased">
      {{ title }}
    </h1>
    <div class="flex flex-col">
      <div class="">
        <div
          [ngStyle]="{
            'background-image': 'url(' + virtualItemImage + ')'
          }"
          class="badge h-40 mb-4"
        ></div>
      </div>
      <div class="horizontal:flex-1 text-md">
        <h2 class="font-effraHeavy text-pink mb-4 antialiased">
          {{ line1 }}
        </h2>
        <div class="font-effra text-white antialiased">
          <p>
            {{ line2 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
