// src/app/state/reducers/achievements.reducer.ts
import { createReducer, on } from '@ngrx/store';
import {
  AchievementsState,
  initialAchievementsState,
} from '../models/achievements.model';
import * as AchievementsActions from '../actions/achievements.actions';

export const achievementsReducer = createReducer<AchievementsState>(
  initialAchievementsState,
  on(
    AchievementsActions.loadAchievementsByTagsSuccess,
    (state, { achievements, userAchievements, loadedAt }) => ({
      ...state,
      achievements,
      userAchievements,
      loadedAt,
    })
  ),
  on(AchievementsActions.triggerAchievementResult, (state, { success }) => ({
    ...state,
    loadedAt: null,
  }))
);

export const currentAchievementIdReducer = createReducer<string | null>(
  null,
  on(
    AchievementsActions.setAchievementToTrigger,
    (state, { achievement }) => achievement?.id || null
  )
);
