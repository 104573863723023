import { Component } from '@angular/core';
import { ReadyGGAchievementData } from '@readygg/ng-api';
import { map, Observable, take } from 'rxjs';
import { RootState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { selectAchievements } from '../../../store/selectors/achievements.selectors';
import { loadAchievementsByTags } from '../../../store/actions/achievements.actions';
import { SocialTaskItemComponent } from '../social-task-item/social-task-item.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-achievements-list',
  standalone: true,
  imports: [SocialTaskItemComponent, AsyncPipe],
  templateUrl: './achievements-list.component.html',
  styleUrl: './achievements-list.component.scss',
})
export class AchievementsListComponent {
  achievements$: Observable<ReadyGGAchievementData[]>;
  achievements: any;

  constructor(private store: Store<RootState>) {
    this.achievements$ = this.store.select(selectAchievements);
    this.achievements = this.achievements$.pipe(
      take(1),
      map(() => true)
    );

    const tagsToLoad = ['social-tasks-demo'];
    this.store.dispatch(loadAchievementsByTags({ tags: tagsToLoad }));
  }
}
