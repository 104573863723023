<app-station
  class="station"
  id="station-0"
  (stationClick)="station1ClickHandler($event)"
  title="Enter the Play Arena"
  imgSrc="assets/images/station-1.png"
>
  <div #station1 class="text-right leading-5">
    <h4 class="text-md font-poppins text-borderGray text-right">Station 1</h4>
    <p class="whitespace-nowrap uppercase text-violet text-2xl font-effra">
      Enter the
    </p>
    <p class="whitespace-nowrap uppercase antialiased text-3xl font-effraHeavy">
      Play Arena
    </p>
  </div>
</app-station>

<app-station
  class="station"
  id="station-1"
  (stationClick)="station2ClickHandler($event)"
  title="Social Battleground"
  imgSrc="assets/images/station-2.png"
  [locked]="station2Locked"
  [score]="isAuthenticated ? userEntryData?.score || 0 : 0"
  points="1500"
>
  <div class="text-right leading-5">
    <h4 class="text-md font-poppins text-borderGray text-right">Station 2</h4>
    <p class="whitespace-nowrap uppercase antialiased text-3xl font-effraHeavy">
      Social
    </p>
    <p class="whitespace-nowrap uppercase text-violet text-2xl font-effra">
      Battleground
    </p>
  </div>
</app-station>

<app-station
  class="station"
  id="station-2"
  (stationClick)="station3ClickHandler($event)"
  title="Become a Grand Master"
  imgSrc="assets/images/station-3.png"
  [locked]="station3Locked"
  [leaderboardId]="leaderboardId"
  [score]="isAuthenticated ? userEntryData?.score || 0 : 0"
  points="2500"
>
  <div class="text-right leading-5">
    <h4 class="text-md font-poppins text-borderGray text-right">Station 3</h4>
    <p class="whitespace-nowrap uppercase text-violet text-2xl font-effra">
      Become a
    </p>
    <p class="whitespace-nowrap uppercase antialiased text-3xl font-effraHeavy">
      Grand<br />Master
    </p>
  </div>
</app-station>
