// src/app/state/reducers/social.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as SocialActions from '../actions/social.actions';
import { SocialState } from '../models/social.model';

export const initialSocialState: SocialState = {
  twitterCodeVerifier: '',
  twitterState: '',
};

export const socialReducer = createReducer(
  initialSocialState,
  on(
    SocialActions.setTwitterAuthData,
    (state, { url, twitterCodeVerifier, twitterState }) => ({
      ...state,
      twitterCodeVerifier: twitterCodeVerifier,
      twitterState: twitterState,
    })
  )
);
