import * as i0 from '@angular/core';
import { Injectable, NgModule, Optional, SkipSelf } from '@angular/core';
import * as i2 from '@angular/common/http';
import { HttpParams, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';

/* tslint:disable */
/* eslint-disable */
/**
 * Global configuration
 */
class ReadyGGApiConfiguration {
  rootUrl = 'https://us-central1-readysandbox.cloudfunctions.net';
  static ɵfac = function ReadyGGApiConfiguration_Factory(t) {
    return new (t || ReadyGGApiConfiguration)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ReadyGGApiConfiguration,
    factory: ReadyGGApiConfiguration.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReadyGGApiConfiguration, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/* tslint:disable */
/* eslint-disable */
/**
 * Base class for services
 */
class BaseService {
  config;
  http;
  constructor(config, http) {
    this.config = config;
    this.http = http;
  }
  _rootUrl;
  /**
   * Returns the root url for all operations in this service. If not set directly in this
   * service, will fallback to `ReadyGGApiConfiguration.rootUrl`.
   */
  get rootUrl() {
    return this._rootUrl || this.config.rootUrl;
  }
  /**
   * Sets the root URL for API operations in this service.
   */
  set rootUrl(rootUrl) {
    this._rootUrl = rootUrl;
  }
  static ɵfac = function BaseService_Factory(t) {
    return new (t || BaseService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BaseService,
    factory: BaseService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseService, [{
    type: Injectable
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec {
  encodeKey(key) {
    return encodeURIComponent(key);
  }
  encodeValue(value) {
    return encodeURIComponent(value);
  }
  decodeKey(key) {
    return decodeURIComponent(key);
  }
  decodeValue(value) {
    return decodeURIComponent(value);
  }
}
const ParameterCodecInstance = new ParameterCodec();
/**
 * Base class for a parameter
 */
class Parameter {
  name;
  value;
  options;
  constructor(name, value, options, defaultStyle, defaultExplode) {
    this.name = name;
    this.value = value;
    this.options = options;
    this.options = options || {};
    if (this.options.style === null || this.options.style === undefined) {
      this.options.style = defaultStyle;
    }
    if (this.options.explode === null || this.options.explode === undefined) {
      this.options.explode = defaultExplode;
    }
  }
  serializeValue(value, separator = ',') {
    if (value === null || value === undefined) {
      return '';
    } else if (value instanceof Array) {
      return value.map(v => this.serializeValue(v).split(separator).join(encodeURIComponent(separator))).join(separator);
    } else if (typeof value === 'object') {
      const array = [];
      for (const key of Object.keys(value)) {
        let propVal = value[key];
        if (propVal !== null && propVal !== undefined) {
          propVal = this.serializeValue(propVal).split(separator).join(encodeURIComponent(separator));
          if (this.options.explode) {
            array.push(`${key}=${propVal}`);
          } else {
            array.push(key);
            array.push(propVal);
          }
        }
      }
      return array.join(separator);
    } else {
      return String(value);
    }
  }
}
/**
 * A parameter in the operation path
 */
class PathParameter extends Parameter {
  constructor(name, value, options) {
    super(name, value, options, 'simple', false);
  }
  append(path) {
    let value = this.value;
    if (value === null || value === undefined) {
      value = '';
    }
    let prefix = this.options.style === 'label' ? '.' : '';
    let separator = this.options.explode ? prefix === '' ? ',' : prefix : ',';
    let alreadySerialized = false;
    if (this.options.style === 'matrix') {
      // The parameter name is just used as prefix, except in some cases...
      prefix = `;${this.name}=`;
      if (this.options.explode && typeof value === 'object') {
        prefix = ';';
        if (value instanceof Array) {
          // For arrays we have to repeat the name for each element
          value = value.map(v => `${this.name}=${this.serializeValue(v, ';')}`);
          value = value.join(';');
          alreadySerialized = true;
        } else {
          // For objects we have to put each the key / value pairs
          value = this.serializeValue(value, ';');
          alreadySerialized = true;
        }
      }
    }
    value = prefix + (alreadySerialized ? value : this.serializeValue(value, separator));
    // Replace both the plain variable and the corresponding variant taking in the prefix and explode into account
    path = path.replace(`{${this.name}}`, value);
    path = path.replace(`{${prefix}${this.name}${this.options.explode ? '*' : ''}}`, value);
    return path;
  }
  // @ts-ignore
  serializeValue(value, separator = ',') {
    var result = typeof value === 'string' ? encodeURIComponent(value) : super.serializeValue(value, separator);
    result = result.replace(/%3D/g, '=');
    result = result.replace(/%3B/g, ';');
    result = result.replace(/%2C/g, ',');
    return result;
  }
}
/**
 * A parameter in the query
 */
class QueryParameter extends Parameter {
  constructor(name, value, options) {
    super(name, value, options, 'form', true);
  }
  append(params) {
    if (this.value instanceof Array) {
      // Array serialization
      if (this.options.explode) {
        for (const v of this.value) {
          params = params.append(this.name, this.serializeValue(v));
        }
      } else {
        const separator = this.options.style === 'spaceDelimited' ? ' ' : this.options.style === 'pipeDelimited' ? '|' : ',';
        return params.append(this.name, this.serializeValue(this.value, separator));
      }
    } else if (this.value !== null && typeof this.value === 'object') {
      // Object serialization
      if (this.options.style === 'deepObject') {
        // Append a parameter for each key, in the form `name[key]`
        for (const key of Object.keys(this.value)) {
          const propVal = this.value[key];
          if (propVal !== null && propVal !== undefined) {
            params = params.append(`${this.name}[${key}]`, this.serializeValue(propVal));
          }
        }
      } else if (this.options.explode) {
        // Append a parameter for each key without using the parameter name
        for (const key of Object.keys(this.value)) {
          const propVal = this.value[key];
          if (propVal !== null && propVal !== undefined) {
            params = params.append(key, this.serializeValue(propVal));
          }
        }
      } else {
        // Append a single parameter whose values are a comma-separated list of key,value,key,value...
        const array = [];
        for (const key of Object.keys(this.value)) {
          const propVal = this.value[key];
          if (propVal !== null && propVal !== undefined) {
            array.push(key);
            array.push(propVal);
          }
        }
        params = params.append(this.name, this.serializeValue(array));
      }
    } else if (this.value !== null && this.value !== undefined) {
      // Plain value
      params = params.append(this.name, this.serializeValue(this.value));
    }
    return params;
  }
}
/**
 * A parameter in the HTTP request header
 */
class HeaderParameter extends Parameter {
  constructor(name, value, options) {
    super(name, value, options, 'simple', false);
  }
  append(headers) {
    if (this.value !== null && this.value !== undefined) {
      if (this.value instanceof Array) {
        for (const v of this.value) {
          headers = headers.append(this.name, this.serializeValue(v));
        }
      } else {
        headers = headers.append(this.name, this.serializeValue(this.value));
      }
    }
    return headers;
  }
}
/**
 * Helper to build http requests from parameters
 */
class RequestBuilder {
  rootUrl;
  operationPath;
  method;
  _path = new Map();
  _query = new Map();
  _header = new Map();
  _bodyContent;
  _bodyContentType;
  constructor(rootUrl, operationPath, method) {
    this.rootUrl = rootUrl;
    this.operationPath = operationPath;
    this.method = method;
  }
  /**
   * Sets a path parameter
   */
  path(name, value, options) {
    this._path.set(name, new PathParameter(name, value, options || {}));
  }
  /**
   * Sets a query parameter
   */
  query(name, value, options) {
    this._query.set(name, new QueryParameter(name, value, options || {}));
  }
  /**
   * Sets a header parameter
   */
  header(name, value, options) {
    this._header.set(name, new HeaderParameter(name, value, options || {}));
  }
  /**
   * Sets the body content, along with the content type
   */
  body(value, contentType = 'application/json') {
    if (value instanceof Blob) {
      this._bodyContentType = value.type;
    } else {
      this._bodyContentType = contentType;
    }
    if (this._bodyContentType === 'application/x-www-form-urlencoded' && value !== null && typeof value === 'object') {
      // Handle URL-encoded data
      const pairs = [];
      for (const key of Object.keys(value)) {
        let val = value[key];
        if (!(val instanceof Array)) {
          val = [val];
        }
        for (const v of val) {
          const formValue = this.formDataValue(v);
          if (formValue !== null) {
            pairs.push([key, formValue]);
          }
        }
      }
      this._bodyContent = pairs.map(p => `${encodeURIComponent(p[0])}=${encodeURIComponent(p[1])}`).join('&');
    } else if (this._bodyContentType === 'multipart/form-data') {
      // Handle multipart form data
      const formData = new FormData();
      if (value !== null && value !== undefined) {
        for (const key of Object.keys(value)) {
          const val = value[key];
          if (val instanceof Array) {
            for (const v of val) {
              const toAppend = this.formDataValue(v);
              if (toAppend !== null) {
                formData.append(key, toAppend);
              }
            }
          } else {
            const toAppend = this.formDataValue(val);
            if (toAppend !== null) {
              formData.set(key, toAppend);
            }
          }
        }
      }
      this._bodyContent = formData;
    } else {
      // The body is the plain content
      this._bodyContent = value;
    }
  }
  formDataValue(value) {
    if (value === null || value === undefined) {
      return null;
    }
    if (value instanceof Blob) {
      return value;
    }
    if (typeof value === 'object') {
      return new Blob([JSON.stringify(value)], {
        type: 'application/json'
      });
    }
    return String(value);
  }
  /**
   * Builds the request with the current set parameters
   */
  build(options) {
    options = options || {};
    // Path parameters
    let path = this.operationPath;
    for (const pathParam of this._path.values()) {
      path = pathParam.append(path);
    }
    const url = this.rootUrl + path;
    // Query parameters
    let httpParams = new HttpParams({
      encoder: ParameterCodecInstance
    });
    for (const queryParam of this._query.values()) {
      httpParams = queryParam.append(httpParams);
    }
    // Header parameters
    let httpHeaders = new HttpHeaders();
    if (options.accept) {
      httpHeaders = httpHeaders.append('Accept', options.accept);
    }
    for (const headerParam of this._header.values()) {
      httpHeaders = headerParam.append(httpHeaders);
    }
    // Request content headers
    if (this._bodyContentType && !(this._bodyContent instanceof FormData)) {
      httpHeaders = httpHeaders.set('Content-Type', this._bodyContentType);
    }
    // Perform the request
    return new HttpRequest(this.method.toUpperCase(), url, this._bodyContent, {
      params: httpParams,
      headers: httpHeaders,
      responseType: options.responseType,
      reportProgress: options.reportProgress,
      context: options.context
    });
  }
}

/* tslint:disable */
/* eslint-disable */
function userCreateCustomToken(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userCreateCustomToken.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userCreateCustomToken.PATH = '/user-createCustomToken';

/* tslint:disable */
/* eslint-disable */
function userRefreshTokens(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userRefreshTokens.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userRefreshTokens.PATH = '/user-refreshTokens';

/* tslint:disable */
/* eslint-disable */
function userResetAccountPassword(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userResetAccountPassword.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
userResetAccountPassword.PATH = '/user-resetAccountPassword';

/* tslint:disable */
/* eslint-disable */
function userSendVerificationEmail(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userSendVerificationEmail.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
userSendVerificationEmail.PATH = '/user-sendVerificationEmail';

/* tslint:disable */
/* eslint-disable */
function userSignInWithEmailPassword(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userSignInWithEmailPassword.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userSignInWithEmailPassword.PATH = '/user-signInWithEmailPassword';

/* tslint:disable */
/* eslint-disable */
function userSignUpAnonymously(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userSignUpAnonymously.PATH, 'post');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userSignUpAnonymously.PATH = '/user-signUpAnonymously';

/* tslint:disable */
/* eslint-disable */
function userSignUpWithEmailPassword(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userSignUpWithEmailPassword.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userSignUpWithEmailPassword.PATH = '/user-signUpWithEmailPassword';

/**
 * User authentication endpoints. Working with user accounts, sign in, sign up, tokens, etc.
 */
class UserAuthService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `userSignInWithEmailPassword()` */
  static UserSignInWithEmailPasswordPath = '/user-signInWithEmailPassword';
  /**
   * Sign in a user with email and password.
   *
   * Allows users to sign in using their email and password. Please use our web login form to register and login users.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSignInWithEmailPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSignInWithEmailPassword$Response(params, context) {
    return userSignInWithEmailPassword(this.http, this.rootUrl, params, context);
  }
  /**
   * Sign in a user with email and password.
   *
   * Allows users to sign in using their email and password. Please use our web login form to register and login users.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSignInWithEmailPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSignInWithEmailPassword(params, context) {
    return this.userSignInWithEmailPassword$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userSignUpWithEmailPassword()` */
  static UserSignUpWithEmailPasswordPath = '/user-signUpWithEmailPassword';
  /**
   * Sign up with Email and Password.
   *
   * Allows users to sign up using their email, password, and display name.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSignUpWithEmailPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSignUpWithEmailPassword$Response(params, context) {
    return userSignUpWithEmailPassword(this.http, this.rootUrl, params, context);
  }
  /**
   * Sign up with Email and Password.
   *
   * Allows users to sign up using their email, password, and display name.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSignUpWithEmailPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSignUpWithEmailPassword(params, context) {
    return this.userSignUpWithEmailPassword$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userSignUpAnonymously()` */
  static UserSignUpAnonymouslyPath = '/user-signUpAnonymously';
  /**
   * Sign Up Anonymously.
   *
   * Allows users to sign up anonymously.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSignUpAnonymously()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSignUpAnonymously$Response(params, context) {
    return userSignUpAnonymously(this.http, this.rootUrl, params, context);
  }
  /**
   * Sign Up Anonymously.
   *
   * Allows users to sign up anonymously.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSignUpAnonymously$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSignUpAnonymously(params, context) {
    return this.userSignUpAnonymously$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userRefreshTokens()` */
  static UserRefreshTokensPath = '/user-refreshTokens';
  /**
   * Refresh Tokens.
   *
   * Refreshes the user's tokens.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userRefreshTokens()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userRefreshTokens$Response(params, context) {
    return userRefreshTokens(this.http, this.rootUrl, params, context);
  }
  /**
   * Refresh Tokens.
   *
   * Refreshes the user's tokens.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userRefreshTokens$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userRefreshTokens(params, context) {
    return this.userRefreshTokens$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userResetAccountPassword()` */
  static UserResetAccountPasswordPath = '/user-resetAccountPassword';
  /**
   * Reset Account Password.
   *
   * Allows users to reset their account password.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userResetAccountPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userResetAccountPassword$Response(params, context) {
    return userResetAccountPassword(this.http, this.rootUrl, params, context);
  }
  /**
   * Reset Account Password.
   *
   * Allows users to reset their account password.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userResetAccountPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userResetAccountPassword(params, context) {
    return this.userResetAccountPassword$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userSendVerificationEmail()` */
  static UserSendVerificationEmailPath = '/user-sendVerificationEmail';
  /**
   * Send Verification Email.
   *
   * Sends a verification email to the user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSendVerificationEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSendVerificationEmail$Response(params, context) {
    return userSendVerificationEmail(this.http, this.rootUrl, params, context);
  }
  /**
   * Send Verification Email.
   *
   * Sends a verification email to the user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSendVerificationEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSendVerificationEmail(params, context) {
    return this.userSendVerificationEmail$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userCreateCustomToken()` */
  static UserCreateCustomTokenPath = '/user-createCustomToken';
  /**
   * Create a custom token for user authentication.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCreateCustomToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreateCustomToken$Response(params, context) {
    return userCreateCustomToken(this.http, this.rootUrl, params, context);
  }
  /**
   * Create a custom token for user authentication.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCreateCustomToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreateCustomToken(params, context) {
    return this.userCreateCustomToken$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function UserAuthService_Factory(t) {
    return new (t || UserAuthService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UserAuthService,
    factory: UserAuthService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function userGetFullProfile(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userGetFullProfile.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userGetFullProfile.PATH = '/user-getFullProfile';

/* tslint:disable */
/* eslint-disable */
function userGetProfile(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userGetProfile.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userGetProfile.PATH = '/user-getProfile';

/* tslint:disable */
/* eslint-disable */
function userUpdateBio(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userUpdateBio.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userUpdateBio.PATH = '/user-updateBio';

/* tslint:disable */
/* eslint-disable */
function userUpdateDisplayName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userUpdateDisplayName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userUpdateDisplayName.PATH = '/user-updateDisplayName';

/* tslint:disable */
/* eslint-disable */
function userUploadProfilePicture(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, userUploadProfilePicture.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
userUploadProfilePicture.PATH = '/user-uploadProfilePicture';

/**
 * User data endpoints. Working with user profiles, profile pictures, etc.
 */
class UserDataService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `userGetProfile()` */
  static UserGetProfilePath = '/user-getProfile';
  /**
   * Get user profile.
   *
   * Retrieve user profile information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGetProfile$Response(params, context) {
    return userGetProfile(this.http, this.rootUrl, params, context);
  }
  /**
   * Get user profile.
   *
   * Retrieve user profile information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGetProfile(params, context) {
    return this.userGetProfile$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userGetFullProfile()` */
  static UserGetFullProfilePath = '/user-getFullProfile';
  /**
   * Get full user profile.
   *
   * Retrieve full user profile information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetFullProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGetFullProfile$Response(params, context) {
    return userGetFullProfile(this.http, this.rootUrl, params, context);
  }
  /**
   * Get full user profile.
   *
   * Retrieve full user profile information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetFullProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userGetFullProfile(params, context) {
    return this.userGetFullProfile$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userUpdateDisplayName()` */
  static UserUpdateDisplayNamePath = '/user-updateDisplayName';
  /**
   * Update the display name of a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUpdateDisplayName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdateDisplayName$Response(params, context) {
    return userUpdateDisplayName(this.http, this.rootUrl, params, context);
  }
  /**
   * Update the display name of a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUpdateDisplayName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdateDisplayName(params, context) {
    return this.userUpdateDisplayName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userUpdateBio()` */
  static UserUpdateBioPath = '/user-updateBio';
  /**
   * Update the bio.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUpdateBio()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdateBio$Response(params, context) {
    return userUpdateBio(this.http, this.rootUrl, params, context);
  }
  /**
   * Update the bio.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUpdateBio$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdateBio(params, context) {
    return this.userUpdateBio$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userUploadProfilePicture()` */
  static UserUploadProfilePicturePath = '/user-uploadProfilePicture';
  /**
   * Upload a profile picture for the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUploadProfilePicture()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUploadProfilePicture$Response(params, context) {
    return userUploadProfilePicture(this.http, this.rootUrl, params, context);
  }
  /**
   * Upload a profile picture for the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUploadProfilePicture$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUploadProfilePicture(params, context) {
    return this.userUploadProfilePicture$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function UserDataService_Factory(t) {
    return new (t || UserDataService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UserDataService,
    factory: UserDataService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserDataService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function walletsCreateWallet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, walletsCreateWallet.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
walletsCreateWallet.PATH = '/wallets-createWallet';

/* tslint:disable */
/* eslint-disable */
function walletsGetUserWallets(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, walletsGetUserWallets.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
walletsGetUserWallets.PATH = '/wallets-getUserWallets';

/**
 * Wallets endpoints. Creating a crypto wallet, getting user wallets, etc.
 */
class WalletsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `walletsCreateWallet()` */
  static WalletsCreateWalletPath = '/wallets-createWallet';
  /**
   * Create a new wallet.
   *
   * Creates a new crypto wallet for the user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `walletsCreateWallet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletsCreateWallet$Response(params, context) {
    return walletsCreateWallet(this.http, this.rootUrl, params, context);
  }
  /**
   * Create a new wallet.
   *
   * Creates a new crypto wallet for the user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `walletsCreateWallet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletsCreateWallet(params, context) {
    return this.walletsCreateWallet$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `walletsGetUserWallets()` */
  static WalletsGetUserWalletsPath = '/wallets-getUserWallets';
  /**
   * Get user wallets.
   *
   * Retrieves the crypto wallets of the user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `walletsGetUserWallets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletsGetUserWallets$Response(params, context) {
    return walletsGetUserWallets(this.http, this.rootUrl, params, context);
  }
  /**
   * Get user wallets.
   *
   * Retrieves the crypto wallets of the user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `walletsGetUserWallets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletsGetUserWallets(params, context) {
    return this.walletsGetUserWallets$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function WalletsService_Factory(t) {
    return new (t || WalletsService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WalletsService,
    factory: WalletsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WalletsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2Add(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2Add.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2Add.PATH = '/virtualItemsV2-add';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2AddFromCsv(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2AddFromCsv.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2AddFromCsv.PATH = '/virtualItemsV2-addFromCSV';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2DeleteVirtualItem(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2DeleteVirtualItem.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2DeleteVirtualItem.PATH = '/virtualItemsV2-deleteVirtualItem';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2DeleteVirtualItemByAppId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2DeleteVirtualItemByAppId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2DeleteVirtualItemByAppId.PATH = '/virtualItemsV2-deleteVirtualItemByAppId';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2DeleteVirtualItemsByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2DeleteVirtualItemsByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2DeleteVirtualItemsByIds.PATH = '/virtualItemsV2-deleteVirtualItemsByIds';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2DeleteVirtualItemsByName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2DeleteVirtualItemsByName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2DeleteVirtualItemsByName.PATH = '/virtualItemsV2-deleteVirtualItemsByName';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2DownloadThumbnail(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2DownloadThumbnail.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2DownloadThumbnail.PATH = '/virtualItemsV2-downloadThumbnail';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2GetByAppId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2GetByAppId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2GetByAppId.PATH = '/virtualItemsV2-getByAppId';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2GetByAppIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2GetByAppIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2GetByAppIds.PATH = '/virtualItemsV2-getByAppIds';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2GetByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2GetByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2GetByIds.PATH = '/virtualItemsV2-getByIds';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2GetByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2GetByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2GetByTags.PATH = '/virtualItemsV2-getByTags';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2GetProperties(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2GetProperties.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2GetProperties.PATH = '/virtualItemsV2-getProperties';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2GetTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2GetTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2GetTags.PATH = '/virtualItemsV2-getTags';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2SetDescription(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2SetDescription.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2SetDescription.PATH = '/virtualItemsV2-setDescription';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2SetName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2SetName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2SetName.PATH = '/virtualItemsV2-setName';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2SetProperties(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2SetProperties.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2SetProperties.PATH = '/virtualItemsV2-setProperties';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2SetTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2SetTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2SetTags.PATH = '/virtualItemsV2-setTags';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2SetTotalQuantity(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2SetTotalQuantity.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2SetTotalQuantity.PATH = '/virtualItemsV2-setTotalQuantity';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2Update(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2Update.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
virtualItemsV2Update.PATH = '/virtualItemsV2-update';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2UploadTextures(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2UploadTextures.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
virtualItemsV2UploadTextures.PATH = '/virtualItemsV2-uploadTextures';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2UploadThumbnail(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2UploadThumbnail.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
virtualItemsV2UploadThumbnail.PATH = '/virtualItemsV2-uploadThumbnail';

/* tslint:disable */
/* eslint-disable */
function virtualItemsV2UploadThumbnailToMultipleItemsByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, virtualItemsV2UploadThumbnailToMultipleItemsByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
virtualItemsV2UploadThumbnailToMultipleItemsByTags.PATH = '/virtualItemsV2-uploadThumbnailToMultipleItemsByTags';

/**
 * Virtual items are game items, that can be purchased using game currency or can be rewarded as completing a certain threshold using Achievements.
 */
class VirtualItemsV2Service extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `virtualItemsV2Add()` */
  static VirtualItemsV2AddPath = '/virtualItemsV2-add';
  /**
   * Add.
   *
   * Adds a new virtual item to the database attached to the specified application ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2Add()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2Add$Response(params, context) {
    return virtualItemsV2Add(this.http, this.rootUrl, params, context);
  }
  /**
   * Add.
   *
   * Adds a new virtual item to the database attached to the specified application ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2Add$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2Add(params, context) {
    return this.virtualItemsV2Add$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2AddFromCsv()` */
  static VirtualItemsV2AddFromCsvPath = '/virtualItemsV2-addFromCSV';
  /**
   * Add virtual items from CSV.
   *
   * Adds virtual items from CSV file to the database attached to the specified application ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2AddFromCsv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2AddFromCsv$Response(params, context) {
    return virtualItemsV2AddFromCsv(this.http, this.rootUrl, params, context);
  }
  /**
   * Add virtual items from CSV.
   *
   * Adds virtual items from CSV file to the database attached to the specified application ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2AddFromCsv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2AddFromCsv(params, context) {
    return this.virtualItemsV2AddFromCsv$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2Update()` */
  static VirtualItemsV2UpdatePath = '/virtualItemsV2-update';
  /**
   * Update.
   *
   * Updates an existing virtual item in the database.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2Update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2Update$Response(params, context) {
    return virtualItemsV2Update(this.http, this.rootUrl, params, context);
  }
  /**
   * Update.
   *
   * Updates an existing virtual item in the database.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2Update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2Update(params, context) {
    return this.virtualItemsV2Update$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2DeleteVirtualItem()` */
  static VirtualItemsV2DeleteVirtualItemPath = '/virtualItemsV2-deleteVirtualItem';
  /**
   * Deletes virtual item.
   *
   * Deletes virtual item by provided appId and itemId.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2DeleteVirtualItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItem$Response(params, context) {
    return virtualItemsV2DeleteVirtualItem(this.http, this.rootUrl, params, context);
  }
  /**
   * Deletes virtual item.
   *
   * Deletes virtual item by provided appId and itemId.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2DeleteVirtualItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItem(params, context) {
    return this.virtualItemsV2DeleteVirtualItem$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2DeleteVirtualItemsByIds()` */
  static VirtualItemsV2DeleteVirtualItemsByIdsPath = '/virtualItemsV2-deleteVirtualItemsByIds';
  /**
   * Delete virtual items by ids.
   *
   * Deletes existing virtual items by provided virtual item ids.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2DeleteVirtualItemsByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItemsByIds$Response(params, context) {
    return virtualItemsV2DeleteVirtualItemsByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete virtual items by ids.
   *
   * Deletes existing virtual items by provided virtual item ids.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2DeleteVirtualItemsByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItemsByIds(params, context) {
    return this.virtualItemsV2DeleteVirtualItemsByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2DeleteVirtualItemsByName()` */
  static VirtualItemsV2DeleteVirtualItemsByNamePath = '/virtualItemsV2-deleteVirtualItemsByName';
  /**
   * Delete virtual items by name.
   *
   * Deletes existing virtual item by provided virtual item name.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2DeleteVirtualItemsByName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItemsByName$Response(params, context) {
    return virtualItemsV2DeleteVirtualItemsByName(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete virtual items by name.
   *
   * Deletes existing virtual item by provided virtual item name.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2DeleteVirtualItemsByName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItemsByName(params, context) {
    return this.virtualItemsV2DeleteVirtualItemsByName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2DeleteVirtualItemByAppId()` */
  static VirtualItemsV2DeleteVirtualItemByAppIdPath = '/virtualItemsV2-deleteVirtualItemByAppId';
  /**
   * Delete virtual items by appId.
   *
   * Deletes existing virtual items by provided app id. This endpoint is used to delete all virtual items for a specific app.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2DeleteVirtualItemByAppId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItemByAppId$Response(params, context) {
    return virtualItemsV2DeleteVirtualItemByAppId(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete virtual items by appId.
   *
   * Deletes existing virtual items by provided app id. This endpoint is used to delete all virtual items for a specific app.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2DeleteVirtualItemByAppId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DeleteVirtualItemByAppId(params, context) {
    return this.virtualItemsV2DeleteVirtualItemByAppId$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2GetByAppIds()` */
  static VirtualItemsV2GetByAppIdsPath = '/virtualItemsV2-getByAppIds';
  /**
   * Get By App Ids.
   *
   * Retrieve an array of virtual items by multiple app IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2GetByAppIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByAppIds$Response(params, context) {
    return virtualItemsV2GetByAppIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get By App Ids.
   *
   * Retrieve an array of virtual items by multiple app IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2GetByAppIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByAppIds(params, context) {
    return this.virtualItemsV2GetByAppIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2GetByAppId()` */
  static VirtualItemsV2GetByAppIdPath = '/virtualItemsV2-getByAppId';
  /**
   * Get By App Id.
   *
   * Retrieve an array of virtual items by a single app ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2GetByAppId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByAppId$Response(params, context) {
    return virtualItemsV2GetByAppId(this.http, this.rootUrl, params, context);
  }
  /**
   * Get By App Id.
   *
   * Retrieve an array of virtual items by a single app ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2GetByAppId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByAppId(params, context) {
    return this.virtualItemsV2GetByAppId$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2GetByTags()` */
  static VirtualItemsV2GetByTagsPath = '/virtualItemsV2-getByTags';
  /**
   * Get By Tags.
   *
   * Returns virtual items data based on specified tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2GetByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByTags$Response(params, context) {
    return virtualItemsV2GetByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Get By Tags.
   *
   * Returns virtual items data based on specified tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2GetByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByTags(params, context) {
    return this.virtualItemsV2GetByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2GetByIds()` */
  static VirtualItemsV2GetByIdsPath = '/virtualItemsV2-getByIds';
  /**
   * Get By Ids.
   *
   * Return virtual items array based on IDs list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2GetByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByIds$Response(params, context) {
    return virtualItemsV2GetByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get By Ids.
   *
   * Return virtual items array based on IDs list
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2GetByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetByIds(params, context) {
    return this.virtualItemsV2GetByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2GetTags()` */
  static VirtualItemsV2GetTagsPath = '/virtualItemsV2-getTags';
  /**
   * Get Tags.
   *
   * Returns all tags for a specific virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2GetTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetTags$Response(params, context) {
    return virtualItemsV2GetTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Tags.
   *
   * Returns all tags for a specific virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2GetTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetTags(params, context) {
    return this.virtualItemsV2GetTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2SetTags()` */
  static VirtualItemsV2SetTagsPath = '/virtualItemsV2-setTags';
  /**
   * Set Tags.
   *
   * Sets the tags array for a specific virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2SetTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetTags$Response(params, context) {
    return virtualItemsV2SetTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Tags.
   *
   * Sets the tags array for a specific virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2SetTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetTags(params, context) {
    return this.virtualItemsV2SetTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2SetName()` */
  static VirtualItemsV2SetNamePath = '/virtualItemsV2-setName';
  /**
   * Set Name.
   *
   * Sets the name for a specific virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2SetName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetName$Response(params, context) {
    return virtualItemsV2SetName(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Name.
   *
   * Sets the name for a specific virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2SetName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetName(params, context) {
    return this.virtualItemsV2SetName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2SetDescription()` */
  static VirtualItemsV2SetDescriptionPath = '/virtualItemsV2-setDescription';
  /**
   * Set Description.
   *
   * Sets the description for a specific virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2SetDescription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetDescription$Response(params, context) {
    return virtualItemsV2SetDescription(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Description.
   *
   * Sets the description for a specific virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2SetDescription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetDescription(params, context) {
    return this.virtualItemsV2SetDescription$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2SetTotalQuantity()` */
  static VirtualItemsV2SetTotalQuantityPath = '/virtualItemsV2-setTotalQuantity';
  /**
   * Set Total Quantity.
   *
   * Sets the total quantity for a specific virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2SetTotalQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetTotalQuantity$Response(params, context) {
    return virtualItemsV2SetTotalQuantity(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Total Quantity.
   *
   * Sets the total quantity for a specific virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2SetTotalQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetTotalQuantity(params, context) {
    return this.virtualItemsV2SetTotalQuantity$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2GetProperties()` */
  static VirtualItemsV2GetPropertiesPath = '/virtualItemsV2-getProperties';
  /**
   * Get Properties.
   *
   * Get properties for virtual item. It is possible to attach application specific properties to a virtual item. This endpoint returns the properties for a specific virtual item and app id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2GetProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetProperties$Response(params, context) {
    return virtualItemsV2GetProperties(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Properties.
   *
   * Get properties for virtual item. It is possible to attach application specific properties to a virtual item. This endpoint returns the properties for a specific virtual item and app id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2GetProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2GetProperties(params, context) {
    return this.virtualItemsV2GetProperties$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2SetProperties()` */
  static VirtualItemsV2SetPropertiesPath = '/virtualItemsV2-setProperties';
  /**
   * Set Properties.
   *
   * Set properties for virtual item. It is possible to attach application specific properties to a virtual item. This endpoint sets the properties for a specific virtual item and app id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2SetProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetProperties$Response(params, context) {
    return virtualItemsV2SetProperties(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Properties.
   *
   * Set properties for virtual item. It is possible to attach application specific properties to a virtual item. This endpoint sets the properties for a specific virtual item and app id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2SetProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2SetProperties(params, context) {
    return this.virtualItemsV2SetProperties$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2UploadThumbnail()` */
  static VirtualItemsV2UploadThumbnailPath = '/virtualItemsV2-uploadThumbnail';
  /**
   * Upload Thumbnail.
   *
   * Uploads a thumbnail for a specific virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2UploadThumbnail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2UploadThumbnail$Response(params, context) {
    return virtualItemsV2UploadThumbnail(this.http, this.rootUrl, params, context);
  }
  /**
   * Upload Thumbnail.
   *
   * Uploads a thumbnail for a specific virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2UploadThumbnail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2UploadThumbnail(params, context) {
    return this.virtualItemsV2UploadThumbnail$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2UploadThumbnailToMultipleItemsByTags()` */
  static VirtualItemsV2UploadThumbnailToMultipleItemsByTagsPath = '/virtualItemsV2-uploadThumbnailToMultipleItemsByTags';
  /**
   * Upload Thumbnail To Multiple Items By Tags.
   *
   * Uploads a thumbnail to multiple virtual items by tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2UploadThumbnailToMultipleItemsByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2UploadThumbnailToMultipleItemsByTags$Response(params, context) {
    return virtualItemsV2UploadThumbnailToMultipleItemsByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Upload Thumbnail To Multiple Items By Tags.
   *
   * Uploads a thumbnail to multiple virtual items by tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2UploadThumbnailToMultipleItemsByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2UploadThumbnailToMultipleItemsByTags(params, context) {
    return this.virtualItemsV2UploadThumbnailToMultipleItemsByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2UploadTextures()` */
  static VirtualItemsV2UploadTexturesPath = '/virtualItemsV2-uploadTextures';
  /**
   * Upload Textures.
   *
   * Upload multiple textures for a virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2UploadTextures()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2UploadTextures$Response(params, context) {
    return virtualItemsV2UploadTextures(this.http, this.rootUrl, params, context);
  }
  /**
   * Upload Textures.
   *
   * Upload multiple textures for a virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2UploadTextures$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2UploadTextures(params, context) {
    return this.virtualItemsV2UploadTextures$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `virtualItemsV2DownloadThumbnail()` */
  static VirtualItemsV2DownloadThumbnailPath = '/virtualItemsV2-downloadThumbnail';
  /**
   * Download Thumbnail.
   *
   * Download thumbnail for a virtual item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `virtualItemsV2DownloadThumbnail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DownloadThumbnail$Response(params, context) {
    return virtualItemsV2DownloadThumbnail(this.http, this.rootUrl, params, context);
  }
  /**
   * Download Thumbnail.
   *
   * Download thumbnail for a virtual item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `virtualItemsV2DownloadThumbnail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  virtualItemsV2DownloadThumbnail(params, context) {
    return this.virtualItemsV2DownloadThumbnail$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function VirtualItemsV2Service_Factory(t) {
    return new (t || VirtualItemsV2Service)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: VirtualItemsV2Service,
    factory: VirtualItemsV2Service.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VirtualItemsV2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function inventoryV2AddToInventory(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2AddToInventory.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2AddToInventory.PATH = '/inventoryV2-addToInventory';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetAll(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetAll.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetAll.PATH = '/inventoryV2-getAll';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetByAppId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetByAppId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetByAppId.PATH = '/inventoryV2-getByAppId';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetByAppIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetByAppIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetByAppIds.PATH = '/inventoryV2-getByAppIds';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetById.PATH = '/inventoryV2-getById';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetByIds.PATH = '/inventoryV2-getByIds';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetByTags.PATH = '/inventoryV2-getByTags';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetByVirtualItemIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetByVirtualItemIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetByVirtualItemIds.PATH = '/inventoryV2-getByVirtualItemIds';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetProperties(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetProperties.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetProperties.PATH = '/inventoryV2-getProperties';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetTags.PATH = '/inventoryV2-getTags';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetUpgrades(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetUpgrades.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetUpgrades.PATH = '/inventoryV2-getUpgrades';

/* tslint:disable */
/* eslint-disable */
function inventoryV2GetWithVirtualItemsDataByAppIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2GetWithVirtualItemsDataByAppIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2GetWithVirtualItemsDataByAppIds.PATH = '/inventoryV2-getWithVirtualItemsDataByAppIds';

/* tslint:disable */
/* eslint-disable */
function inventoryV2RemoveByInventoryItemId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2RemoveByInventoryItemId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2RemoveByInventoryItemId.PATH = '/inventoryV2-removeByInventoryItemId';

/* tslint:disable */
/* eslint-disable */
function inventoryV2RemoveByVirtualItemId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2RemoveByVirtualItemId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2RemoveByVirtualItemId.PATH = '/inventoryV2-removeByVirtualItemId';

/* tslint:disable */
/* eslint-disable */
function inventoryV2SetProperties(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2SetProperties.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2SetProperties.PATH = '/inventoryV2-setProperties';

/* tslint:disable */
/* eslint-disable */
function inventoryV2SetTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2SetTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2SetTags.PATH = '/inventoryV2-setTags';

/* tslint:disable */
/* eslint-disable */
function inventoryV2UpdateInventoryQuantity(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2UpdateInventoryQuantity.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2UpdateInventoryQuantity.PATH = '/inventoryV2-updateInventoryQuantity';

/* tslint:disable */
/* eslint-disable */
function inventoryV2Upgrade(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, inventoryV2Upgrade.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
inventoryV2Upgrade.PATH = '/inventoryV2-upgrade';

/**
 * Inventory is responsible with managing the items that a player has in the game. The endpoints provide various functionalities such as getting inventory data, adding items to the inventory, and removing items from the inventory.
 */
class InventoryV2Service extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `inventoryV2AddToInventory()` */
  static InventoryV2AddToInventoryPath = '/inventoryV2-addToInventory';
  /**
   * Add.
   *
   * Add a virtual item to the inventory
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2AddToInventory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2AddToInventory$Response(params, context) {
    return inventoryV2AddToInventory(this.http, this.rootUrl, params, context);
  }
  /**
   * Add.
   *
   * Add a virtual item to the inventory
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2AddToInventory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2AddToInventory(params, context) {
    return this.inventoryV2AddToInventory$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2UpdateInventoryQuantity()` */
  static InventoryV2UpdateInventoryQuantityPath = '/inventoryV2-updateInventoryQuantity';
  /**
   * Update Inventory Quantity.
   *
   * Update the quantity of a virtual item in the inventory
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2UpdateInventoryQuantity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2UpdateInventoryQuantity$Response(params, context) {
    return inventoryV2UpdateInventoryQuantity(this.http, this.rootUrl, params, context);
  }
  /**
   * Update Inventory Quantity.
   *
   * Update the quantity of a virtual item in the inventory
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2UpdateInventoryQuantity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2UpdateInventoryQuantity(params, context) {
    return this.inventoryV2UpdateInventoryQuantity$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2RemoveByVirtualItemId()` */
  static InventoryV2RemoveByVirtualItemIdPath = '/inventoryV2-removeByVirtualItemId';
  /**
   * Remove by Virtual Item ID.
   *
   * Remove a virtual item from the inventory by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2RemoveByVirtualItemId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2RemoveByVirtualItemId$Response(params, context) {
    return inventoryV2RemoveByVirtualItemId(this.http, this.rootUrl, params, context);
  }
  /**
   * Remove by Virtual Item ID.
   *
   * Remove a virtual item from the inventory by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2RemoveByVirtualItemId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2RemoveByVirtualItemId(params, context) {
    return this.inventoryV2RemoveByVirtualItemId$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2RemoveByInventoryItemId()` */
  static InventoryV2RemoveByInventoryItemIdPath = '/inventoryV2-removeByInventoryItemId';
  /**
   * Remove by Inventory Item ID.
   *
   * Remove a virtual item from the inventory by inventory item ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2RemoveByInventoryItemId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2RemoveByInventoryItemId$Response(params, context) {
    return inventoryV2RemoveByInventoryItemId(this.http, this.rootUrl, params, context);
  }
  /**
   * Remove by Inventory Item ID.
   *
   * Remove a virtual item from the inventory by inventory item ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2RemoveByInventoryItemId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2RemoveByInventoryItemId(params, context) {
    return this.inventoryV2RemoveByInventoryItemId$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetById()` */
  static InventoryV2GetByIdPath = '/inventoryV2-getById';
  /**
   * Get by Id.
   *
   * Retrieve virtual item inventory data by owned item ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetById$Response(params, context) {
    return inventoryV2GetById(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by Id.
   *
   * Retrieve virtual item inventory data by owned item ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetById(params, context) {
    return this.inventoryV2GetById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetByIds()` */
  static InventoryV2GetByIdsPath = '/inventoryV2-getByIds';
  /**
   * Get by Ids.
   *
   * Retrieve virtual items inventory data by multiple owned item IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByIds$Response(params, context) {
    return inventoryV2GetByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by Ids.
   *
   * Retrieve virtual items inventory data by multiple owned item IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByIds(params, context) {
    return this.inventoryV2GetByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetByVirtualItemIds()` */
  static InventoryV2GetByVirtualItemIdsPath = '/inventoryV2-getByVirtualItemIds';
  /**
   * Get by Virtual Item Ids.
   *
   * Retrieve virtual items inventory data by virtual item IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetByVirtualItemIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByVirtualItemIds$Response(params, context) {
    return inventoryV2GetByVirtualItemIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by Virtual Item Ids.
   *
   * Retrieve virtual items inventory data by virtual item IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetByVirtualItemIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByVirtualItemIds(params, context) {
    return this.inventoryV2GetByVirtualItemIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetAll()` */
  static InventoryV2GetAllPath = '/inventoryV2-getAll';
  /**
   * Get All.
   *
   * Retrieve all virtual items inventory data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetAll$Response(params, context) {
    return inventoryV2GetAll(this.http, this.rootUrl, params, context);
  }
  /**
   * Get All.
   *
   * Retrieve all virtual items inventory data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetAll(params, context) {
    return this.inventoryV2GetAll$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetByAppIds()` */
  static InventoryV2GetByAppIdsPath = '/inventoryV2-getByAppIds';
  /**
   * Get by App Ids.
   *
   * Retrieve virtual items inventory data based on specified app IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetByAppIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByAppIds$Response(params, context) {
    return inventoryV2GetByAppIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by App Ids.
   *
   * Retrieve virtual items inventory data based on specified app IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetByAppIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByAppIds(params, context) {
    return this.inventoryV2GetByAppIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetByAppId()` */
  static InventoryV2GetByAppIdPath = '/inventoryV2-getByAppId';
  /**
   * Get by App Id.
   *
   * Retrieve virtual items inventory data based on a specified app ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetByAppId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByAppId$Response(params, context) {
    return inventoryV2GetByAppId(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by App Id.
   *
   * Retrieve virtual items inventory data based on a specified app ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetByAppId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByAppId(params, context) {
    return this.inventoryV2GetByAppId$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetWithVirtualItemsDataByAppIds()` */
  static InventoryV2GetWithVirtualItemsDataByAppIdsPath = '/inventoryV2-getWithVirtualItemsDataByAppIds';
  /**
   * Get with Virtual Items Data by App Ids.
   *
   * Return virtual items inventory data based on specified appIds, with virtual items data included.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetWithVirtualItemsDataByAppIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetWithVirtualItemsDataByAppIds$Response(params, context) {
    return inventoryV2GetWithVirtualItemsDataByAppIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get with Virtual Items Data by App Ids.
   *
   * Return virtual items inventory data based on specified appIds, with virtual items data included.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetWithVirtualItemsDataByAppIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetWithVirtualItemsDataByAppIds(params, context) {
    return this.inventoryV2GetWithVirtualItemsDataByAppIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetProperties()` */
  static InventoryV2GetPropertiesPath = '/inventoryV2-getProperties';
  /**
   * Get Properties.
   *
   * Get properties for owned virtual item. It is possible to attach properties to inventory item inside of users inventory.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetProperties$Response(params, context) {
    return inventoryV2GetProperties(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Properties.
   *
   * Get properties for owned virtual item. It is possible to attach properties to inventory item inside of users inventory.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetProperties(params, context) {
    return this.inventoryV2GetProperties$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2SetProperties()` */
  static InventoryV2SetPropertiesPath = '/inventoryV2-setProperties';
  /**
   * Set Properties.
   *
   * Set properties for owned virtual item. It is possible to attach properties to inventory item inside of users inventory.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2SetProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2SetProperties$Response(params, context) {
    return inventoryV2SetProperties(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Properties.
   *
   * Set properties for owned virtual item. It is possible to attach properties to inventory item inside of users inventory.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2SetProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2SetProperties(params, context) {
    return this.inventoryV2SetProperties$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetUpgrades()` */
  static InventoryV2GetUpgradesPath = '/inventoryV2-getUpgrades';
  /**
   * Get Upgrades.
   *
   * Get inventory item upgrades. Non stackable items can have upgrades.
   * This endpoint returns the list of upgrades for a specific owned item.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetUpgrades()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetUpgrades$Response(params, context) {
    return inventoryV2GetUpgrades(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Upgrades.
   *
   * Get inventory item upgrades. Non stackable items can have upgrades.
   * This endpoint returns the list of upgrades for a specific owned item.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetUpgrades$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetUpgrades(params, context) {
    return this.inventoryV2GetUpgrades$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2Upgrade()` */
  static InventoryV2UpgradePath = '/inventoryV2-upgrade';
  /**
   * Upgrade.
   *
   * Upgrades user owned virtual item to a new level.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2Upgrade()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2Upgrade$Response(params, context) {
    return inventoryV2Upgrade(this.http, this.rootUrl, params, context);
  }
  /**
   * Upgrade.
   *
   * Upgrades user owned virtual item to a new level.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2Upgrade$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2Upgrade(params, context) {
    return this.inventoryV2Upgrade$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetByTags()` */
  static InventoryV2GetByTagsPath = '/inventoryV2-getByTags';
  /**
   * Get by Tags.
   *
   * Get all owned virtual items by provided tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByTags$Response(params, context) {
    return inventoryV2GetByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by Tags.
   *
   * Get all owned virtual items by provided tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetByTags(params, context) {
    return this.inventoryV2GetByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2GetTags()` */
  static InventoryV2GetTagsPath = '/inventoryV2-getTags';
  /**
   * Get Tags.
   *
   * Get all tags for a specific inventory item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2GetTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetTags$Response(params, context) {
    return inventoryV2GetTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Tags.
   *
   * Get all tags for a specific inventory item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2GetTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2GetTags(params, context) {
    return this.inventoryV2GetTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `inventoryV2SetTags()` */
  static InventoryV2SetTagsPath = '/inventoryV2-setTags';
  /**
   * Set Tags.
   *
   * Set tags for a specific inventory item
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryV2SetTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2SetTags$Response(params, context) {
    return inventoryV2SetTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Tags.
   *
   * Set tags for a specific inventory item
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryV2SetTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryV2SetTags(params, context) {
    return this.inventoryV2SetTags$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function InventoryV2Service_Factory(t) {
    return new (t || InventoryV2Service)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: InventoryV2Service,
    factory: InventoryV2Service.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InventoryV2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function storeV2Add(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2Add.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2Add.PATH = '/storeV2-add';

/* tslint:disable */
/* eslint-disable */
function storeV2AddItemsToLootBoxByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2AddItemsToLootBoxByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2AddItemsToLootBoxByIds.PATH = '/storeV2-addItemsToLootBoxByIds';

/* tslint:disable */
/* eslint-disable */
function storeV2AddItemsToLootBoxByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2AddItemsToLootBoxByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2AddItemsToLootBoxByTags.PATH = '/storeV2-addItemsToLootBoxByTags';

/* tslint:disable */
/* eslint-disable */
function storeV2BuyStoreOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2BuyStoreOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2BuyStoreOffer.PATH = '/storeV2-buyStoreOffer';

/* tslint:disable */
/* eslint-disable */
function storeV2BuyVirtualItems(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2BuyVirtualItems.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2BuyVirtualItems.PATH = '/storeV2-buyVirtualItems';

/* tslint:disable */
/* eslint-disable */
function storeV2CancelScheduledPriceChange(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2CancelScheduledPriceChange.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2CancelScheduledPriceChange.PATH = '/storeV2-cancelScheduledPriceChange';

/* tslint:disable */
/* eslint-disable */
function storeV2CreateLootBox(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2CreateLootBox.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2CreateLootBox.PATH = '/storeV2-createLootBox';

/* tslint:disable */
/* eslint-disable */
function storeV2DeleteLootBox(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2DeleteLootBox.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2DeleteLootBox.PATH = '/storeV2-deleteLootBox';

/* tslint:disable */
/* eslint-disable */
function storeV2DeleteStoreOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2DeleteStoreOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2DeleteStoreOffer.PATH = '/storeV2-deleteStoreOffer';

/* tslint:disable */
/* eslint-disable */
function storeV2GetByAppIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetByAppIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetByAppIds.PATH = '/storeV2-getByAppIds';

/* tslint:disable */
/* eslint-disable */
function storeV2GetByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetByIds.PATH = '/storeV2-getByIds';

/* tslint:disable */
/* eslint-disable */
function storeV2GetByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetByTags.PATH = '/storeV2-getByTags';

/* tslint:disable */
/* eslint-disable */
function storeV2GetByTimestamp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetByTimestamp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetByTimestamp.PATH = '/storeV2-getByTimestamp';

/* tslint:disable */
/* eslint-disable */
function storeV2GetLootBoxesByAppId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetLootBoxesByAppId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetLootBoxesByAppId.PATH = '/storeV2-getLootBoxesByAppId';

/* tslint:disable */
/* eslint-disable */
function storeV2GetLootBoxesByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetLootBoxesByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetLootBoxesByIds.PATH = '/storeV2-getLootBoxesByIds';

/* tslint:disable */
/* eslint-disable */
function storeV2GetProperties(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetProperties.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetProperties.PATH = '/storeV2-getProperties';

/* tslint:disable */
/* eslint-disable */
function storeV2GetScheduledPriceChanges(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetScheduledPriceChanges.PATH, 'post');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetScheduledPriceChanges.PATH = '/storeV2-getScheduledPriceChanges';

/* tslint:disable */
/* eslint-disable */
function storeV2GetTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetTags.PATH = '/storeV2-getTags';

/* tslint:disable */
/* eslint-disable */
function storeV2GetWithVirtualItemsDataByAppIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2GetWithVirtualItemsDataByAppIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2GetWithVirtualItemsDataByAppIds.PATH = '/storeV2-getWithVirtualItemsDataByAppIds';

/* tslint:disable */
/* eslint-disable */
function storeV2ImportStoreOffersFromCsv(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2ImportStoreOffersFromCsv.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2ImportStoreOffersFromCsv.PATH = '/storeV2-importStoreOffersFromCSV';

/* tslint:disable */
/* eslint-disable */
function storeV2IsAvailable(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2IsAvailable.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2IsAvailable.PATH = '/storeV2-isAvailable';

/* tslint:disable */
/* eslint-disable */
function storeV2LootBoxIsAvailable(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2LootBoxIsAvailable.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2LootBoxIsAvailable.PATH = '/storeV2-lootBoxIsAvailable';

/* tslint:disable */
/* eslint-disable */
function storeV2OpenLootBox(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2OpenLootBox.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2OpenLootBox.PATH = '/storeV2-openLootBox';

/* tslint:disable */
/* eslint-disable */
function storeV2SchedulePriceChangeForLootbox(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SchedulePriceChangeForLootbox.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2SchedulePriceChangeForLootbox.PATH = '/storeV2-schedulePriceChangeForLootbox';

/* tslint:disable */
/* eslint-disable */
function storeV2SchedulePriceChangeForStoreOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SchedulePriceChangeForStoreOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2SchedulePriceChangeForStoreOffer.PATH = '/storeV2-schedulePriceChangeForStoreOffer';

/* tslint:disable */
/* eslint-disable */
function storeV2SchedulePriceChangeForVirtualItem(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SchedulePriceChangeForVirtualItem.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2SchedulePriceChangeForVirtualItem.PATH = '/storeV2-schedulePriceChangeForVirtualItem';

/* tslint:disable */
/* eslint-disable */
function storeV2SetDescription(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SetDescription.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2SetDescription.PATH = '/storeV2-setDescription';

/* tslint:disable */
/* eslint-disable */
function storeV2SetImageUrl(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SetImageUrl.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2SetImageUrl.PATH = '/storeV2-setImageUrl';

/* tslint:disable */
/* eslint-disable */
function storeV2SetName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SetName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2SetName.PATH = '/storeV2-setName';

/* tslint:disable */
/* eslint-disable */
function storeV2SetPrices(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SetPrices.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2SetPrices.PATH = '/storeV2-setPrices';

/* tslint:disable */
/* eslint-disable */
function storeV2SetProperties(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SetProperties.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2SetProperties.PATH = '/storeV2-setProperties';

/* tslint:disable */
/* eslint-disable */
function storeV2SetTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SetTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2SetTags.PATH = '/storeV2-setTags';

/* tslint:disable */
/* eslint-disable */
function storeV2SetTime(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2SetTime.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
storeV2SetTime.PATH = '/storeV2-setTime';

/* tslint:disable */
/* eslint-disable */
function storeV2Update(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, storeV2Update.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
storeV2Update.PATH = '/storeV2-update';

/**
 * Store is a collection of endpoints that are responsible for managing the items that can be purchased in the game. The endpoints provide various functionalities such as purchasing NFT and non NFT items, getting store data, and fetching player limited time store offers.
 */
class StoreV2Service extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `storeV2BuyVirtualItems()` */
  static StoreV2BuyVirtualItemsPath = '/storeV2-buyVirtualItems';
  /**
   * Buy Virtual Items.
   *
   * Goes through the internal purchase process to buy multiple virtual items
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2BuyVirtualItems()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2BuyVirtualItems$Response(params, context) {
    return storeV2BuyVirtualItems(this.http, this.rootUrl, params, context);
  }
  /**
   * Buy Virtual Items.
   *
   * Goes through the internal purchase process to buy multiple virtual items
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2BuyVirtualItems$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2BuyVirtualItems(params, context) {
    return this.storeV2BuyVirtualItems$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2BuyStoreOffer()` */
  static StoreV2BuyStoreOfferPath = '/storeV2-buyStoreOffer';
  /**
   * Buy Store Offer.
   *
   * Goes through the internal purchase process to buy whole store offer (includes all itemsIds)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2BuyStoreOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2BuyStoreOffer$Response(params, context) {
    return storeV2BuyStoreOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Buy Store Offer.
   *
   * Goes through the internal purchase process to buy whole store offer (includes all itemsIds)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2BuyStoreOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2BuyStoreOffer(params, context) {
    return this.storeV2BuyStoreOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2CreateLootBox()` */
  static StoreV2CreateLootBoxPath = '/storeV2-createLootBox';
  /**
   * Create Loot Box.
   *
   * Create a new loot box. Loot boxes are used to group virtual items together.
   * When a user buys a loot box, they receive a random virtual item from the loot box.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2CreateLootBox()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2CreateLootBox$Response(params, context) {
    return storeV2CreateLootBox(this.http, this.rootUrl, params, context);
  }
  /**
   * Create Loot Box.
   *
   * Create a new loot box. Loot boxes are used to group virtual items together.
   * When a user buys a loot box, they receive a random virtual item from the loot box.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2CreateLootBox$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2CreateLootBox(params, context) {
    return this.storeV2CreateLootBox$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2AddItemsToLootBoxByTags()` */
  static StoreV2AddItemsToLootBoxByTagsPath = '/storeV2-addItemsToLootBoxByTags';
  /**
   * Add Items to Loot Box by Tags.
   *
   * Add items to a loot box based on virtual item tags.
   * You can add multiple virtual items to a loot box at once.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2AddItemsToLootBoxByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2AddItemsToLootBoxByTags$Response(params, context) {
    return storeV2AddItemsToLootBoxByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Add Items to Loot Box by Tags.
   *
   * Add items to a loot box based on virtual item tags.
   * You can add multiple virtual items to a loot box at once.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2AddItemsToLootBoxByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2AddItemsToLootBoxByTags(params, context) {
    return this.storeV2AddItemsToLootBoxByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2AddItemsToLootBoxByIds()` */
  static StoreV2AddItemsToLootBoxByIdsPath = '/storeV2-addItemsToLootBoxByIds';
  /**
   * Add Items to Loot Box by IDs.
   *
   * Add items to a loot box using item IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2AddItemsToLootBoxByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2AddItemsToLootBoxByIds$Response(params, context) {
    return storeV2AddItemsToLootBoxByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Add Items to Loot Box by IDs.
   *
   * Add items to a loot box using item IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2AddItemsToLootBoxByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2AddItemsToLootBoxByIds(params, context) {
    return this.storeV2AddItemsToLootBoxByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2DeleteLootBox()` */
  static StoreV2DeleteLootBoxPath = '/storeV2-deleteLootBox';
  /**
   * Delete Loot Box.
   *
   * Deletes a loot box. Only the loot box is deleted, the virtual items remain in the database.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2DeleteLootBox()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2DeleteLootBox$Response(params, context) {
    return storeV2DeleteLootBox(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete Loot Box.
   *
   * Deletes a loot box. Only the loot box is deleted, the virtual items remain in the database.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2DeleteLootBox$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2DeleteLootBox(params, context) {
    return this.storeV2DeleteLootBox$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetLootBoxesByIds()` */
  static StoreV2GetLootBoxesByIdsPath = '/storeV2-getLootBoxesByIds';
  /**
   * Get loot boxes by IDs.
   *
   * Retrieves loot boxes by their IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetLootBoxesByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetLootBoxesByIds$Response(params, context) {
    return storeV2GetLootBoxesByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get loot boxes by IDs.
   *
   * Retrieves loot boxes by their IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetLootBoxesByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetLootBoxesByIds(params, context) {
    return this.storeV2GetLootBoxesByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetLootBoxesByAppId()` */
  static StoreV2GetLootBoxesByAppIdPath = '/storeV2-getLootBoxesByAppId';
  /**
   * Get loot boxes by App ID.
   *
   * Get loot boxes by application ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetLootBoxesByAppId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetLootBoxesByAppId$Response(params, context) {
    return storeV2GetLootBoxesByAppId(this.http, this.rootUrl, params, context);
  }
  /**
   * Get loot boxes by App ID.
   *
   * Get loot boxes by application ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetLootBoxesByAppId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetLootBoxesByAppId(params, context) {
    return this.storeV2GetLootBoxesByAppId$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2LootBoxIsAvailable()` */
  static StoreV2LootBoxIsAvailablePath = '/storeV2-lootBoxIsAvailable';
  /**
   * Loot Box is Available.
   *
   * Check if a loot box is available. A loot box is available if it contains at least one virtual item.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2LootBoxIsAvailable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2LootBoxIsAvailable$Response(params, context) {
    return storeV2LootBoxIsAvailable(this.http, this.rootUrl, params, context);
  }
  /**
   * Loot Box is Available.
   *
   * Check if a loot box is available. A loot box is available if it contains at least one virtual item.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2LootBoxIsAvailable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2LootBoxIsAvailable(params, context) {
    return this.storeV2LootBoxIsAvailable$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2OpenLootBox()` */
  static StoreV2OpenLootBoxPath = '/storeV2-openLootBox';
  /**
   * Open Loot Box.
   *
   * Open a loot box. The user receives a random virtual item from the loot box.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2OpenLootBox()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2OpenLootBox$Response(params, context) {
    return storeV2OpenLootBox(this.http, this.rootUrl, params, context);
  }
  /**
   * Open Loot Box.
   *
   * Open a loot box. The user receives a random virtual item from the loot box.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2OpenLootBox$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2OpenLootBox(params, context) {
    return this.storeV2OpenLootBox$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2Add()` */
  static StoreV2AddPath = '/storeV2-add';
  /**
   * Add.
   *
   * Add new store offer containing virtual items
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2Add()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2Add$Response(params, context) {
    return storeV2Add(this.http, this.rootUrl, params, context);
  }
  /**
   * Add.
   *
   * Add new store offer containing virtual items
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2Add$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2Add(params, context) {
    return this.storeV2Add$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2ImportStoreOffersFromCsv()` */
  static StoreV2ImportStoreOffersFromCsvPath = '/storeV2-importStoreOffersFromCSV';
  /**
   * Import store offers from CSV.
   *
   * Adds new store offers from CSV file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2ImportStoreOffersFromCsv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2ImportStoreOffersFromCsv$Response(params, context) {
    return storeV2ImportStoreOffersFromCsv(this.http, this.rootUrl, params, context);
  }
  /**
   * Import store offers from CSV.
   *
   * Adds new store offers from CSV file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2ImportStoreOffersFromCsv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2ImportStoreOffersFromCsv(params, context) {
    return this.storeV2ImportStoreOffersFromCsv$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2Update()` */
  static StoreV2UpdatePath = '/storeV2-update';
  /**
   * Update.
   *
   * Changes parameters of existing offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2Update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2Update$Response(params, context) {
    return storeV2Update(this.http, this.rootUrl, params, context);
  }
  /**
   * Update.
   *
   * Changes parameters of existing offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2Update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2Update(params, context) {
    return this.storeV2Update$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SetTags()` */
  static StoreV2SetTagsPath = '/storeV2-setTags';
  /**
   * Set Tags.
   *
   * Sets the tags array for a specific shop offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SetTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetTags$Response(params, context) {
    return storeV2SetTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Tags.
   *
   * Sets the tags array for a specific shop offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SetTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetTags(params, context) {
    return this.storeV2SetTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SetName()` */
  static StoreV2SetNamePath = '/storeV2-setName';
  /**
   * Set Name.
   *
   * Sets the name for a specific shop offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SetName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetName$Response(params, context) {
    return storeV2SetName(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Name.
   *
   * Sets the name for a specific shop offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SetName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetName(params, context) {
    return this.storeV2SetName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SetDescription()` */
  static StoreV2SetDescriptionPath = '/storeV2-setDescription';
  /**
   * Set Description.
   *
   * Sets the description for a specific shop offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SetDescription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetDescription$Response(params, context) {
    return storeV2SetDescription(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Description.
   *
   * Sets the description for a specific shop offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SetDescription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetDescription(params, context) {
    return this.storeV2SetDescription$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SetPrices()` */
  static StoreV2SetPricesPath = '/storeV2-setPrices';
  /**
   * Set Prices.
   *
   * Sets the prices for a specific shop offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SetPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetPrices$Response(params, context) {
    return storeV2SetPrices(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Prices.
   *
   * Sets the prices for a specific shop offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SetPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetPrices(params, context) {
    return this.storeV2SetPrices$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SetTime()` */
  static StoreV2SetTimePath = '/storeV2-setTime';
  /**
   * Set Time.
   *
   * Sets the time for a specific shop offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SetTime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetTime$Response(params, context) {
    return storeV2SetTime(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Time.
   *
   * Sets the time for a specific shop offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SetTime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetTime(params, context) {
    return this.storeV2SetTime$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SetImageUrl()` */
  static StoreV2SetImageUrlPath = '/storeV2-setImageUrl';
  /**
   * Set Image Url.
   *
   * Sets the imageUrl for a specific store offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SetImageUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetImageUrl$Response(params, context) {
    return storeV2SetImageUrl(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Image Url.
   *
   * Sets the imageUrl for a specific store offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SetImageUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetImageUrl(params, context) {
    return this.storeV2SetImageUrl$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2DeleteStoreOffer()` */
  static StoreV2DeleteStoreOfferPath = '/storeV2-deleteStoreOffer';
  /**
   * Delete Store Offer.
   *
   * Deletes existing offer by provided offer id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2DeleteStoreOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2DeleteStoreOffer$Response(params, context) {
    return storeV2DeleteStoreOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete Store Offer.
   *
   * Deletes existing offer by provided offer id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2DeleteStoreOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2DeleteStoreOffer(params, context) {
    return this.storeV2DeleteStoreOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2IsAvailable()` */
  static StoreV2IsAvailablePath = '/storeV2-isAvailable';
  /**
   * Is Available.
   *
   * Checks if the store offer meets all requirements to be available for the users.
   * For more information you can refer to the StoreOffer.requiredToPurchase description.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2IsAvailable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2IsAvailable$Response(params, context) {
    return storeV2IsAvailable(this.http, this.rootUrl, params, context);
  }
  /**
   * Is Available.
   *
   * Checks if the store offer meets all requirements to be available for the users.
   * For more information you can refer to the StoreOffer.requiredToPurchase description.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2IsAvailable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2IsAvailable(params, context) {
    return this.storeV2IsAvailable$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetByTags()` */
  static StoreV2GetByTagsPath = '/storeV2-getByTags';
  /**
   * Get by Tags.
   *
   * Returns offers data based on specified tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByTags$Response(params, context) {
    return storeV2GetByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by Tags.
   *
   * Returns offers data based on specified tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByTags(params, context) {
    return this.storeV2GetByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetByTimestamp()` */
  static StoreV2GetByTimestampPath = '/storeV2-getByTimestamp';
  /**
   * Get by Timestamp.
   *
   * Returns offers data based on provided timestamp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetByTimestamp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByTimestamp$Response(params, context) {
    return storeV2GetByTimestamp(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by Timestamp.
   *
   * Returns offers data based on provided timestamp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetByTimestamp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByTimestamp(params, context) {
    return this.storeV2GetByTimestamp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetByAppIds()` */
  static StoreV2GetByAppIdsPath = '/storeV2-getByAppIds';
  /**
   * Get by App IDs.
   *
   * Returns offers data based on specified appIds
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetByAppIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByAppIds$Response(params, context) {
    return storeV2GetByAppIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by App IDs.
   *
   * Returns offers data based on specified appIds
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetByAppIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByAppIds(params, context) {
    return this.storeV2GetByAppIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetWithVirtualItemsDataByAppIds()` */
  static StoreV2GetWithVirtualItemsDataByAppIdsPath = '/storeV2-getWithVirtualItemsDataByAppIds';
  /**
   * Get with Virtual Items Data by App IDs.
   *
   * Returns offers data based on specified appIds, but with virtual items data included
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetWithVirtualItemsDataByAppIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetWithVirtualItemsDataByAppIds$Response(params, context) {
    return storeV2GetWithVirtualItemsDataByAppIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get with Virtual Items Data by App IDs.
   *
   * Returns offers data based on specified appIds, but with virtual items data included
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetWithVirtualItemsDataByAppIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetWithVirtualItemsDataByAppIds(params, context) {
    return this.storeV2GetWithVirtualItemsDataByAppIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetByIds()` */
  static StoreV2GetByIdsPath = '/storeV2-getByIds';
  /**
   * Get by IDs.
   *
   * Returns offers data based on specified offer ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByIds$Response(params, context) {
    return storeV2GetByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Get by IDs.
   *
   * Returns offers data based on specified offer ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetByIds(params, context) {
    return this.storeV2GetByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetTags()` */
  static StoreV2GetTagsPath = '/storeV2-getTags';
  /**
   * Get Tags.
   *
   * Returns all tags for a specific shop offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetTags$Response(params, context) {
    return storeV2GetTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Tags.
   *
   * Returns all tags for a specific shop offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetTags(params, context) {
    return this.storeV2GetTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetProperties()` */
  static StoreV2GetPropertiesPath = '/storeV2-getProperties';
  /**
   * Get Properties.
   *
   * Get properties for store offer item. It is possible to attach application specific properties to a store offer item.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetProperties$Response(params, context) {
    return storeV2GetProperties(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Properties.
   *
   * Get properties for store offer item. It is possible to attach application specific properties to a store offer item.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2GetProperties(params, context) {
    return this.storeV2GetProperties$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SetProperties()` */
  static StoreV2SetPropertiesPath = '/storeV2-setProperties';
  /**
   * Set Properties.
   *
   * Set properties for store offer item. It is possible to attach application specific properties to a store offer item.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SetProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetProperties$Response(params, context) {
    return storeV2SetProperties(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Properties.
   *
   * Set properties for store offer item. It is possible to attach application specific properties to a store offer item.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SetProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SetProperties(params, context) {
    return this.storeV2SetProperties$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SchedulePriceChangeForVirtualItem()` */
  static StoreV2SchedulePriceChangeForVirtualItemPath = '/storeV2-schedulePriceChangeForVirtualItem';
  /**
   * Schedules price change for a virtual item.
   *
   * Schedules price change for a virtual item. The price change will be applied at the provided time.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SchedulePriceChangeForVirtualItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SchedulePriceChangeForVirtualItem$Response(params, context) {
    return storeV2SchedulePriceChangeForVirtualItem(this.http, this.rootUrl, params, context);
  }
  /**
   * Schedules price change for a virtual item.
   *
   * Schedules price change for a virtual item. The price change will be applied at the provided time.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SchedulePriceChangeForVirtualItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SchedulePriceChangeForVirtualItem(params, context) {
    return this.storeV2SchedulePriceChangeForVirtualItem$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SchedulePriceChangeForStoreOffer()` */
  static StoreV2SchedulePriceChangeForStoreOfferPath = '/storeV2-schedulePriceChangeForStoreOffer';
  /**
   * Schedules price change for a store offer.
   *
   * Schedules price change for a store offer. The price change will be applied at the provided time.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SchedulePriceChangeForStoreOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SchedulePriceChangeForStoreOffer$Response(params, context) {
    return storeV2SchedulePriceChangeForStoreOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Schedules price change for a store offer.
   *
   * Schedules price change for a store offer. The price change will be applied at the provided time.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SchedulePriceChangeForStoreOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SchedulePriceChangeForStoreOffer(params, context) {
    return this.storeV2SchedulePriceChangeForStoreOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2SchedulePriceChangeForLootbox()` */
  static StoreV2SchedulePriceChangeForLootboxPath = '/storeV2-schedulePriceChangeForLootbox';
  /**
   * Schedules price change for a lootbox.
   *
   * Schedules price change for a lootbox. The price change will be applied at the provided time.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2SchedulePriceChangeForLootbox()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SchedulePriceChangeForLootbox$Response(params, context) {
    return storeV2SchedulePriceChangeForLootbox(this.http, this.rootUrl, params, context);
  }
  /**
   * Schedules price change for a lootbox.
   *
   * Schedules price change for a lootbox. The price change will be applied at the provided time.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2SchedulePriceChangeForLootbox$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2SchedulePriceChangeForLootbox(params, context) {
    return this.storeV2SchedulePriceChangeForLootbox$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2GetScheduledPriceChanges()` */
  static StoreV2GetScheduledPriceChangesPath = '/storeV2-getScheduledPriceChanges';
  /**
   * Get Scheduled Price Changes.
   *
   * Retrieves scheduled price changes. Will return all scheduled price changes for the caller application.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2GetScheduledPriceChanges()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeV2GetScheduledPriceChanges$Response(params, context) {
    return storeV2GetScheduledPriceChanges(this.http, this.rootUrl, params, context);
  }
  /**
   * Get Scheduled Price Changes.
   *
   * Retrieves scheduled price changes. Will return all scheduled price changes for the caller application.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2GetScheduledPriceChanges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storeV2GetScheduledPriceChanges(params, context) {
    return this.storeV2GetScheduledPriceChanges$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `storeV2CancelScheduledPriceChange()` */
  static StoreV2CancelScheduledPriceChangePath = '/storeV2-cancelScheduledPriceChange';
  /**
   * Cancel Scheduled Price Change.
   *
   * Cancels a scheduled price change
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeV2CancelScheduledPriceChange()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2CancelScheduledPriceChange$Response(params, context) {
    return storeV2CancelScheduledPriceChange(this.http, this.rootUrl, params, context);
  }
  /**
   * Cancel Scheduled Price Change.
   *
   * Cancels a scheduled price change
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeV2CancelScheduledPriceChange$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeV2CancelScheduledPriceChange(params, context) {
    return this.storeV2CancelScheduledPriceChange$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function StoreV2Service_Factory(t) {
    return new (t || StoreV2Service)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: StoreV2Service,
    factory: StoreV2Service.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreV2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function leaderboardV2Add(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2Add.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2Add.PATH = '/leaderboardV2-add';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2AddOrSetUserScoresFromGleamCsv(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2AddOrSetUserScoresFromGleamCsv.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2AddOrSetUserScoresFromGleamCsv.PATH = '/leaderboardV2-addOrSetUserScoresFromGleamCSV';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2AddScore(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2AddScore.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2AddScore.PATH = '/leaderboardV2-addScore';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2Delete(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2Delete.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
leaderboardV2Delete.PATH = '/leaderboardV2-delete';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetByAppIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetByAppIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetByAppIds.PATH = '/leaderboardV2-getByAppIds';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetById.PATH = '/leaderboardV2-getById';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetByRequestName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetByRequestName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetByRequestName.PATH = '/leaderboardV2-getByRequestName';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetByRequestNames(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetByRequestNames.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetByRequestNames.PATH = '/leaderboardV2-getByRequestNames';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetByTags.PATH = '/leaderboardV2-getByTags';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetEntries(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetEntries.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetEntries.PATH = '/leaderboardV2-getEntries';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetIds.PATH = '/leaderboardV2-getIds';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetReset(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetReset.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetReset.PATH = '/leaderboardV2-getReset';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetResets(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetResets.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetResets.PATH = '/leaderboardV2-getResets';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2GetUserEntry(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2GetUserEntry.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2GetUserEntry.PATH = '/leaderboardV2-getUserEntry';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2IsAvailable(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2IsAvailable.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2IsAvailable.PATH = '/leaderboardV2-isAvailable';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2IsInGracePeriod(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2IsInGracePeriod.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2IsInGracePeriod.PATH = '/leaderboardV2-isInGracePeriod';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2ResetLeaderboard(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2ResetLeaderboard.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
leaderboardV2ResetLeaderboard.PATH = '/leaderboardV2-resetLeaderboard';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2SetScore(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2SetScore.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2SetScore.PATH = '/leaderboardV2-setScore';

/* tslint:disable */
/* eslint-disable */
function leaderboardV2Update(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, leaderboardV2Update.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
leaderboardV2Update.PATH = '/leaderboardV2-update';

/**
 * The Leaderboards are responsible for managing player scores and ranking players according to their totals. The endpoints provide various functionalities such as setting player scores, getting leaderboard data, and fetching player entries on the leaderboard.
 */
class LeaderboardV2Service extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `leaderboardV2Add()` */
  static LeaderboardV2AddPath = '/leaderboardV2-add';
  /**
   * Add.
   *
   * Add a new leaderboard entry
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2Add()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2Add$Response(params, context) {
    return leaderboardV2Add(this.http, this.rootUrl, params, context);
  }
  /**
   * Add.
   *
   * Add a new leaderboard entry
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2Add$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2Add(params, context) {
    return this.leaderboardV2Add$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2Update()` */
  static LeaderboardV2UpdatePath = '/leaderboardV2-update';
  /**
   * Update.
   *
   * Update an existing leaderboard
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2Update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2Update$Response(params, context) {
    return leaderboardV2Update(this.http, this.rootUrl, params, context);
  }
  /**
   * Update.
   *
   * Update an existing leaderboard
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2Update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2Update(params, context) {
    return this.leaderboardV2Update$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2Delete()` */
  static LeaderboardV2DeletePath = '/leaderboardV2-delete';
  /**
   * Delete.
   *
   * Delete an existing leaderboard
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2Delete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2Delete$Response(params, context) {
    return leaderboardV2Delete(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete.
   *
   * Delete an existing leaderboard
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2Delete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2Delete(params, context) {
    return this.leaderboardV2Delete$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetById()` */
  static LeaderboardV2GetByIdPath = '/leaderboardV2-getById';
  /**
   * Retrieve a leaderboard entry by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetById$Response(params, context) {
    return leaderboardV2GetById(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a leaderboard entry by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetById(params, context) {
    return this.leaderboardV2GetById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetByRequestName()` */
  static LeaderboardV2GetByRequestNamePath = '/leaderboardV2-getByRequestName';
  /**
   * Retrieve a leaderboard entry by request name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetByRequestName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByRequestName$Response(params, context) {
    return leaderboardV2GetByRequestName(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a leaderboard entry by request name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetByRequestName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByRequestName(params, context) {
    return this.leaderboardV2GetByRequestName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetByRequestNames()` */
  static LeaderboardV2GetByRequestNamesPath = '/leaderboardV2-getByRequestNames';
  /**
   * Retrieve leaderboard entries by a list of request names.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetByRequestNames()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByRequestNames$Response(params, context) {
    return leaderboardV2GetByRequestNames(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve leaderboard entries by a list of request names.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetByRequestNames$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByRequestNames(params, context) {
    return this.leaderboardV2GetByRequestNames$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetByAppIds()` */
  static LeaderboardV2GetByAppIdsPath = '/leaderboardV2-getByAppIds';
  /**
   * Retrieve leaderboard entries by a list of application IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetByAppIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByAppIds$Response(params, context) {
    return leaderboardV2GetByAppIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve leaderboard entries by a list of application IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetByAppIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByAppIds(params, context) {
    return this.leaderboardV2GetByAppIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetByTags()` */
  static LeaderboardV2GetByTagsPath = '/leaderboardV2-getByTags';
  /**
   * Retrieve leaderboard entries by a list of tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByTags$Response(params, context) {
    return leaderboardV2GetByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve leaderboard entries by a list of tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetByTags(params, context) {
    return this.leaderboardV2GetByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetIds()` */
  static LeaderboardV2GetIdsPath = '/leaderboardV2-getIds';
  /**
   * Retrieve leaderboard IDs based on the given parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetIds$Response(params, context) {
    return leaderboardV2GetIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve leaderboard IDs based on the given parameters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetIds(params, context) {
    return this.leaderboardV2GetIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2IsAvailable()` */
  static LeaderboardV2IsAvailablePath = '/leaderboardV2-isAvailable';
  /**
   * Check if a leaderboard is available for a given user in a specific application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2IsAvailable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2IsAvailable$Response(params, context) {
    return leaderboardV2IsAvailable(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if a leaderboard is available for a given user in a specific application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2IsAvailable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2IsAvailable(params, context) {
    return this.leaderboardV2IsAvailable$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2IsInGracePeriod()` */
  static LeaderboardV2IsInGracePeriodPath = '/leaderboardV2-isInGracePeriod';
  /**
   * Check if a leaderboard is in grace period by leaderboard ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2IsInGracePeriod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2IsInGracePeriod$Response(params, context) {
    return leaderboardV2IsInGracePeriod(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if a leaderboard is in grace period by leaderboard ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2IsInGracePeriod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2IsInGracePeriod(params, context) {
    return this.leaderboardV2IsInGracePeriod$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2SetScore()` */
  static LeaderboardV2SetScorePath = '/leaderboardV2-setScore';
  /**
   * Set Score.
   *
   * Set the score for a user on a specific leaderboard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2SetScore()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2SetScore$Response(params, context) {
    return leaderboardV2SetScore(this.http, this.rootUrl, params, context);
  }
  /**
   * Set Score.
   *
   * Set the score for a user on a specific leaderboard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2SetScore$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2SetScore(params, context) {
    return this.leaderboardV2SetScore$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2AddScore()` */
  static LeaderboardV2AddScorePath = '/leaderboardV2-addScore';
  /**
   * Add Score.
   *
   * Add a score to the existing score of a user on a specific leaderboard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2AddScore()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2AddScore$Response(params, context) {
    return leaderboardV2AddScore(this.http, this.rootUrl, params, context);
  }
  /**
   * Add Score.
   *
   * Add a score to the existing score of a user on a specific leaderboard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2AddScore$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2AddScore(params, context) {
    return this.leaderboardV2AddScore$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2AddOrSetUserScoresFromGleamCsv()` */
  static LeaderboardV2AddOrSetUserScoresFromGleamCsvPath = '/leaderboardV2-addOrSetUserScoresFromGleamCSV';
  /**
   * Add or Set User Scores from Gleam CSV.
   *
   * Add or set user scores from a Gleam CSV file for a specific leaderboard.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2AddOrSetUserScoresFromGleamCsv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2AddOrSetUserScoresFromGleamCsv$Response(params, context) {
    return leaderboardV2AddOrSetUserScoresFromGleamCsv(this.http, this.rootUrl, params, context);
  }
  /**
   * Add or Set User Scores from Gleam CSV.
   *
   * Add or set user scores from a Gleam CSV file for a specific leaderboard.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2AddOrSetUserScoresFromGleamCsv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2AddOrSetUserScoresFromGleamCsv(params, context) {
    return this.leaderboardV2AddOrSetUserScoresFromGleamCsv$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetUserEntry()` */
  static LeaderboardV2GetUserEntryPath = '/leaderboardV2-getUserEntry';
  /**
   * Retrieves a user's entry from a leaderboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetUserEntry()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetUserEntry$Response(params, context) {
    return leaderboardV2GetUserEntry(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieves a user's entry from a leaderboard.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetUserEntry$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetUserEntry(params, context) {
    return this.leaderboardV2GetUserEntry$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetEntries()` */
  static LeaderboardV2GetEntriesPath = '/leaderboardV2-getEntries';
  /**
   * Retrieve leaderboard entries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetEntries()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetEntries$Response(params, context) {
    return leaderboardV2GetEntries(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve leaderboard entries.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetEntries$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetEntries(params, context) {
    return this.leaderboardV2GetEntries$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetResets()` */
  static LeaderboardV2GetResetsPath = '/leaderboardV2-getResets';
  /**
   * Retrieve leaderboard resets.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetResets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetResets$Response(params, context) {
    return leaderboardV2GetResets(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve leaderboard resets.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetResets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetResets(params, context) {
    return this.leaderboardV2GetResets$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2GetReset()` */
  static LeaderboardV2GetResetPath = '/leaderboardV2-getReset';
  /**
   * Retrieve leaderboard reset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2GetReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetReset$Response(params, context) {
    return leaderboardV2GetReset(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve leaderboard reset.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2GetReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2GetReset(params, context) {
    return this.leaderboardV2GetReset$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `leaderboardV2ResetLeaderboard()` */
  static LeaderboardV2ResetLeaderboardPath = '/leaderboardV2-resetLeaderboard';
  /**
   * Reset leaderboard. Gives the rewards to the users and resets the leaderboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaderboardV2ResetLeaderboard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2ResetLeaderboard$Response(params, context) {
    return leaderboardV2ResetLeaderboard(this.http, this.rootUrl, params, context);
  }
  /**
   * Reset leaderboard. Gives the rewards to the users and resets the leaderboard.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaderboardV2ResetLeaderboard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  leaderboardV2ResetLeaderboard(params, context) {
    return this.leaderboardV2ResetLeaderboard$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function LeaderboardV2Service_Factory(t) {
    return new (t || LeaderboardV2Service)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: LeaderboardV2Service,
    factory: LeaderboardV2Service.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LeaderboardV2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function achievementsAdd(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsAdd.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsAdd.PATH = '/achievements-add';

/* tslint:disable */
/* eslint-disable */
function achievementsAddLoginDaysInRowAchievement(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsAddLoginDaysInRowAchievement.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsAddLoginDaysInRowAchievement.PATH = '/achievements-addLoginDaysInRowAchievement';

/* tslint:disable */
/* eslint-disable */
function achievementsAddPlayerProgressAchievement(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsAddPlayerProgressAchievement.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsAddPlayerProgressAchievement.PATH = '/achievements-addPlayerProgressAchievement';

/* tslint:disable */
/* eslint-disable */
function achievementsAddPurchaseAchievement(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsAddPurchaseAchievement.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsAddPurchaseAchievement.PATH = '/achievements-addPurchaseAchievement';

/* tslint:disable */
/* eslint-disable */
function achievementsClaimById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsClaimById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsClaimById.PATH = '/achievements-claimById';

/* tslint:disable */
/* eslint-disable */
function achievementsClaimByRequestName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsClaimByRequestName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsClaimByRequestName.PATH = '/achievements-claimByRequestName';

/* tslint:disable */
/* eslint-disable */
function achievementsDelete(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsDelete.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsDelete.PATH = '/achievements-delete';

/* tslint:disable */
/* eslint-disable */
function achievementsDeleteLoginDaysInRowGameConstRecord(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsDeleteLoginDaysInRowGameConstRecord.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsDeleteLoginDaysInRowGameConstRecord.PATH = '/achievements-deleteLoginDaysInRowGameConstRecord';

/* tslint:disable */
/* eslint-disable */
function achievementsDeletePlayerProgressGameConstRecord(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsDeletePlayerProgressGameConstRecord.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsDeletePlayerProgressGameConstRecord.PATH = '/achievements-deletePlayerProgressGameConstRecord';

/* tslint:disable */
/* eslint-disable */
function achievementsDeletePurchaseGameConstRecord(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsDeletePurchaseGameConstRecord.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsDeletePurchaseGameConstRecord.PATH = '/achievements-deletePurchaseGameConstRecord';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByAppIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByAppIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByAppIds.PATH = '/achievements-getByAppIds';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByAppIdsWithUserData(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByAppIdsWithUserData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByAppIdsWithUserData.PATH = '/achievements-getByAppIdsWithUserData';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByIds.PATH = '/achievements-getByIds';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByIdsWithUserData(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByIdsWithUserData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByIdsWithUserData.PATH = '/achievements-getByIdsWithUserData';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByRequestName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByRequestName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByRequestName.PATH = '/achievements-getByRequestName';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByRequestNames(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByRequestNames.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByRequestNames.PATH = '/achievements-getByRequestNames';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByTags.PATH = '/achievements-getByTags';

/* tslint:disable */
/* eslint-disable */
function achievementsGetByTagsWithUserData(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetByTagsWithUserData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetByTagsWithUserData.PATH = '/achievements-getByTagsWithUserData';

/* tslint:disable */
/* eslint-disable */
function achievementsGetProjectAchievements(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsGetProjectAchievements.PATH, 'post');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsGetProjectAchievements.PATH = '/achievements-getProjectAchievements';

/* tslint:disable */
/* eslint-disable */
function achievementsTriggerById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsTriggerById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsTriggerById.PATH = '/achievements-triggerById';

/* tslint:disable */
/* eslint-disable */
function achievementsTriggerByRequestName(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsTriggerByRequestName.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsTriggerByRequestName.PATH = '/achievements-triggerByRequestName';

/* tslint:disable */
/* eslint-disable */
function achievementsUpdate(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, achievementsUpdate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
achievementsUpdate.PATH = '/achievements-update';

/**
 * Achievements are a way to reward players for completing certain tasks in the game. The endpoints provide various functionalities such as setting player achievements, getting achievement data, and fetching player entries on the leaderboard.
 */
class AchievementsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `achievementsAdd()` */
  static AchievementsAddPath = '/achievements-add';
  /**
   * Add.
   *
   * Adds a new achievement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAdd$Response(params, context) {
    return achievementsAdd(this.http, this.rootUrl, params, context);
  }
  /**
   * Add.
   *
   * Adds a new achievement
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAdd(params, context) {
    return this.achievementsAdd$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsUpdate()` */
  static AchievementsUpdatePath = '/achievements-update';
  /**
   * Update.
   *
   * Update an existing achievement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsUpdate$Response(params, context) {
    return achievementsUpdate(this.http, this.rootUrl, params, context);
  }
  /**
   * Update.
   *
   * Update an existing achievement
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsUpdate(params, context) {
    return this.achievementsUpdate$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsDelete()` */
  static AchievementsDeletePath = '/achievements-delete';
  /**
   * Delete an achievement.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDelete$Response(params, context) {
    return achievementsDelete(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete an achievement.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDelete(params, context) {
    return this.achievementsDelete$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByIds()` */
  static AchievementsGetByIdsPath = '/achievements-getByIds';
  /**
   * Retrieve achievements by their IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByIds$Response(params, context) {
    return achievementsGetByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve achievements by their IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByIds(params, context) {
    return this.achievementsGetByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByAppIds()` */
  static AchievementsGetByAppIdsPath = '/achievements-getByAppIds';
  /**
   * Retrieve achievements by application IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByAppIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByAppIds$Response(params, context) {
    return achievementsGetByAppIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve achievements by application IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByAppIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByAppIds(params, context) {
    return this.achievementsGetByAppIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByRequestName()` */
  static AchievementsGetByRequestNamePath = '/achievements-getByRequestName';
  /**
   * Retrieve a specific achievement by request name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByRequestName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByRequestName$Response(params, context) {
    return achievementsGetByRequestName(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a specific achievement by request name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByRequestName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByRequestName(params, context) {
    return this.achievementsGetByRequestName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByRequestNames()` */
  static AchievementsGetByRequestNamesPath = '/achievements-getByRequestNames';
  /**
   * Retrieve achievements by a list of request names.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByRequestNames()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByRequestNames$Response(params, context) {
    return achievementsGetByRequestNames(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve achievements by a list of request names.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByRequestNames$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByRequestNames(params, context) {
    return this.achievementsGetByRequestNames$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByTags()` */
  static AchievementsGetByTagsPath = '/achievements-getByTags';
  /**
   * Retrieve achievements by tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByTags$Response(params, context) {
    return achievementsGetByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve achievements by tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByTags(params, context) {
    return this.achievementsGetByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByTagsWithUserData()` */
  static AchievementsGetByTagsWithUserDataPath = '/achievements-getByTagsWithUserData';
  /**
   * Retrieve achievements and user data by tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByTagsWithUserData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByTagsWithUserData$Response(params, context) {
    return achievementsGetByTagsWithUserData(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve achievements and user data by tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByTagsWithUserData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByTagsWithUserData(params, context) {
    return this.achievementsGetByTagsWithUserData$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByAppIdsWithUserData()` */
  static AchievementsGetByAppIdsWithUserDataPath = '/achievements-getByAppIdsWithUserData';
  /**
   * Retrieve achievements and user data by application IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByAppIdsWithUserData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByAppIdsWithUserData$Response(params, context) {
    return achievementsGetByAppIdsWithUserData(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve achievements and user data by application IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByAppIdsWithUserData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByAppIdsWithUserData(params, context) {
    return this.achievementsGetByAppIdsWithUserData$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetByIdsWithUserData()` */
  static AchievementsGetByIdsWithUserDataPath = '/achievements-getByIdsWithUserData';
  /**
   * Retrieve achievements and user data by achievement IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetByIdsWithUserData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByIdsWithUserData$Response(params, context) {
    return achievementsGetByIdsWithUserData(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve achievements and user data by achievement IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetByIdsWithUserData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsGetByIdsWithUserData(params, context) {
    return this.achievementsGetByIdsWithUserData$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsTriggerByRequestName()` */
  static AchievementsTriggerByRequestNamePath = '/achievements-triggerByRequestName';
  /**
   * Trigger an achievement by request name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsTriggerByRequestName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsTriggerByRequestName$Response(params, context) {
    return achievementsTriggerByRequestName(this.http, this.rootUrl, params, context);
  }
  /**
   * Trigger an achievement by request name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsTriggerByRequestName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsTriggerByRequestName(params, context) {
    return this.achievementsTriggerByRequestName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsTriggerById()` */
  static AchievementsTriggerByIdPath = '/achievements-triggerById';
  /**
   * Trigger an achievement by its unique identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsTriggerById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsTriggerById$Response(params, context) {
    return achievementsTriggerById(this.http, this.rootUrl, params, context);
  }
  /**
   * Trigger an achievement by its unique identifier.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsTriggerById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsTriggerById(params, context) {
    return this.achievementsTriggerById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsClaimByRequestName()` */
  static AchievementsClaimByRequestNamePath = '/achievements-claimByRequestName';
  /**
   * Claim an achievement by request name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsClaimByRequestName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsClaimByRequestName$Response(params, context) {
    return achievementsClaimByRequestName(this.http, this.rootUrl, params, context);
  }
  /**
   * Claim an achievement by request name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsClaimByRequestName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsClaimByRequestName(params, context) {
    return this.achievementsClaimByRequestName$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsClaimById()` */
  static AchievementsClaimByIdPath = '/achievements-claimById';
  /**
   * Claim an achievement by its unique identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsClaimById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsClaimById$Response(params, context) {
    return achievementsClaimById(this.http, this.rootUrl, params, context);
  }
  /**
   * Claim an achievement by its unique identifier.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsClaimById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsClaimById(params, context) {
    return this.achievementsClaimById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsAddLoginDaysInRowAchievement()` */
  static AchievementsAddLoginDaysInRowAchievementPath = '/achievements-addLoginDaysInRowAchievement';
  /**
   * Adds a login days in row achievement.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsAddLoginDaysInRowAchievement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAddLoginDaysInRowAchievement$Response(params, context) {
    return achievementsAddLoginDaysInRowAchievement(this.http, this.rootUrl, params, context);
  }
  /**
   * Adds a login days in row achievement.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsAddLoginDaysInRowAchievement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAddLoginDaysInRowAchievement(params, context) {
    return this.achievementsAddLoginDaysInRowAchievement$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsDeleteLoginDaysInRowGameConstRecord()` */
  static AchievementsDeleteLoginDaysInRowGameConstRecordPath = '/achievements-deleteLoginDaysInRowGameConstRecord';
  /**
   * Deletes a login days in row achievement record from game constants.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsDeleteLoginDaysInRowGameConstRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDeleteLoginDaysInRowGameConstRecord$Response(params, context) {
    return achievementsDeleteLoginDaysInRowGameConstRecord(this.http, this.rootUrl, params, context);
  }
  /**
   * Deletes a login days in row achievement record from game constants.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsDeleteLoginDaysInRowGameConstRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDeleteLoginDaysInRowGameConstRecord(params, context) {
    return this.achievementsDeleteLoginDaysInRowGameConstRecord$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsAddPlayerProgressAchievement()` */
  static AchievementsAddPlayerProgressAchievementPath = '/achievements-addPlayerProgressAchievement';
  /**
   * Adds a player progress achievement.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsAddPlayerProgressAchievement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAddPlayerProgressAchievement$Response(params, context) {
    return achievementsAddPlayerProgressAchievement(this.http, this.rootUrl, params, context);
  }
  /**
   * Adds a player progress achievement.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsAddPlayerProgressAchievement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAddPlayerProgressAchievement(params, context) {
    return this.achievementsAddPlayerProgressAchievement$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsDeletePlayerProgressGameConstRecord()` */
  static AchievementsDeletePlayerProgressGameConstRecordPath = '/achievements-deletePlayerProgressGameConstRecord';
  /**
   * Deletes a player progress achievement record from game constants.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsDeletePlayerProgressGameConstRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDeletePlayerProgressGameConstRecord$Response(params, context) {
    return achievementsDeletePlayerProgressGameConstRecord(this.http, this.rootUrl, params, context);
  }
  /**
   * Deletes a player progress achievement record from game constants.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsDeletePlayerProgressGameConstRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDeletePlayerProgressGameConstRecord(params, context) {
    return this.achievementsDeletePlayerProgressGameConstRecord$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsAddPurchaseAchievement()` */
  static AchievementsAddPurchaseAchievementPath = '/achievements-addPurchaseAchievement';
  /**
   * Adds purchase achievements.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsAddPurchaseAchievement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAddPurchaseAchievement$Response(params, context) {
    return achievementsAddPurchaseAchievement(this.http, this.rootUrl, params, context);
  }
  /**
   * Adds purchase achievements.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsAddPurchaseAchievement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsAddPurchaseAchievement(params, context) {
    return this.achievementsAddPurchaseAchievement$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsDeletePurchaseGameConstRecord()` */
  static AchievementsDeletePurchaseGameConstRecordPath = '/achievements-deletePurchaseGameConstRecord';
  /**
   * Deletes a purchase achievement record from game constants.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsDeletePurchaseGameConstRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDeletePurchaseGameConstRecord$Response(params, context) {
    return achievementsDeletePurchaseGameConstRecord(this.http, this.rootUrl, params, context);
  }
  /**
   * Deletes a purchase achievement record from game constants.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsDeletePurchaseGameConstRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  achievementsDeletePurchaseGameConstRecord(params, context) {
    return this.achievementsDeletePurchaseGameConstRecord$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `achievementsGetProjectAchievements()` */
  static AchievementsGetProjectAchievementsPath = '/achievements-getProjectAchievements';
  /**
   * Get project achievements.
   *
   * Retrieves currently active achievements for a project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `achievementsGetProjectAchievements()` instead.
   *
   * This method doesn't expect any request body.
   */
  achievementsGetProjectAchievements$Response(params, context) {
    return achievementsGetProjectAchievements(this.http, this.rootUrl, params, context);
  }
  /**
   * Get project achievements.
   *
   * Retrieves currently active achievements for a project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `achievementsGetProjectAchievements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  achievementsGetProjectAchievements(params, context) {
    return this.achievementsGetProjectAchievements$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function AchievementsService_Factory(t) {
    return new (t || AchievementsService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AchievementsService,
    factory: AchievementsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AchievementsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function currencyAddUserCurrencies(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyAddUserCurrencies.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyAddUserCurrencies.PATH = '/currency-addUserCurrencies';

/* tslint:disable */
/* eslint-disable */
function currencyAddUserGlobalCurrencies(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyAddUserGlobalCurrencies.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyAddUserGlobalCurrencies.PATH = '/currency-addUserGlobalCurrencies';

/* tslint:disable */
/* eslint-disable */
function currencyGetProducts(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyGetProducts.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyGetProducts.PATH = '/currency-getProducts';

/* tslint:disable */
/* eslint-disable */
function currencyGetPurchases(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyGetPurchases.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyGetPurchases.PATH = '/currency-getPurchases';

/* tslint:disable */
/* eslint-disable */
function currencyGetRgnCoinEconomy(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyGetRgnCoinEconomy.PATH, 'post');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyGetRgnCoinEconomy.PATH = '/currency-getRGNCoinEconomy';

/* tslint:disable */
/* eslint-disable */
function currencyGetUserCurrencies(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyGetUserCurrencies.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyGetUserCurrencies.PATH = '/currency-getUserCurrencies';

/* tslint:disable */
/* eslint-disable */
function currencyGetUserGlobalCurrencies(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyGetUserGlobalCurrencies.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyGetUserGlobalCurrencies.PATH = '/currency-getUserGlobalCurrencies';

/* tslint:disable */
/* eslint-disable */
function currencyGetVerificationInfo(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyGetVerificationInfo.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyGetVerificationInfo.PATH = '/currency-getVerificationInfo';

/* tslint:disable */
/* eslint-disable */
function currencyPurchaseProduct(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyPurchaseProduct.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyPurchaseProduct.PATH = '/currency-purchaseProduct';

/* tslint:disable */
/* eslint-disable */
function currencyPurchaseRgnCoinV2(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyPurchaseRgnCoinV2.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyPurchaseRgnCoinV2.PATH = '/currency-purchaseRGNCoinV2';

/* tslint:disable */
/* eslint-disable */
function currencyUpdateAppleAppStoreVerificationInfo(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyUpdateAppleAppStoreVerificationInfo.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyUpdateAppleAppStoreVerificationInfo.PATH = '/currency-updateAppleAppStoreVerificationInfo';

/* tslint:disable */
/* eslint-disable */
function currencyUpdateGooglePlayVerificationInfo(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, currencyUpdateGooglePlayVerificationInfo.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
currencyUpdateGooglePlayVerificationInfo.PATH = '/currency-updateGooglePlayVerificationInfo';

/**
 * Currency endpoints. Working with currencies, getting currency data, adding currency to user account etc.
 */
class CurrencyService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `currencyGetRgnCoinEconomy()` */
  static CurrencyGetRgnCoinEconomyPath = '/currency-getRGNCoinEconomy';
  /**
   * Retrieves the READYgg Coin Economy data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyGetRgnCoinEconomy()` instead.
   *
   * This method doesn't expect any request body.
   */
  currencyGetRgnCoinEconomy$Response(params, context) {
    return currencyGetRgnCoinEconomy(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieves the READYgg Coin Economy data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyGetRgnCoinEconomy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  currencyGetRgnCoinEconomy(params, context) {
    return this.currencyGetRgnCoinEconomy$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyPurchaseRgnCoinV2()` */
  static CurrencyPurchaseRgnCoinV2Path = '/currency-purchaseRGNCoinV2';
  /**
   * Purchase READYgg Coin V2 with improved security.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyPurchaseRgnCoinV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyPurchaseRgnCoinV2$Response(params, context) {
    return currencyPurchaseRgnCoinV2(this.http, this.rootUrl, params, context);
  }
  /**
   * Purchase READYgg Coin V2 with improved security.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyPurchaseRgnCoinV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyPurchaseRgnCoinV2(params, context) {
    return this.currencyPurchaseRgnCoinV2$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyGetProducts()` */
  static CurrencyGetProductsPath = '/currency-getProducts';
  /**
   * Retrieves products available for purchase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyGetProducts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetProducts$Response(params, context) {
    return currencyGetProducts(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieves products available for purchase.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyGetProducts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetProducts(params, context) {
    return this.currencyGetProducts$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyPurchaseProduct()` */
  static CurrencyPurchaseProductPath = '/currency-purchaseProduct';
  /**
   * Purchase a product.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyPurchaseProduct()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyPurchaseProduct$Response(params, context) {
    return currencyPurchaseProduct(this.http, this.rootUrl, params, context);
  }
  /**
   * Purchase a product.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyPurchaseProduct$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyPurchaseProduct(params, context) {
    return this.currencyPurchaseProduct$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyGetUserCurrencies()` */
  static CurrencyGetUserCurrenciesPath = '/currency-getUserCurrencies';
  /**
   * Retrieves project currencies for a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyGetUserCurrencies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetUserCurrencies$Response(params, context) {
    return currencyGetUserCurrencies(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieves project currencies for a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyGetUserCurrencies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetUserCurrencies(params, context) {
    return this.currencyGetUserCurrencies$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyAddUserCurrencies()` */
  static CurrencyAddUserCurrenciesPath = '/currency-addUserCurrencies';
  /**
   * Add currencies to a user's account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyAddUserCurrencies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyAddUserCurrencies$Response(params, context) {
    return currencyAddUserCurrencies(this.http, this.rootUrl, params, context);
  }
  /**
   * Add currencies to a user's account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyAddUserCurrencies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyAddUserCurrencies(params, context) {
    return this.currencyAddUserCurrencies$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyAddUserGlobalCurrencies()` */
  static CurrencyAddUserGlobalCurrenciesPath = '/currency-addUserGlobalCurrencies';
  /**
   * Add global currencies to a user's account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyAddUserGlobalCurrencies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyAddUserGlobalCurrencies$Response(params, context) {
    return currencyAddUserGlobalCurrencies(this.http, this.rootUrl, params, context);
  }
  /**
   * Add global currencies to a user's account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyAddUserGlobalCurrencies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyAddUserGlobalCurrencies(params, context) {
    return this.currencyAddUserGlobalCurrencies$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyGetUserGlobalCurrencies()` */
  static CurrencyGetUserGlobalCurrenciesPath = '/currency-getUserGlobalCurrencies';
  /**
   * Retrieves global currencies for a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyGetUserGlobalCurrencies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetUserGlobalCurrencies$Response(params, context) {
    return currencyGetUserGlobalCurrencies(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieves global currencies for a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyGetUserGlobalCurrencies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetUserGlobalCurrencies(params, context) {
    return this.currencyGetUserGlobalCurrencies$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyGetPurchases()` */
  static CurrencyGetPurchasesPath = '/currency-getPurchases';
  /**
   * Get all purchases for an application.
   *
   * Retrieves all purchases for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyGetPurchases()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetPurchases$Response(params, context) {
    return currencyGetPurchases(this.http, this.rootUrl, params, context);
  }
  /**
   * Get all purchases for an application.
   *
   * Retrieves all purchases for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyGetPurchases$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetPurchases(params, context) {
    return this.currencyGetPurchases$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyGetVerificationInfo()` */
  static CurrencyGetVerificationInfoPath = '/currency-getVerificationInfo';
  /**
   * Get verification information for an application.
   *
   * Retrieves verification information for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyGetVerificationInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetVerificationInfo$Response(params, context) {
    return currencyGetVerificationInfo(this.http, this.rootUrl, params, context);
  }
  /**
   * Get verification information for an application.
   *
   * Retrieves verification information for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyGetVerificationInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyGetVerificationInfo(params, context) {
    return this.currencyGetVerificationInfo$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyUpdateAppleAppStoreVerificationInfo()` */
  static CurrencyUpdateAppleAppStoreVerificationInfoPath = '/currency-updateAppleAppStoreVerificationInfo';
  /**
   * Update Apple App Store verification information.
   *
   * Updates the Apple App Store verification information for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyUpdateAppleAppStoreVerificationInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyUpdateAppleAppStoreVerificationInfo$Response(params, context) {
    return currencyUpdateAppleAppStoreVerificationInfo(this.http, this.rootUrl, params, context);
  }
  /**
   * Update Apple App Store verification information.
   *
   * Updates the Apple App Store verification information for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyUpdateAppleAppStoreVerificationInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyUpdateAppleAppStoreVerificationInfo(params, context) {
    return this.currencyUpdateAppleAppStoreVerificationInfo$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `currencyUpdateGooglePlayVerificationInfo()` */
  static CurrencyUpdateGooglePlayVerificationInfoPath = '/currency-updateGooglePlayVerificationInfo';
  /**
   * Update Google Play verification information.
   *
   * Updates the Google Play verification information for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currencyUpdateGooglePlayVerificationInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyUpdateGooglePlayVerificationInfo$Response(params, context) {
    return currencyUpdateGooglePlayVerificationInfo(this.http, this.rootUrl, params, context);
  }
  /**
   * Update Google Play verification information.
   *
   * Updates the Google Play verification information for an application. This endpoint requires project admin or owner role.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currencyUpdateGooglePlayVerificationInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  currencyUpdateGooglePlayVerificationInfo(params, context) {
    return this.currencyUpdateGooglePlayVerificationInfo$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function CurrencyService_Factory(t) {
    return new (t || CurrencyService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CurrencyService,
    factory: CurrencyService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CurrencyService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function gameAddPlayerProgress(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gameAddPlayerProgress.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gameAddPlayerProgress.PATH = '/game-addPlayerProgress';

/* tslint:disable */
/* eslint-disable */
function gameGetGameProgress(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gameGetGameProgress.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gameGetGameProgress.PATH = '/game-getGameProgress';

/* tslint:disable */
/* eslint-disable */
function gameGetPlayerProgress(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gameGetPlayerProgress.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gameGetPlayerProgress.PATH = '/game-getPlayerProgress';

/* tslint:disable */
/* eslint-disable */
function gameOnGameComplete(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gameOnGameComplete.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gameOnGameComplete.PATH = '/game-onGameComplete';

/* tslint:disable */
/* eslint-disable */
function gameUpdatePlayerProgress(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gameUpdatePlayerProgress.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gameUpdatePlayerProgress.PATH = '/game-updatePlayerProgress';

/**
 * Storing game specific player data, getting game data, user level up.
 */
class GameService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `gameOnGameComplete()` */
  static GameOnGameCompletePath = '/game-onGameComplete';
  /**
   * Level ups the current logged in user and gives user rewards by adding custom currencies to the user profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gameOnGameComplete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameOnGameComplete$Response(params, context) {
    return gameOnGameComplete(this.http, this.rootUrl, params, context);
  }
  /**
   * Level ups the current logged in user and gives user rewards by adding custom currencies to the user profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gameOnGameComplete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameOnGameComplete(params, context) {
    return this.gameOnGameComplete$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gameGetGameProgress()` */
  static GameGetGameProgressPath = '/game-getGameProgress';
  /**
   * Gets the current user level.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gameGetGameProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameGetGameProgress$Response(params, context) {
    return gameGetGameProgress(this.http, this.rootUrl, params, context);
  }
  /**
   * Gets the current user level.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gameGetGameProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameGetGameProgress(params, context) {
    return this.gameGetGameProgress$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gameAddPlayerProgress()` */
  static GameAddPlayerProgressPath = '/game-addPlayerProgress';
  /**
   * Add Player Progress.
   *
   * Attaches custom data to the current logged in user account. You can store game specific arbitrary data attached to the user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gameAddPlayerProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameAddPlayerProgress$Response(params, context) {
    return gameAddPlayerProgress(this.http, this.rootUrl, params, context);
  }
  /**
   * Add Player Progress.
   *
   * Attaches custom data to the current logged in user account. You can store game specific arbitrary data attached to the user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gameAddPlayerProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameAddPlayerProgress(params, context) {
    return this.gameAddPlayerProgress$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gameUpdatePlayerProgress()` */
  static GameUpdatePlayerProgressPath = '/game-updatePlayerProgress';
  /**
   * Attaches custom data and possibly coin rewards to the current logged in user account. 'rgn-coin' is not allowed as a reward.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gameUpdatePlayerProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameUpdatePlayerProgress$Response(params, context) {
    return gameUpdatePlayerProgress(this.http, this.rootUrl, params, context);
  }
  /**
   * Attaches custom data and possibly coin rewards to the current logged in user account. 'rgn-coin' is not allowed as a reward.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gameUpdatePlayerProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameUpdatePlayerProgress(params, context) {
    return this.gameUpdatePlayerProgress$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gameGetPlayerProgress()` */
  static GameGetPlayerProgressPath = '/game-getPlayerProgress';
  /**
   * Gets the last attached user progress data, which can contain custom game-specific data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gameGetPlayerProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameGetPlayerProgress$Response(params, context) {
    return gameGetPlayerProgress(this.http, this.rootUrl, params, context);
  }
  /**
   * Gets the last attached user progress data, which can contain custom game-specific data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gameGetPlayerProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gameGetPlayerProgress(params, context) {
    return this.gameGetPlayerProgress$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function GameService_Factory(t) {
    return new (t || GameService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: GameService,
    factory: GameService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GameService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function matchmakingCreateMatch(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingCreateMatch.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingCreateMatch.PATH = '/matchmaking-createMatch';

/* tslint:disable */
/* eslint-disable */
function matchmakingDeleteAllMatchesForApp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingDeleteAllMatchesForApp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingDeleteAllMatchesForApp.PATH = '/matchmaking-deleteAllMatchesForApp';

/* tslint:disable */
/* eslint-disable */
function matchmakingFinishMatch(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingFinishMatch.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingFinishMatch.PATH = '/matchmaking-finishMatch';

/* tslint:disable */
/* eslint-disable */
function matchmakingGetFinishedMatchById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingGetFinishedMatchById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingGetFinishedMatchById.PATH = '/matchmaking-getFinishedMatchById';

/* tslint:disable */
/* eslint-disable */
function matchmakingGetFinishedMatches(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingGetFinishedMatches.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingGetFinishedMatches.PATH = '/matchmaking-getFinishedMatches';

/* tslint:disable */
/* eslint-disable */
function matchmakingGetJoinOpenMatches(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingGetJoinOpenMatches.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingGetJoinOpenMatches.PATH = '/matchmaking-getJoinOpenMatches';

/* tslint:disable */
/* eslint-disable */
function matchmakingGetVoteOpenMatches(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingGetVoteOpenMatches.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingGetVoteOpenMatches.PATH = '/matchmaking-getVoteOpenMatches';

/* tslint:disable */
/* eslint-disable */
function matchmakingParticipateInMatch(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingParticipateInMatch.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingParticipateInMatch.PATH = '/matchmaking-participateInMatch';

/* tslint:disable */
/* eslint-disable */
function matchmakingStartMatch(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingStartMatch.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingStartMatch.PATH = '/matchmaking-startMatch';

/* tslint:disable */
/* eslint-disable */
function matchmakingSubmitMatchScore(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingSubmitMatchScore.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingSubmitMatchScore.PATH = '/matchmaking-submitMatchScore';

/* tslint:disable */
/* eslint-disable */
function matchmakingVoteForMatch(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, matchmakingVoteForMatch.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
matchmakingVoteForMatch.PATH = '/matchmaking-voteForMatch';

/**
 * Matchmaking endpoints. Working with matches, getting match data, creating matches for two and more players, etc.
 */
class MatchmakingService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `matchmakingCreateMatch()` */
  static MatchmakingCreateMatchPath = '/matchmaking-createMatch';
  /**
   * Create a new match with specific configurations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingCreateMatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingCreateMatch$Response(params, context) {
    return matchmakingCreateMatch(this.http, this.rootUrl, params, context);
  }
  /**
   * Create a new match with specific configurations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingCreateMatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingCreateMatch(params, context) {
    return this.matchmakingCreateMatch$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingDeleteAllMatchesForApp()` */
  static MatchmakingDeleteAllMatchesForAppPath = '/matchmaking-deleteAllMatchesForApp';
  /**
   * Delete all matches for a specific application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingDeleteAllMatchesForApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingDeleteAllMatchesForApp$Response(params, context) {
    return matchmakingDeleteAllMatchesForApp(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete all matches for a specific application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingDeleteAllMatchesForApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingDeleteAllMatchesForApp(params, context) {
    return this.matchmakingDeleteAllMatchesForApp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingGetJoinOpenMatches()` */
  static MatchmakingGetJoinOpenMatchesPath = '/matchmaking-getJoinOpenMatches';
  /**
   * Get joinable open matches.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingGetJoinOpenMatches()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetJoinOpenMatches$Response(params, context) {
    return matchmakingGetJoinOpenMatches(this.http, this.rootUrl, params, context);
  }
  /**
   * Get joinable open matches.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingGetJoinOpenMatches$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetJoinOpenMatches(params, context) {
    return this.matchmakingGetJoinOpenMatches$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingGetVoteOpenMatches()` */
  static MatchmakingGetVoteOpenMatchesPath = '/matchmaking-getVoteOpenMatches';
  /**
   * Get open matches available for voting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingGetVoteOpenMatches()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetVoteOpenMatches$Response(params, context) {
    return matchmakingGetVoteOpenMatches(this.http, this.rootUrl, params, context);
  }
  /**
   * Get open matches available for voting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingGetVoteOpenMatches$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetVoteOpenMatches(params, context) {
    return this.matchmakingGetVoteOpenMatches$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingGetFinishedMatches()` */
  static MatchmakingGetFinishedMatchesPath = '/matchmaking-getFinishedMatches';
  /**
   * Get finished matches for a specific user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingGetFinishedMatches()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetFinishedMatches$Response(params, context) {
    return matchmakingGetFinishedMatches(this.http, this.rootUrl, params, context);
  }
  /**
   * Get finished matches for a specific user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingGetFinishedMatches$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetFinishedMatches(params, context) {
    return this.matchmakingGetFinishedMatches$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingGetFinishedMatchById()` */
  static MatchmakingGetFinishedMatchByIdPath = '/matchmaking-getFinishedMatchById';
  /**
   * Get a finished match by its ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingGetFinishedMatchById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetFinishedMatchById$Response(params, context) {
    return matchmakingGetFinishedMatchById(this.http, this.rootUrl, params, context);
  }
  /**
   * Get a finished match by its ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingGetFinishedMatchById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingGetFinishedMatchById(params, context) {
    return this.matchmakingGetFinishedMatchById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingParticipateInMatch()` */
  static MatchmakingParticipateInMatchPath = '/matchmaking-participateInMatch';
  /**
   * Participate in a match.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingParticipateInMatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingParticipateInMatch$Response(params, context) {
    return matchmakingParticipateInMatch(this.http, this.rootUrl, params, context);
  }
  /**
   * Participate in a match.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingParticipateInMatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingParticipateInMatch(params, context) {
    return this.matchmakingParticipateInMatch$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingStartMatch()` */
  static MatchmakingStartMatchPath = '/matchmaking-startMatch';
  /**
   * Start a match.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingStartMatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingStartMatch$Response(params, context) {
    return matchmakingStartMatch(this.http, this.rootUrl, params, context);
  }
  /**
   * Start a match.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingStartMatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingStartMatch(params, context) {
    return this.matchmakingStartMatch$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingVoteForMatch()` */
  static MatchmakingVoteForMatchPath = '/matchmaking-voteForMatch';
  /**
   * Vote for a participant in a match.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingVoteForMatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingVoteForMatch$Response(params, context) {
    return matchmakingVoteForMatch(this.http, this.rootUrl, params, context);
  }
  /**
   * Vote for a participant in a match.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingVoteForMatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingVoteForMatch(params, context) {
    return this.matchmakingVoteForMatch$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingSubmitMatchScore()` */
  static MatchmakingSubmitMatchScorePath = '/matchmaking-submitMatchScore';
  /**
   * Submit score for a match.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingSubmitMatchScore()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingSubmitMatchScore$Response(params, context) {
    return matchmakingSubmitMatchScore(this.http, this.rootUrl, params, context);
  }
  /**
   * Submit score for a match.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingSubmitMatchScore$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingSubmitMatchScore(params, context) {
    return this.matchmakingSubmitMatchScore$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `matchmakingFinishMatch()` */
  static MatchmakingFinishMatchPath = '/matchmaking-finishMatch';
  /**
   * Finish a match.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchmakingFinishMatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingFinishMatch$Response(params, context) {
    return matchmakingFinishMatch(this.http, this.rootUrl, params, context);
  }
  /**
   * Finish a match.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchmakingFinishMatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  matchmakingFinishMatch(params, context) {
    return this.matchmakingFinishMatch$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function MatchmakingService_Factory(t) {
    return new (t || MatchmakingService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MatchmakingService,
    factory: MatchmakingService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchmakingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function gamePassAdd(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassAdd.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassAdd.PATH = '/gamePass-add';

/* tslint:disable */
/* eslint-disable */
function gamePassAddQuantityForUser(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassAddQuantityForUser.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassAddQuantityForUser.PATH = '/gamePass-addQuantityForUser';

/* tslint:disable */
/* eslint-disable */
function gamePassAddToUser(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassAddToUser.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassAddToUser.PATH = '/gamePass-addToUser';

/* tslint:disable */
/* eslint-disable */
function gamePassDelete(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassDelete.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassDelete.PATH = '/gamePass-delete';

/* tslint:disable */
/* eslint-disable */
function gamePassDeleteFromUser(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassDeleteFromUser.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassDeleteFromUser.PATH = '/gamePass-deleteFromUser';

/* tslint:disable */
/* eslint-disable */
function gamePassGet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassGet.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassGet.PATH = '/gamePass-get';

/* tslint:disable */
/* eslint-disable */
function gamePassGetAllForUser(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassGetAllForUser.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassGetAllForUser.PATH = '/gamePass-getAllForUser';

/* tslint:disable */
/* eslint-disable */
function gamePassGetForApp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassGetForApp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassGetForApp.PATH = '/gamePass-getForApp';

/* tslint:disable */
/* eslint-disable */
function gamePassGetForUser(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, gamePassGetForUser.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
gamePassGetForUser.PATH = '/gamePass-getForUser';

/**
 * GamePass endpoints. Working with game passes, getting game pass data, etc.
 */
class GamePassService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `gamePassAdd()` */
  static GamePassAddPath = '/gamePass-add';
  /**
   * Add.
   *
   * Add a new game pass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassAdd$Response(params, context) {
    return gamePassAdd(this.http, this.rootUrl, params, context);
  }
  /**
   * Add.
   *
   * Add a new game pass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassAdd(params, context) {
    return this.gamePassAdd$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassDelete()` */
  static GamePassDeletePath = '/gamePass-delete';
  /**
   * Delete a game pass.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassDelete$Response(params, context) {
    return gamePassDelete(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a game pass.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassDelete(params, context) {
    return this.gamePassDelete$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassAddToUser()` */
  static GamePassAddToUserPath = '/gamePass-addToUser';
  /**
   * Add to User.
   *
   * Add a game pass to a user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassAddToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassAddToUser$Response(params, context) {
    return gamePassAddToUser(this.http, this.rootUrl, params, context);
  }
  /**
   * Add to User.
   *
   * Add a game pass to a user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassAddToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassAddToUser(params, context) {
    return this.gamePassAddToUser$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassDeleteFromUser()` */
  static GamePassDeleteFromUserPath = '/gamePass-deleteFromUser';
  /**
   * Delete a game pass from a user.
   *
   * This endpoint deletes all game passes, the quantity is ignored.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassDeleteFromUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassDeleteFromUser$Response(params, context) {
    return gamePassDeleteFromUser(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a game pass from a user.
   *
   * This endpoint deletes all game passes, the quantity is ignored.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassDeleteFromUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassDeleteFromUser(params, context) {
    return this.gamePassDeleteFromUser$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassAddQuantityForUser()` */
  static GamePassAddQuantityForUserPath = '/gamePass-addQuantityForUser';
  /**
   * Add quantity to a game pass for a user.
   *
   * The quantity can be negative to deduct game passes.
   * The minimum quantity is of user owned game passes is 0.
   * If you want to remove the game pass for a user, use the deleteFromUser endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassAddQuantityForUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassAddQuantityForUser$Response(params, context) {
    return gamePassAddQuantityForUser(this.http, this.rootUrl, params, context);
  }
  /**
   * Add quantity to a game pass for a user.
   *
   * The quantity can be negative to deduct game passes.
   * The minimum quantity is of user owned game passes is 0.
   * If you want to remove the game pass for a user, use the deleteFromUser endpoint.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassAddQuantityForUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassAddQuantityForUser(params, context) {
    return this.gamePassAddQuantityForUser$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassGet()` */
  static GamePassGetPath = '/gamePass-get';
  /**
   * Retrieve a game pass.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassGet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGet$Response(params, context) {
    return gamePassGet(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a game pass.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassGet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGet(params, context) {
    return this.gamePassGet$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassGetForApp()` */
  static GamePassGetForAppPath = '/gamePass-getForApp';
  /**
   * Retrieve game passes for a specific app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassGetForApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGetForApp$Response(params, context) {
    return gamePassGetForApp(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve game passes for a specific app.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassGetForApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGetForApp(params, context) {
    return this.gamePassGetForApp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassGetForUser()` */
  static GamePassGetForUserPath = '/gamePass-getForUser';
  /**
   * Retrieve game passes for a specific user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassGetForUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGetForUser$Response(params, context) {
    return gamePassGetForUser(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve game passes for a specific user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassGetForUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGetForUser(params, context) {
    return this.gamePassGetForUser$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `gamePassGetAllForUser()` */
  static GamePassGetAllForUserPath = '/gamePass-getAllForUser';
  /**
   * Retrieve all game passes for a specific user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gamePassGetAllForUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGetAllForUser$Response(params, context) {
    return gamePassGetAllForUser(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve all game passes for a specific user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gamePassGetAllForUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  gamePassGetAllForUser(params, context) {
    return this.gamePassGetAllForUser$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function GamePassService_Factory(t) {
    return new (t || GamePassService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: GamePassService,
    factory: GamePassService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GamePassService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function dappGetAllDevelopers(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetAllDevelopers.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetAllDevelopers.PATH = '/dapp-getAllDevelopers';

/* tslint:disable */
/* eslint-disable */
function dappGetCategories(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetCategories.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetCategories.PATH = '/dapp-getCategories';

/* tslint:disable */
/* eslint-disable */
function dappGetDAppById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetDAppById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetDAppById.PATH = '/dapp-getDAppById';

/* tslint:disable */
/* eslint-disable */
function dappGetDApps(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetDApps.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetDApps.PATH = '/dapp-getDApps';

/* tslint:disable */
/* eslint-disable */
function dappGetDAppsByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetDAppsByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetDAppsByIds.PATH = '/dapp-getDAppsByIds';

/* tslint:disable */
/* eslint-disable */
function dappGetDeveloperById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetDeveloperById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetDeveloperById.PATH = '/dapp-getDeveloperById';

/* tslint:disable */
/* eslint-disable */
function dappGetHomePageData(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetHomePageData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetHomePageData.PATH = '/dapp-getHomePageData';

/* tslint:disable */
/* eslint-disable */
function dappGetUpcomingEvents(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetUpcomingEvents.PATH, 'post');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetUpcomingEvents.PATH = '/dapp-getUpcomingEvents';
class DAppService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `dappGetDeveloperById()` */
  static DappGetDeveloperByIdPath = '/dapp-getDeveloperById';
  /**
   * Retrieve a developer by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetDeveloperById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDeveloperById$Response(params, context) {
    return dappGetDeveloperById(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a developer by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetDeveloperById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDeveloperById(params, context) {
    return this.dappGetDeveloperById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetAllDevelopers()` */
  static DappGetAllDevelopersPath = '/dapp-getAllDevelopers';
  /**
   * Retrieve all developers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetAllDevelopers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetAllDevelopers$Response(params, context) {
    return dappGetAllDevelopers(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve all developers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetAllDevelopers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetAllDevelopers(params, context) {
    return this.dappGetAllDevelopers$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetDAppById()` */
  static DappGetDAppByIdPath = '/dapp-getDAppById';
  /**
   * Retrieve a dapp by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetDAppById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDAppById$Response(params, context) {
    return dappGetDAppById(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a dapp by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetDAppById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDAppById(params, context) {
    return this.dappGetDAppById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetDAppsByIds()` */
  static DappGetDAppsByIdsPath = '/dapp-getDAppsByIds';
  /**
   * Retrieve dapps by IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetDAppsByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDAppsByIds$Response(params, context) {
    return dappGetDAppsByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve dapps by IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetDAppsByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDAppsByIds(params, context) {
    return this.dappGetDAppsByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetDApps()` */
  static DappGetDAppsPath = '/dapp-getDApps';
  /**
   * Retrieve dapps.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetDApps()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDApps$Response(params, context) {
    return dappGetDApps(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve dapps.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetDApps$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDApps(params, context) {
    return this.dappGetDApps$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetUpcomingEvents()` */
  static DappGetUpcomingEventsPath = '/dapp-getUpcomingEvents';
  /**
   * Retrieve upcoming events.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetUpcomingEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  dappGetUpcomingEvents$Response(params, context) {
    return dappGetUpcomingEvents(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve upcoming events.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetUpcomingEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dappGetUpcomingEvents(params, context) {
    return this.dappGetUpcomingEvents$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetCategories()` */
  static DappGetCategoriesPath = '/dapp-getCategories';
  /**
   * Retrieve categories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetCategories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetCategories$Response(params, context) {
    return dappGetCategories(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve categories.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetCategories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetCategories(params, context) {
    return this.dappGetCategories$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetHomePageData()` */
  static DappGetHomePageDataPath = '/dapp-getHomePageData';
  /**
   * Retrieve data for the home page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetHomePageData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetHomePageData$Response(params, context) {
    return dappGetHomePageData(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve data for the home page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetHomePageData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetHomePageData(params, context) {
    return this.dappGetHomePageData$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function DAppService_Factory(t) {
    return new (t || DAppService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DAppService,
    factory: DAppService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DAppService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function dappAddCategory(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappAddCategory.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappAddCategory.PATH = '/dappAdmin-addCategory';

/* tslint:disable */
/* eslint-disable */
function dappAddDeveloper(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappAddDeveloper.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappAddDeveloper.PATH = '/dappAdmin-addDeveloper';

/* tslint:disable */
/* eslint-disable */
function dappAddDeveloperDApp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappAddDeveloperDApp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappAddDeveloperDApp.PATH = '/dappAdmin-addDeveloperDApp';

/* tslint:disable */
/* eslint-disable */
function dappApproveDApp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappApproveDApp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappApproveDApp.PATH = '/dappAdmin-approveDApp';

/* tslint:disable */
/* eslint-disable */
function dappCheckApprovalStatus(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappCheckApprovalStatus.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappCheckApprovalStatus.PATH = '/dappAdmin-checkApprovalStatus';

/* tslint:disable */
/* eslint-disable */
function dappDeleteBinary(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappDeleteBinary.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappDeleteBinary.PATH = '/dappAdmin-deleteBinary';

/* tslint:disable */
/* eslint-disable */
function dappDeleteCategory(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappDeleteCategory.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappDeleteCategory.PATH = '/dappAdmin-deleteCategory';

/* tslint:disable */
/* eslint-disable */
function dappDeleteDApp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappDeleteDApp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappDeleteDApp.PATH = '/dappAdmin-deleteDApp';

/* tslint:disable */
/* eslint-disable */
function dappDeleteDeveloper(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappDeleteDeveloper.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappDeleteDeveloper.PATH = '/dappAdmin-deleteDeveloper';

/* tslint:disable */
/* eslint-disable */
function dappGetDAppApproveRequests(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappGetDAppApproveRequests.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappGetDAppApproveRequests.PATH = '/dappAdmin-getDAppApproveRequests';

/* tslint:disable */
/* eslint-disable */
function dappRejectDApp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappRejectDApp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappRejectDApp.PATH = '/dappAdmin-rejectDApp';

/* tslint:disable */
/* eslint-disable */
function dappRequestDAppForListApproval(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappRequestDAppForListApproval.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappRequestDAppForListApproval.PATH = '/dappAdmin-requestDAppForListApproval';

/* tslint:disable */
/* eslint-disable */
function dappUpdateDApp(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappUpdateDApp.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappUpdateDApp.PATH = '/dappAdmin-updateDApp';

/* tslint:disable */
/* eslint-disable */
function dappUpdateDeveloper(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappUpdateDeveloper.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappUpdateDeveloper.PATH = '/dappAdmin-updateDeveloper';

/* tslint:disable */
/* eslint-disable */
function dappUpdateUpcomingEvents(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappUpdateUpcomingEvents.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappUpdateUpcomingEvents.PATH = '/dappAdmin-updateUpcomingEvents';

/* tslint:disable */
/* eslint-disable */
function dappUploadBinary(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, dappUploadBinary.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
dappUploadBinary.PATH = '/dappAdmin-uploadBinary';
class DAppAdminService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `dappAddDeveloper()` */
  static DappAddDeveloperPath = '/dappAdmin-addDeveloper';
  /**
   * Add a new developer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappAddDeveloper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappAddDeveloper$Response(params, context) {
    return dappAddDeveloper(this.http, this.rootUrl, params, context);
  }
  /**
   * Add a new developer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappAddDeveloper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappAddDeveloper(params, context) {
    return this.dappAddDeveloper$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappUpdateDeveloper()` */
  static DappUpdateDeveloperPath = '/dappAdmin-updateDeveloper';
  /**
   * Update an existing developer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappUpdateDeveloper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUpdateDeveloper$Response(params, context) {
    return dappUpdateDeveloper(this.http, this.rootUrl, params, context);
  }
  /**
   * Update an existing developer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappUpdateDeveloper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUpdateDeveloper(params, context) {
    return this.dappUpdateDeveloper$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappDeleteDeveloper()` */
  static DappDeleteDeveloperPath = '/dappAdmin-deleteDeveloper';
  /**
   * Delete a developer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappDeleteDeveloper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteDeveloper$Response(params, context) {
    return dappDeleteDeveloper(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a developer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappDeleteDeveloper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteDeveloper(params, context) {
    return this.dappDeleteDeveloper$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappAddDeveloperDApp()` */
  static DappAddDeveloperDAppPath = '/dappAdmin-addDeveloperDApp';
  /**
   * Add a new dapp.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappAddDeveloperDApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappAddDeveloperDApp$Response(params, context) {
    return dappAddDeveloperDApp(this.http, this.rootUrl, params, context);
  }
  /**
   * Add a new dapp.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappAddDeveloperDApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappAddDeveloperDApp(params, context) {
    return this.dappAddDeveloperDApp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappUpdateDApp()` */
  static DappUpdateDAppPath = '/dappAdmin-updateDApp';
  /**
   * Update an existing dapp.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappUpdateDApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUpdateDApp$Response(params, context) {
    return dappUpdateDApp(this.http, this.rootUrl, params, context);
  }
  /**
   * Update an existing dapp.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappUpdateDApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUpdateDApp(params, context) {
    return this.dappUpdateDApp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappDeleteDApp()` */
  static DappDeleteDAppPath = '/dappAdmin-deleteDApp';
  /**
   * Delete a dapp.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappDeleteDApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteDApp$Response(params, context) {
    return dappDeleteDApp(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a dapp.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappDeleteDApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteDApp(params, context) {
    return this.dappDeleteDApp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappRequestDAppForListApproval()` */
  static DappRequestDAppForListApprovalPath = '/dappAdmin-requestDAppForListApproval';
  /**
   * Request to list a dapp in the dapp store.
   *
   * This api requires elevated user rights. The request will be sent to the admin for approval.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappRequestDAppForListApproval()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappRequestDAppForListApproval$Response(params, context) {
    return dappRequestDAppForListApproval(this.http, this.rootUrl, params, context);
  }
  /**
   * Request to list a dapp in the dapp store.
   *
   * This api requires elevated user rights. The request will be sent to the admin for approval.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappRequestDAppForListApproval$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappRequestDAppForListApproval(params, context) {
    return this.dappRequestDAppForListApproval$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappCheckApprovalStatus()` */
  static DappCheckApprovalStatusPath = '/dappAdmin-checkApprovalStatus';
  /**
   * Check the approval status of a dapp listing request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappCheckApprovalStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappCheckApprovalStatus$Response(params, context) {
    return dappCheckApprovalStatus(this.http, this.rootUrl, params, context);
  }
  /**
   * Check the approval status of a dapp listing request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappCheckApprovalStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappCheckApprovalStatus(params, context) {
    return this.dappCheckApprovalStatus$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappApproveDApp()` */
  static DappApproveDAppPath = '/dappAdmin-approveDApp';
  /**
   * Approve a dapp listing request.
   *
   * This api requires elevated user rights. Marks a DApp as listed in the DApp store.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappApproveDApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappApproveDApp$Response(params, context) {
    return dappApproveDApp(this.http, this.rootUrl, params, context);
  }
  /**
   * Approve a dapp listing request.
   *
   * This api requires elevated user rights. Marks a DApp as listed in the DApp store.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappApproveDApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappApproveDApp(params, context) {
    return this.dappApproveDApp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappRejectDApp()` */
  static DappRejectDAppPath = '/dappAdmin-rejectDApp';
  /**
   * Reject a dapp listing request.
   *
   * This api requires elevated user rights. Marks a DApp as rejected in the DApp store.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappRejectDApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappRejectDApp$Response(params, context) {
    return dappRejectDApp(this.http, this.rootUrl, params, context);
  }
  /**
   * Reject a dapp listing request.
   *
   * This api requires elevated user rights. Marks a DApp as rejected in the DApp store.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappRejectDApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappRejectDApp(params, context) {
    return this.dappRejectDApp$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappGetDAppApproveRequests()` */
  static DappGetDAppApproveRequestsPath = '/dappAdmin-getDAppApproveRequests';
  /**
   * Retrieve dapp listing requests.
   *
   * This api requires elevated user rights. Retrieves all dapp listing requests.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappGetDAppApproveRequests()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDAppApproveRequests$Response(params, context) {
    return dappGetDAppApproveRequests(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve dapp listing requests.
   *
   * This api requires elevated user rights. Retrieves all dapp listing requests.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappGetDAppApproveRequests$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappGetDAppApproveRequests(params, context) {
    return this.dappGetDAppApproveRequests$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappAddCategory()` */
  static DappAddCategoryPath = '/dappAdmin-addCategory';
  /**
   * Add a new category.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappAddCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappAddCategory$Response(params, context) {
    return dappAddCategory(this.http, this.rootUrl, params, context);
  }
  /**
   * Add a new category.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappAddCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappAddCategory(params, context) {
    return this.dappAddCategory$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappDeleteCategory()` */
  static DappDeleteCategoryPath = '/dappAdmin-deleteCategory';
  /**
   * Delete a category.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappDeleteCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteCategory$Response(params, context) {
    return dappDeleteCategory(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a category.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappDeleteCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteCategory(params, context) {
    return this.dappDeleteCategory$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappUpdateUpcomingEvents()` */
  static DappUpdateUpcomingEventsPath = '/dappAdmin-updateUpcomingEvents';
  /**
   * Update upcoming events.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappUpdateUpcomingEvents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUpdateUpcomingEvents$Response(params, context) {
    return dappUpdateUpcomingEvents(this.http, this.rootUrl, params, context);
  }
  /**
   * Update upcoming events.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappUpdateUpcomingEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUpdateUpcomingEvents(params, context) {
    return this.dappUpdateUpcomingEvents$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappUploadBinary()` */
  static DappUploadBinaryPath = '/dappAdmin-uploadBinary';
  /**
   * Upload a binary. This api requires elevated user rights.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappUploadBinary()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUploadBinary$Response(params, context) {
    return dappUploadBinary(this.http, this.rootUrl, params, context);
  }
  /**
   * Upload a binary. This api requires elevated user rights.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappUploadBinary$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappUploadBinary(params, context) {
    return this.dappUploadBinary$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `dappDeleteBinary()` */
  static DappDeleteBinaryPath = '/dappAdmin-deleteBinary';
  /**
   * Delete a binary. This api requires elevated user rights.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dappDeleteBinary()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteBinary$Response(params, context) {
    return dappDeleteBinary(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a binary. This api requires elevated user rights.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dappDeleteBinary$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dappDeleteBinary(params, context) {
    return this.dappDeleteBinary$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function DAppAdminService_Factory(t) {
    return new (t || DAppAdminService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DAppAdminService,
    factory: DAppAdminService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DAppAdminService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function betaTestV2Add(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2Add.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2Add.PATH = '/betaTestV2-add';

/* tslint:disable */
/* eslint-disable */
function betaTestV2AddReport(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2AddReport.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2AddReport.PATH = '/betaTestV2-addReport';

/* tslint:disable */
/* eslint-disable */
function betaTestV2Delete(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2Delete.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2Delete.PATH = '/betaTestV2-delete';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetByAppId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetByAppId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetByAppId.PATH = '/betaTestV2-getByAppId';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetById.PATH = '/betaTestV2-getById';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetByIds.PATH = '/betaTestV2-getByIds';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetByTags.PATH = '/betaTestV2-getByTags';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetReports(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetReports.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetReports.PATH = '/betaTestV2-getReports';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetSubscribedUsers(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetSubscribedUsers.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetSubscribedUsers.PATH = '/betaTestV2-getSubscribedUsers';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetTesterProfile(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetTesterProfile.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetTesterProfile.PATH = '/betaTestV2-getTesterProfile';

/* tslint:disable */
/* eslint-disable */
function betaTestV2GetTesterProfiles(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2GetTesterProfiles.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2GetTesterProfiles.PATH = '/betaTestV2-getTesterProfiles';

/* tslint:disable */
/* eslint-disable */
function betaTestV2Join(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2Join.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2Join.PATH = '/betaTestV2-join';

/* tslint:disable */
/* eslint-disable */
function betaTestV2Leave(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2Leave.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2Leave.PATH = '/betaTestV2-leave';

/* tslint:disable */
/* eslint-disable */
function betaTestV2Subscribe(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2Subscribe.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2Subscribe.PATH = '/betaTestV2-subscribe';

/* tslint:disable */
/* eslint-disable */
function betaTestV2Unsubscribe(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2Unsubscribe.PATH, 'post');
  if (params) {}
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2Unsubscribe.PATH = '/betaTestV2-unsubscribe';

/* tslint:disable */
/* eslint-disable */
function betaTestV2Update(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2Update.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2Update.PATH = '/betaTestV2-update';

/* tslint:disable */
/* eslint-disable */
function betaTestV2UpdateAllExistingItems(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2UpdateAllExistingItems.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
betaTestV2UpdateAllExistingItems.PATH = '/betaTestV2-updateAllExistingItems';

/* tslint:disable */
/* eslint-disable */
function betaTestV2UpdateTesterProfile(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, betaTestV2UpdateTesterProfile.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
betaTestV2UpdateTesterProfile.PATH = '/betaTestV2-updateTesterProfile';
class BetaTestV2Service extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `betaTestV2Add()` */
  static BetaTestV2AddPath = '/betaTestV2-add';
  /**
   * Add a new beta test.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2Add()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Add$Response(params, context) {
    return betaTestV2Add(this.http, this.rootUrl, params, context);
  }
  /**
   * Add a new beta test.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2Add$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Add(params, context) {
    return this.betaTestV2Add$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2Update()` */
  static BetaTestV2UpdatePath = '/betaTestV2-update';
  /**
   * Update an existing beta test.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2Update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Update$Response(params, context) {
    return betaTestV2Update(this.http, this.rootUrl, params, context);
  }
  /**
   * Update an existing beta test.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2Update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Update(params, context) {
    return this.betaTestV2Update$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2Delete()` */
  static BetaTestV2DeletePath = '/betaTestV2-delete';
  /**
   * Delete a beta test.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2Delete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Delete$Response(params, context) {
    return betaTestV2Delete(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete a beta test.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2Delete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Delete(params, context) {
    return this.betaTestV2Delete$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetById()` */
  static BetaTestV2GetByIdPath = '/betaTestV2-getById';
  /**
   * Retrieve a beta test by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetById$Response(params, context) {
    return betaTestV2GetById(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a beta test by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetById(params, context) {
    return this.betaTestV2GetById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetByIds()` */
  static BetaTestV2GetByIdsPath = '/betaTestV2-getByIds';
  /**
   * Retrieve beta tests by IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetByIds$Response(params, context) {
    return betaTestV2GetByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve beta tests by IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetByIds(params, context) {
    return this.betaTestV2GetByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetByAppId()` */
  static BetaTestV2GetByAppIdPath = '/betaTestV2-getByAppId';
  /**
   * Retrieve beta tests by app ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetByAppId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetByAppId$Response(params, context) {
    return betaTestV2GetByAppId(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve beta tests by app ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetByAppId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetByAppId(params, context) {
    return this.betaTestV2GetByAppId$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetByTags()` */
  static BetaTestV2GetByTagsPath = '/betaTestV2-getByTags';
  /**
   * Retrieve beta tests by tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetByTags$Response(params, context) {
    return betaTestV2GetByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve beta tests by tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetByTags(params, context) {
    return this.betaTestV2GetByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2Join()` */
  static BetaTestV2JoinPath = '/betaTestV2-join';
  /**
   * Join a beta test.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2Join()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Join$Response(params, context) {
    return betaTestV2Join(this.http, this.rootUrl, params, context);
  }
  /**
   * Join a beta test.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2Join$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Join(params, context) {
    return this.betaTestV2Join$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2Leave()` */
  static BetaTestV2LeavePath = '/betaTestV2-leave';
  /**
   * Leave a beta test.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2Leave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Leave$Response(params, context) {
    return betaTestV2Leave(this.http, this.rootUrl, params, context);
  }
  /**
   * Leave a beta test.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2Leave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Leave(params, context) {
    return this.betaTestV2Leave$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2UpdateTesterProfile()` */
  static BetaTestV2UpdateTesterProfilePath = '/betaTestV2-updateTesterProfile';
  /**
   * Update a tester profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2UpdateTesterProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2UpdateTesterProfile$Response(params, context) {
    return betaTestV2UpdateTesterProfile(this.http, this.rootUrl, params, context);
  }
  /**
   * Update a tester profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2UpdateTesterProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2UpdateTesterProfile(params, context) {
    return this.betaTestV2UpdateTesterProfile$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetTesterProfile()` */
  static BetaTestV2GetTesterProfilePath = '/betaTestV2-getTesterProfile';
  /**
   * Retrieve a tester profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetTesterProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetTesterProfile$Response(params, context) {
    return betaTestV2GetTesterProfile(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a tester profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetTesterProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetTesterProfile(params, context) {
    return this.betaTestV2GetTesterProfile$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetTesterProfiles()` */
  static BetaTestV2GetTesterProfilesPath = '/betaTestV2-getTesterProfiles';
  /**
   * Retrieve tester profiles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetTesterProfiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetTesterProfiles$Response(params, context) {
    return betaTestV2GetTesterProfiles(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve tester profiles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetTesterProfiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetTesterProfiles(params, context) {
    return this.betaTestV2GetTesterProfiles$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2AddReport()` */
  static BetaTestV2AddReportPath = '/betaTestV2-addReport';
  /**
   * Add a new report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2AddReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2AddReport$Response(params, context) {
    return betaTestV2AddReport(this.http, this.rootUrl, params, context);
  }
  /**
   * Add a new report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2AddReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2AddReport(params, context) {
    return this.betaTestV2AddReport$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetReports()` */
  static BetaTestV2GetReportsPath = '/betaTestV2-getReports';
  /**
   * Retrieve reports.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetReports()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetReports$Response(params, context) {
    return betaTestV2GetReports(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve reports.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetReports$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetReports(params, context) {
    return this.betaTestV2GetReports$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2Subscribe()` */
  static BetaTestV2SubscribePath = '/betaTestV2-subscribe';
  /**
   * Subscribe or update preferences to a beta tests for email notifications when new beta tests are available.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2Subscribe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Subscribe$Response(params, context) {
    return betaTestV2Subscribe(this.http, this.rootUrl, params, context);
  }
  /**
   * Subscribe or update preferences to a beta tests for email notifications when new beta tests are available.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2Subscribe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2Subscribe(params, context) {
    return this.betaTestV2Subscribe$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2Unsubscribe()` */
  static BetaTestV2UnsubscribePath = '/betaTestV2-unsubscribe';
  /**
   * Unsubscribe from beta tests for email notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2Unsubscribe()` instead.
   *
   * This method doesn't expect any request body.
   */
  betaTestV2Unsubscribe$Response(params, context) {
    return betaTestV2Unsubscribe(this.http, this.rootUrl, params, context);
  }
  /**
   * Unsubscribe from beta tests for email notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2Unsubscribe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  betaTestV2Unsubscribe(params, context) {
    return this.betaTestV2Unsubscribe$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2GetSubscribedUsers()` */
  static BetaTestV2GetSubscribedUsersPath = '/betaTestV2-getSubscribedUsers';
  /**
   * Retrieve subscribed user emails.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2GetSubscribedUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetSubscribedUsers$Response(params, context) {
    return betaTestV2GetSubscribedUsers(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve subscribed user emails.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2GetSubscribedUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2GetSubscribedUsers(params, context) {
    return this.betaTestV2GetSubscribedUsers$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `betaTestV2UpdateAllExistingItems()` */
  static BetaTestV2UpdateAllExistingItemsPath = '/betaTestV2-updateAllExistingItems';
  /**
   * Update all existing items.
   *
   * This api requires elevated user rights. Updates all existing beta test items.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `betaTestV2UpdateAllExistingItems()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2UpdateAllExistingItems$Response(params, context) {
    return betaTestV2UpdateAllExistingItems(this.http, this.rootUrl, params, context);
  }
  /**
   * Update all existing items.
   *
   * This api requires elevated user rights. Updates all existing beta test items.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `betaTestV2UpdateAllExistingItems$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  betaTestV2UpdateAllExistingItems(params, context) {
    return this.betaTestV2UpdateAllExistingItems$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BetaTestV2Service_Factory(t) {
    return new (t || BetaTestV2Service)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BetaTestV2Service,
    factory: BetaTestV2Service.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BetaTestV2Service, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function socialCheckIfUserFollowsTwitterUser(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialCheckIfUserFollowsTwitterUser.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialCheckIfUserFollowsTwitterUser.PATH = '/social-checkIfUserFollowsTwitterUser';

/* tslint:disable */
/* eslint-disable */
function socialCheckIfUserLikedTweet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialCheckIfUserLikedTweet.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialCheckIfUserLikedTweet.PATH = '/social-checkIfUserLikedTweet';

/* tslint:disable */
/* eslint-disable */
function socialCheckIfUserRepliedToTweet(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialCheckIfUserRepliedToTweet.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialCheckIfUserRepliedToTweet.PATH = '/social-checkIfUserRepliedToTweet';

/* tslint:disable */
/* eslint-disable */
function socialCheckIfUserRetweeted(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialCheckIfUserRetweeted.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialCheckIfUserRetweeted.PATH = '/social-checkIfUserRetweeted';

/* tslint:disable */
/* eslint-disable */
function socialGenerateTwitterOAuthUrl(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialGenerateTwitterOAuthUrl.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialGenerateTwitterOAuthUrl.PATH = '/social-generateTwitterOAuthUrl';

/* tslint:disable */
/* eslint-disable */
function socialHasRoleOnDiscordServer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialHasRoleOnDiscordServer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialHasRoleOnDiscordServer.PATH = '/social-hasRoleOnDiscordServer';

/* tslint:disable */
/* eslint-disable */
function socialIsUserConnectedTo(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialIsUserConnectedTo.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialIsUserConnectedTo.PATH = '/social-isUserConnectedTo';

/* tslint:disable */
/* eslint-disable */
function socialIsUserOnDiscordServer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialIsUserOnDiscordServer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialIsUserOnDiscordServer.PATH = '/social-isUserOnDiscordServer';

/* tslint:disable */
/* eslint-disable */
function socialUpdateDiscordUserInfo(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialUpdateDiscordUserInfo.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialUpdateDiscordUserInfo.PATH = '/social-updateDiscordUserInfo';

/* tslint:disable */
/* eslint-disable */
function socialUpdateTwitterUserInfo(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, socialUpdateTwitterUserInfo.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
socialUpdateTwitterUserInfo.PATH = '/social-updateTwitterUserInfo';
class SocialService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `socialIsUserConnectedTo()` */
  static SocialIsUserConnectedToPath = '/social-isUserConnectedTo';
  /**
   * Check if user is connected to a social platform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialIsUserConnectedTo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialIsUserConnectedTo$Response(params, context) {
    return socialIsUserConnectedTo(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if user is connected to a social platform.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialIsUserConnectedTo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialIsUserConnectedTo(params, context) {
    return this.socialIsUserConnectedTo$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialUpdateDiscordUserInfo()` */
  static SocialUpdateDiscordUserInfoPath = '/social-updateDiscordUserInfo';
  /**
   * Update Discord user info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialUpdateDiscordUserInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialUpdateDiscordUserInfo$Response(params, context) {
    return socialUpdateDiscordUserInfo(this.http, this.rootUrl, params, context);
  }
  /**
   * Update Discord user info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialUpdateDiscordUserInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialUpdateDiscordUserInfo(params, context) {
    return this.socialUpdateDiscordUserInfo$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialIsUserOnDiscordServer()` */
  static SocialIsUserOnDiscordServerPath = '/social-isUserOnDiscordServer';
  /**
   * Check if user is on Discord server.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialIsUserOnDiscordServer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialIsUserOnDiscordServer$Response(params, context) {
    return socialIsUserOnDiscordServer(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if user is on Discord server.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialIsUserOnDiscordServer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialIsUserOnDiscordServer(params, context) {
    return this.socialIsUserOnDiscordServer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialHasRoleOnDiscordServer()` */
  static SocialHasRoleOnDiscordServerPath = '/social-hasRoleOnDiscordServer';
  /**
   * Check if user has a role on Discord server.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialHasRoleOnDiscordServer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialHasRoleOnDiscordServer$Response(params, context) {
    return socialHasRoleOnDiscordServer(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if user has a role on Discord server.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialHasRoleOnDiscordServer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialHasRoleOnDiscordServer(params, context) {
    return this.socialHasRoleOnDiscordServer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialGenerateTwitterOAuthUrl()` */
  static SocialGenerateTwitterOAuthUrlPath = '/social-generateTwitterOAuthUrl';
  /**
   * Generate Twitter OAuth URL.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialGenerateTwitterOAuthUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialGenerateTwitterOAuthUrl$Response(params, context) {
    return socialGenerateTwitterOAuthUrl(this.http, this.rootUrl, params, context);
  }
  /**
   * Generate Twitter OAuth URL.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialGenerateTwitterOAuthUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialGenerateTwitterOAuthUrl(params, context) {
    return this.socialGenerateTwitterOAuthUrl$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialUpdateTwitterUserInfo()` */
  static SocialUpdateTwitterUserInfoPath = '/social-updateTwitterUserInfo';
  /**
   * Update Twitter user info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialUpdateTwitterUserInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialUpdateTwitterUserInfo$Response(params, context) {
    return socialUpdateTwitterUserInfo(this.http, this.rootUrl, params, context);
  }
  /**
   * Update Twitter user info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialUpdateTwitterUserInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialUpdateTwitterUserInfo(params, context) {
    return this.socialUpdateTwitterUserInfo$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialCheckIfUserFollowsTwitterUser()` */
  static SocialCheckIfUserFollowsTwitterUserPath = '/social-checkIfUserFollowsTwitterUser';
  /**
   * Check if user follows a Twitter user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialCheckIfUserFollowsTwitterUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserFollowsTwitterUser$Response(params, context) {
    return socialCheckIfUserFollowsTwitterUser(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if user follows a Twitter user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialCheckIfUserFollowsTwitterUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserFollowsTwitterUser(params, context) {
    return this.socialCheckIfUserFollowsTwitterUser$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialCheckIfUserRetweeted()` */
  static SocialCheckIfUserRetweetedPath = '/social-checkIfUserRetweeted';
  /**
   * Check if user retweeted a tweet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialCheckIfUserRetweeted()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserRetweeted$Response(params, context) {
    return socialCheckIfUserRetweeted(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if user retweeted a tweet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialCheckIfUserRetweeted$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserRetweeted(params, context) {
    return this.socialCheckIfUserRetweeted$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialCheckIfUserLikedTweet()` */
  static SocialCheckIfUserLikedTweetPath = '/social-checkIfUserLikedTweet';
  /**
   * Check if user liked a tweet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialCheckIfUserLikedTweet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserLikedTweet$Response(params, context) {
    return socialCheckIfUserLikedTweet(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if user liked a tweet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialCheckIfUserLikedTweet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserLikedTweet(params, context) {
    return this.socialCheckIfUserLikedTweet$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `socialCheckIfUserRepliedToTweet()` */
  static SocialCheckIfUserRepliedToTweetPath = '/social-checkIfUserRepliedToTweet';
  /**
   * Check if user replied to a tweet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socialCheckIfUserRepliedToTweet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserRepliedToTweet$Response(params, context) {
    return socialCheckIfUserRepliedToTweet(this.http, this.rootUrl, params, context);
  }
  /**
   * Check if user replied to a tweet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socialCheckIfUserRepliedToTweet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  socialCheckIfUserRepliedToTweet(params, context) {
    return this.socialCheckIfUserRepliedToTweet$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function SocialService_Factory(t) {
    return new (t || SocialService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: SocialService,
    factory: SocialService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SocialService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function marketplaceBuyOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceBuyOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
marketplaceBuyOffer.PATH = '/marketplace-buyOffer';

/* tslint:disable */
/* eslint-disable */
function marketplaceCompleteBuyOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceCompleteBuyOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
marketplaceCompleteBuyOffer.PATH = '/marketplace-completeBuyOffer';

/* tslint:disable */
/* eslint-disable */
function marketplaceCreateOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceCreateOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
marketplaceCreateOffer.PATH = '/marketplace-createOffer';

/* tslint:disable */
/* eslint-disable */
function marketplaceDeleteOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceDeleteOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
marketplaceDeleteOffer.PATH = '/marketplace-deleteOffer';

/* tslint:disable */
/* eslint-disable */
function marketplaceGetOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceGetOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
marketplaceGetOffer.PATH = '/marketplace-getOffer';

/* tslint:disable */
/* eslint-disable */
function marketplaceGetOffers(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceGetOffers.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
marketplaceGetOffers.PATH = '/marketplace-getOffers';

/* tslint:disable */
/* eslint-disable */
function marketplaceUpdateAllItems(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceUpdateAllItems.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'text',
    accept: '*/*',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r.clone({
      body: undefined
    });
  }));
}
marketplaceUpdateAllItems.PATH = '/marketplace-updateAllItems';

/* tslint:disable */
/* eslint-disable */
function marketplaceUpdateOffer(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, marketplaceUpdateOffer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
marketplaceUpdateOffer.PATH = '/marketplace-updateOffer';
class MarketplaceService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `marketplaceCreateOffer()` */
  static MarketplaceCreateOfferPath = '/marketplace-createOffer';
  /**
   * Create an offer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceCreateOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceCreateOffer$Response(params, context) {
    return marketplaceCreateOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Create an offer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceCreateOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceCreateOffer(params, context) {
    return this.marketplaceCreateOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `marketplaceUpdateOffer()` */
  static MarketplaceUpdateOfferPath = '/marketplace-updateOffer';
  /**
   * Update an offer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceUpdateOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceUpdateOffer$Response(params, context) {
    return marketplaceUpdateOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Update an offer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceUpdateOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceUpdateOffer(params, context) {
    return this.marketplaceUpdateOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `marketplaceDeleteOffer()` */
  static MarketplaceDeleteOfferPath = '/marketplace-deleteOffer';
  /**
   * Delete an offer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceDeleteOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceDeleteOffer$Response(params, context) {
    return marketplaceDeleteOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Delete an offer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceDeleteOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceDeleteOffer(params, context) {
    return this.marketplaceDeleteOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `marketplaceGetOffers()` */
  static MarketplaceGetOffersPath = '/marketplace-getOffers';
  /**
   * Retrieve offers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceGetOffers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceGetOffers$Response(params, context) {
    return marketplaceGetOffers(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve offers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceGetOffers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceGetOffers(params, context) {
    return this.marketplaceGetOffers$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `marketplaceGetOffer()` */
  static MarketplaceGetOfferPath = '/marketplace-getOffer';
  /**
   * Retrieve an offer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceGetOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceGetOffer$Response(params, context) {
    return marketplaceGetOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve an offer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceGetOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceGetOffer(params, context) {
    return this.marketplaceGetOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `marketplaceBuyOffer()` */
  static MarketplaceBuyOfferPath = '/marketplace-buyOffer';
  /**
   * Buy an offer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceBuyOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceBuyOffer$Response(params, context) {
    return marketplaceBuyOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Buy an offer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceBuyOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceBuyOffer(params, context) {
    return this.marketplaceBuyOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `marketplaceCompleteBuyOffer()` */
  static MarketplaceCompleteBuyOfferPath = '/marketplace-completeBuyOffer';
  /**
   * Complete a buy offer transaction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceCompleteBuyOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceCompleteBuyOffer$Response(params, context) {
    return marketplaceCompleteBuyOffer(this.http, this.rootUrl, params, context);
  }
  /**
   * Complete a buy offer transaction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceCompleteBuyOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceCompleteBuyOffer(params, context) {
    return this.marketplaceCompleteBuyOffer$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `marketplaceUpdateAllItems()` */
  static MarketplaceUpdateAllItemsPath = '/marketplace-updateAllItems';
  /**
   * Update all items.
   *
   * This api requires elevated user rights. Updates all existing marketplace items, transactions and inventory items.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `marketplaceUpdateAllItems()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceUpdateAllItems$Response(params, context) {
    return marketplaceUpdateAllItems(this.http, this.rootUrl, params, context);
  }
  /**
   * Update all items.
   *
   * This api requires elevated user rights. Updates all existing marketplace items, transactions and inventory items.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `marketplaceUpdateAllItems$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  marketplaceUpdateAllItems(params, context) {
    return this.marketplaceUpdateAllItems$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function MarketplaceService_Factory(t) {
    return new (t || MarketplaceService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MarketplaceService,
    factory: MarketplaceService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarketplaceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
function projectsGetAll(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, projectsGetAll.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
projectsGetAll.PATH = '/projects-getAll';

/* tslint:disable */
/* eslint-disable */
function projectsGetById(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, projectsGetById.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
projectsGetById.PATH = '/projects-getById';

/* tslint:disable */
/* eslint-disable */
function projectsGetByIds(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, projectsGetByIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
projectsGetByIds.PATH = '/projects-getByIds';

/* tslint:disable */
/* eslint-disable */
function projectsGetByTags(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, projectsGetByTags.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
projectsGetByTags.PATH = '/projects-getByTags';

/* tslint:disable */
/* eslint-disable */
function projectsGetByUserId(http, rootUrl, params, context) {
  const rb = new RequestBuilder(rootUrl, projectsGetByUserId.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  return http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    context
  })).pipe(filter(r => r instanceof HttpResponse), map(r => {
    return r;
  }));
}
projectsGetByUserId.PATH = '/projects-getByUserId';
class ProjectsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `projectsGetById()` */
  static ProjectsGetByIdPath = '/projects-getById';
  /**
   * Retrieve a project by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsGetById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetById$Response(params, context) {
    return projectsGetById(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve a project by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsGetById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetById(params, context) {
    return this.projectsGetById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `projectsGetByIds()` */
  static ProjectsGetByIdsPath = '/projects-getByIds';
  /**
   * Retrieve projects by IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsGetByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetByIds$Response(params, context) {
    return projectsGetByIds(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve projects by IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsGetByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetByIds(params, context) {
    return this.projectsGetByIds$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `projectsGetByTags()` */
  static ProjectsGetByTagsPath = '/projects-getByTags';
  /**
   * Retrieve projects by tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsGetByTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetByTags$Response(params, context) {
    return projectsGetByTags(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve projects by tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsGetByTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetByTags(params, context) {
    return this.projectsGetByTags$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `projectsGetAll()` */
  static ProjectsGetAllPath = '/projects-getAll';
  /**
   * Retrieve all projects.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsGetAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetAll$Response(params, context) {
    return projectsGetAll(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve all projects.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsGetAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetAll(params, context) {
    return this.projectsGetAll$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `projectsGetByUserId()` */
  static ProjectsGetByUserIdPath = '/projects-getByUserId';
  /**
   * Retrieve projects by user ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsGetByUserId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetByUserId$Response(params, context) {
    return projectsGetByUserId(this.http, this.rootUrl, params, context);
  }
  /**
   * Retrieve projects by user ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsGetByUserId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsGetByUserId(params, context) {
    return this.projectsGetByUserId$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function ProjectsService_Factory(t) {
    return new (t || ProjectsService)(i0.ɵɵinject(ReadyGGApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ProjectsService,
    factory: ProjectsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProjectsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ReadyGGApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/**
 * Module that provides all services and configuration.
 */
class ApiModule {
  static forRoot(params) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: ReadyGGApiConfiguration,
        useValue: params
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static ɵfac = function ApiModule_Factory(t) {
    return new (t || ApiModule)(i0.ɵɵinject(ApiModule, 12), i0.ɵɵinject(i2.HttpClient, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ApiModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [UserAuthService, UserDataService, WalletsService, VirtualItemsV2Service, InventoryV2Service, StoreV2Service, LeaderboardV2Service, AchievementsService, CurrencyService, GameService, MatchmakingService, GamePassService, DAppService, DAppAdminService, BetaTestV2Service, SocialService, MarketplaceService, ProjectsService, ReadyGGApiConfiguration]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [],
      declarations: [],
      providers: [UserAuthService, UserDataService, WalletsService, VirtualItemsV2Service, InventoryV2Service, StoreV2Service, LeaderboardV2Service, AchievementsService, CurrencyService, GameService, MatchmakingService, GamePassService, DAppService, DAppAdminService, BetaTestV2Service, SocialService, MarketplaceService, ProjectsService, ReadyGGApiConfiguration]
    }]
  }], () => [{
    type: ApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i2.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
/**
 * The type of the binary to be uploaded.
 * Supported types are:
 * * `dapp_icon` - The icon of the DApp. 'entityId' is the id of the DApp.
 * * `dapp_banner` - The banner of the DApp. 'entityId' is the id of the DApp.
 * * `dapp_screenshot` - The screenshot of the DApp. 'entityId' is the id of the DApp. 'additionalData' contanins the platformId.
 * * `upcoming_event_banner` - The banner of the upcoming event. 'entityId' is the name of the upcoming event.
 * * `category_image` - The image of the category. 'entityId' is the id of the category.
 */
var ReadyGGDAppBinaryType;
(function (ReadyGGDAppBinaryType) {
  ReadyGGDAppBinaryType["DappIcon"] = "dapp_icon";
  ReadyGGDAppBinaryType["DappBanner"] = "dapp_banner";
  ReadyGGDAppBinaryType["DappScreenshot"] = "dapp_screenshot";
  ReadyGGDAppBinaryType["UpcomingEventBanner"] = "upcoming_event_banner";
  ReadyGGDAppBinaryType["CategoryImage"] = "category_image";
})(ReadyGGDAppBinaryType || (ReadyGGDAppBinaryType = {}));

/* tslint:disable */
/* eslint-disable */
/**
 * The type of query to be performed.
 * * `search` - This query requires the query parameter for the search criteria. The query could be a name, description,
 *   category, platform. It depends how far we want to go with it. We can consider integrating something similar
 *   to ElasticSearch or Algolia. The startAfter and limit could be used for pagination. This api will return
 *   only listed apps.
 * * `popular` - The query parameter is not required. For now until we integrate the social functions we will return a static
 *   array of existing dapps from the backend. Later we can consider prebaking the data once in a while to build
 *   a list of apps. This api will return only listed apps.
 * * `newlyListed` - The query parameter is not required. Returns an array of DApps that have the “isListed” set to “true”.
 *   Sorted by listedAt field. Supports pagination. The startAfter has a long type to start after “listedAt” last
 *   dapp.
 * * `communityChoice` - The query parameter is not required. For now we will return a static list of apps like in the “popular”
 *   until we integrate the social functions.
 * * `topRated` - The query parameter is not required. For now we will return a static list of apps like in the “popular”
 *   until we integrate the social functions.
 * * `topCategory` - The query parameter is the category name for which the DApps are requested. Again, static list for now.
 * * `category` - The query parameter is the category filter for which the DAppCategories are requested. Again, static list for now.
 * * `unlisted` - The query parameter is not required. Returns an array of DApps that have the “isListed” set to “false”.
 *   Sorted by updatedAt field. Supports pagination. The startAfter has a long type to start after “updatedAt”
 *   last dapp.
 */
var ReadyGGDAppQueryType;
(function (ReadyGGDAppQueryType) {
  ReadyGGDAppQueryType["Search"] = "search";
  ReadyGGDAppQueryType["Popular"] = "popular";
  ReadyGGDAppQueryType["NewlyListed"] = "newlyListed";
  ReadyGGDAppQueryType["CommunityChoice"] = "communityChoice";
  ReadyGGDAppQueryType["TopRated"] = "topRated";
  ReadyGGDAppQueryType["TopCategory"] = "topCategory";
  ReadyGGDAppQueryType["Category"] = "category";
  ReadyGGDAppQueryType["Unlisted"] = "unlisted";
})(ReadyGGDAppQueryType || (ReadyGGDAppQueryType = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { AchievementsService, ApiModule, BaseService, BetaTestV2Service, CurrencyService, DAppAdminService, DAppService, GamePassService, GameService, InventoryV2Service, LeaderboardV2Service, MarketplaceService, MatchmakingService, ProjectsService, ReadyGGApiConfiguration, ReadyGGDAppBinaryType, ReadyGGDAppQueryType, RequestBuilder, SocialService, StoreV2Service, UserAuthService, UserDataService, VirtualItemsV2Service, WalletsService };
