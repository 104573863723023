@if(achievements$ | async; as achievements) { @if(achievements.length) {
<div class="grid-view p-3">
  @for(achievement of achievements; track achievement) { @if(achievement) {
  <div class="inline-flex">
    <app-social-task-item [achievement]="achievement" class="flex-grow-1">
    </app-social-task-item>
  </div>
  } }
</div>
} @else {
<p>No achievements found.</p>
} } @else {
<p>Loading achievements...</p>
}
