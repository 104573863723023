import { InventoryDialogComponent } from '../inventory-dialog/inventory-dialog.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { AchievementsService } from '../../../services/achievements.service';
import { NftService } from '../../../services/nft.service';
import { Achievement } from '../../../interfaces/achievement.interface';
import {
  Referral,
  ReferralInformation,
  ReferralReward,
} from '../../../interfaces/referral.interface';
import {
  Inventory,
  InventoryItem,
} from '../../../interfaces/inventory.interface';
import { DialogService } from '../../../services/dialog.service';

@Component({
  selector: 'app-refer-dialog',
  standalone: true,
  templateUrl: 'refer-dialog.component.html',
  styleUrls: ['refer-dialog.component.scss'],
  imports: [CommonModule],
})
export class ReferDialogComponent implements OnInit {
  @ViewChild('email') emailInput: ElementRef | undefined;
  referrals: number[] = [];
  currentReferrals: number = 0;
  isEmailValid: boolean = false;
  message: string = '';
  isError: boolean = false;
  referralVirtualItemId: string = 'nCNJUYWARkHAAoIwxWnz';
  referralBadgeImage: string = '';
  claiming: boolean = false;
  referralBadgeClaimed: boolean = false;
  loadingAchievements: boolean = false;

  constructor(
    private authService: AuthService,
    private achievementsService: AchievementsService,
    private dialogService: DialogService,
    private nftService: NftService
  ) {}

  ngOnInit() {
    this.getReferralData();
    this.nftService.getInventory().then((inventory: Inventory) => {
      const item = inventory.items.find(
        (item) => item.virtualItemId === this.referralVirtualItemId
      );
      if (item) {
        this.referralBadgeClaimed = true;
        const virtualItem = inventory.virtualItems.find(
          (vItem) => vItem.id === this.referralVirtualItemId
        );
        // if (virtualItem) {
        //   this.referralBadgeImage = virtualItem.image.source;
        // }
      }
    });
  }

  openAuthPopup() {
    this.authService.runOAuth();
  }

  getReferralData() {
    this.achievementsService
      .getReferralsInformation()
      .then((info: ReferralInformation) => {
        const achievementIds = info.rewards.map(
          (r: ReferralReward) => r.achievementId
        );

        this.loadingAchievements = true;

        this.achievementsService
          .getReferralAchivements(achievementIds)
          .then((achievements: Achievement[]) => {
            this.referrals = achievements
              .map(
                (achievement: Achievement) => achievement.rewards[0].quantity
              )
              .sort((a, b) => a - b);
            this.loadingAchievements = false;
          });
      });

    this.achievementsService
      .getReferralsAmount()
      .then((referrals: Referral[]) => {
        this.currentReferrals = referrals.length;
      });
  }

  validateEmail(email: string) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.isEmailValid = emailPattern.test(email);
  }

  claimReferralBadge() {
    this.claiming = true;
    this.nftService.claimNft([this.referralVirtualItemId]).then(() => {
      this.claiming = false;
      this.referralBadgeClaimed = true;
      this.nftService.getInventory().then((inventory: Inventory) => {
        this.openInventory(inventory);
      });
    });
  }

  sendReferralInvite(email: string) {
    if (!this.isEmailValid || !email) {
      this.message = 'Please enter a valid email address.';
      this.isError = true;
      return;
    }

    const referralLink = 'https://universe.ready.gg';
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        console.log('Referral link copied to clipboard');
        this.message = 'Referral link copied to clipboard!';
        this.isError = false;
        this.clearMessageAfterTimeout();
      })
      .catch((err) => {
        console.error('Failed to copy referral link: ', err);
        this.message = 'Failed to copy referral link.';
        this.isError = true;
        this.clearMessageAfterTimeout();
      });

    this.achievementsService.referralInvite(email).then((res) => {
      console.log(res);
      this.getReferralData();
      this.emailInput!.nativeElement.value = '';
    });
  }

  clearMessageAfterTimeout() {
    setTimeout(() => {
      this.message = '';
    }, 3000);
  }

  openInventory(inventory: Inventory) {
    this.dialogService.openDialog(InventoryDialogComponent, {
      inventory: inventory.items.map((item) => {
        const virtualItem = inventory.virtualItems.find(
          (vItem) => vItem.id === item.virtualItemId
        );
        return {
          ...item,
          image: virtualItem ? virtualItem.image.source : null,
        };
      }),
    });
  }
}
